import { disableLoader, enableLoader, updateStoresList } from '@context/actions';
import { getStoresByTenantIdMysql } from '@storeData/store';
import { navigateTo } from '@util/routerService';
import { formatTimeTo12Hr } from '@util/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StoreTimings from './storeTimings';

function FaPhone(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>;
}

function MdEmail(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" {...props}><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" /></svg>;
}

function ImLocation(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 16 16" height="1em" width="1em" {...props}><path d="M8 0c-2.761 0-5 2.239-5 5 0 5 5 11 5 11s5-6 5-11c0-2.761-2.239-5-5-5zM8 8c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg>;
}

function FacebookIcon() {
    return <svg className="facebook" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg>
}

function WhatsappIcon() {
    return <svg className="whatsapp" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M260.062 32C138.605 32 40.134 129.701 40.134 250.232c0 41.23 11.532 79.79 31.559 112.687L32 480l121.764-38.682c31.508 17.285 67.745 27.146 106.298 27.146C381.535 468.464 480 370.749 480 250.232 480 129.701 381.535 32 260.062 32zm109.362 301.11c-5.174 12.827-28.574 24.533-38.899 25.072-10.314.547-10.608 7.994-66.84-16.434-56.225-24.434-90.052-83.844-92.719-87.67-2.669-3.812-21.78-31.047-20.749-58.455 1.038-27.413 16.047-40.346 21.404-45.725 5.351-5.387 11.486-6.352 15.232-6.413 4.428-.072 7.296-.132 10.573-.011 3.274.124 8.192-.685 12.45 10.639 4.256 11.323 14.443 39.153 15.746 41.989 1.302 2.839 2.108 6.126.102 9.771-2.012 3.653-3.042 5.935-5.961 9.083-2.935 3.148-6.174 7.042-8.792 9.449-2.92 2.665-5.97 5.572-2.9 11.269 3.068 5.693 13.653 24.356 29.779 39.736 20.725 19.771 38.598 26.329 44.098 29.317 5.515 3.004 8.806 2.67 12.226-.929 3.404-3.599 14.639-15.746 18.596-21.169 3.955-5.438 7.661-4.373 12.742-2.329 5.078 2.052 32.157 16.556 37.673 19.551 5.51 2.989 9.193 4.529 10.51 6.9 1.317 2.38.901 13.531-4.271 26.359z"></path></svg>
}

function YoutubeIcon() {
    return <svg className="youtube" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M941.3 296.1a112.3 112.3 0 0 0-79.2-79.3C792.2 198 512 198 512 198s-280.2 0-350.1 18.7A112.12 112.12 0 0 0 82.7 296C64 366 64 512 64 512s0 146 18.7 215.9c10.3 38.6 40.7 69 79.2 79.3C231.8 826 512 826 512 826s280.2 0 350.1-18.8c38.6-10.3 68.9-40.7 79.2-79.3C960 658 960 512 960 512s0-146-18.7-215.9zM423 646V378l232 133-232 135z"></path></svg>
}

function FcGoogle(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} x="0px" y="0px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" height="1em" width="1em" {...props}><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24 c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657 C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg>;
}

function FloatingWhatsapp() {
    return <svg className="whatsapp" stroke="currentColor" fill="currentColor" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48" >
        <path fill="#fff" d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z"></path><path fill="#fff" d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z"></path><path fill="#cfd8dc" d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z"></path><path fill="#40c351" d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z"></path><path fill="#fff" fillRule="evenodd" d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z" clipRule="evenodd"></path>
    </svg>
}
function CaretDownIcon() {
    return (
        <svg className="caret-down" fill="currentColor" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.5 192h257c26.5 0 40 31.9 21.1 51L195.1 348c-11.8 12.2-30.4 12.2-42.2 0L38.4 243c-18.8-19.1-5.4-51 21.1-51z"></path>
        </svg>
    );
}

function Footer() {
    const storeMetaData = useSelector((state: any) => state.store ? state.store.storeMetaData : null);
    const storeData = useSelector((state: any) => state.store ? state.store.storeData : null);
    const store = useSelector((state: any) => state);
    const [socialLinks, setSocialLinks] = useState([]);
    const dispatch = useDispatch();
    const [storesList, setStoresList] = useState([])
    const baseRouteUrl = useSelector((state: any) => state.store.baseRouteUrl);
    const { configData } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [whatsappLinkData, setWhatsappLinkData] = useState(null);
    const cartAppointment = useSelector((state: any) => state.appointmentServices);
    const currentPage = useSelector((state: any) => state.currentPage);
    const basicConfig = configData?.storeConfig?.basicConfig
    const [timingModal, setTimingModal] = useState(false)
    const languageData: any = store.languageKeywords
    useEffect(() => {
        const { configData: { socialLinks } } = storeData;
        if (socialLinks.length != 0) {
            const linksList: any[] = socialLinks.filter((l: any) => l.active);
            let whatsappIndex = linksList.findIndex((l: any) => l.name.includes('whatsapp'));
            if (whatsappIndex != -1) {
                setWhatsappLinkData(linksList[whatsappIndex]);
                linksList.splice(whatsappIndex, 1)
            }
            setSocialLinks(linksList);
        }
    }, [storeData])

    useEffect(() => {
        if (storeData.tenantId && storesList.length == 0) {
            dispatch(enableLoader());
            getStoresByTenantIdMysql(storeData.tenantId).then((res: any) => {
                dispatch(disableLoader());
                dispatch(updateStoresList(res))
                setStoresList(res);
            }).catch((err) => {
                dispatch(disableLoader());
                console.log(err)
            })
        }
        // getLanguageKeywords(configData.tenantId, configData.storeId, 'ar').then((keywords: any) => {
        //     let tempLangData: any = {};
        //     keywords.map((keywordDetails: any) => {
        //         tempLangData[keywordDetails.keyword] = keywordDetails.translation
        //     })
        //     dispatch(disableLoader());
        //     dispatch(updateLanguageKeywords(tempLangData));
        // }).catch((err) => {
        //     dispatch(disableLoader());
        //     console.log(err)
        // })
    }, [storeData])

    const toggletimingModal = () => {
        setTimingModal(!timingModal)
    }

    const onClickType = (type: any, data: any = {}) => {
        switch (type) {
            case 'facebook': case 'whatsapp': case 'instagram': case 'youtube': case 'google':
                window.open(data.url, '_blank')
                break;
            case 'phone':
                window.open(`tel:${storeMetaData.phone?.length == 10 ? `+${storeMetaData.countryCode} ` : ''}${storeMetaData.phone}`, '_self')
                break;
            case 'location':
                window.open(`${storeMetaData.googleMapUrl ? storeMetaData.googleMapUrl : `https://www.google.com/maps/search/?api=1&query=${storeMetaData.latitude},${storeMetaData.longitude}`}`, '_blank')
                break;
            case 'email':
                window.open(`mailto:${storeMetaData.email}`, '_self')
                break;
            default:
                break;
        }
    }

    return (
        <>
            {storeMetaData?.name?.length != 0 ? <div>
                < div className="footer-wrap" id="footer-wrapper"
                // style={{ backgroundImage: `url(/assets/${genericImages?.footerBg})` }} 
                >
                    <div className="social-wrap">
                        <div className="getInTouch">{languageData["Get In Touch"] ? languageData["Get In Touch"] : "Get In Touch"}</div>
                        <div className="social-icon-wrap">
                            {socialLinks.length !== 0 && socialLinks.map((linkData, index) => {
                                return <div className={`social-icon glass-card`} key={index} onClick={() => onClickType(linkData.name, linkData)}>
                                    {/* {linkData.name.includes('instagram') && <SvgIcon icon="instagram" />} */}
                                    {linkData.name.includes('instagram') && <img className="insta" src="/assets/Icons/social/insta.svg" alt="Respark" />}
                                    {linkData.name.includes('google') && <FcGoogle />}
                                    {linkData.name.includes('facebook') && <FacebookIcon />}
                                    {linkData.name.includes('whatsapp') && <WhatsappIcon />}
                                    {linkData.name.includes('youtube') && <YoutubeIcon />}
                                </div>
                            })}
                        </div>
                        {Boolean(whatsappLinkData) &&
                            <div className={`social-icon glass-card floating-whatsapp`} style={{
                                bottom: (configData.storeConfig?.appointmentConfig?.active && cartAppointment?.length != 0 && currentPage != 'cart' && currentPage != 'checkout' && currentPage != 'appointment') ? "45px" : "0",
                                left: (configData.storeConfig?.appointmentConfig?.active && cartAppointment?.length != 0 && currentPage != 'cart' && currentPage != 'checkout' && currentPage != 'appointment') ? "48%" : "46%"
                            }} >
                                <div onClick={() => onClickType(whatsappLinkData.name, whatsappLinkData)}>
                                    <FloatingWhatsapp />
                                </div>
                            </div>}
                    </div>
                    <div className="information-wrap">
                        <div className="icon glass-card" onClick={() => onClickType('location')}>
                            <ImLocation />
                        </div>
                        {storesList && configData.showStoreLocator && storesList.length > 1 ?
                            <span className="containt">
                                {storesList && storesList.length != 0 && storesList.map((storeData: any, i: number) => {
                                    return <div key={i} className='name' onClick={() => navigateTo('storelocator')}>{storeData.name}</div>
                                })}
                            </span> :
                            <span className="containt" style={{ textTransform: 'capitalize' }}>
                                <a href={storeMetaData.googleMapUrl} rel="noreferrer" target="_blank">
                                    {storeMetaData.address}, {storeMetaData.area}, {storeMetaData.city}, {storeMetaData.state}, {storeMetaData.pincode}
                                </a>
                            </span>
                        }
                    </div>
                    {(Boolean(storeMetaData.phone) || Boolean(storeMetaData.phone1)) && <div className="information-wrap">
                        <div className="icon glass-card" onClick={() => onClickType('phone')}>
                            <FaPhone />
                        </div>
                        {/* <span className="containt">
                            {storeMetaData.phone && <a href={`tel:${storeMetaData.phone?.length == 10 ? `+${storeMetaData.countryCode} ` : ''}${storeMetaData.phone}`}>
                                {storeMetaData.phone?.length == 10 ? `+${storeMetaData.countryCode} ` : <>&nbsp;&nbsp;&nbsp;</>}{storeMetaData.phone}
                            </a>}
                            {storeMetaData.phone1 && <><br /><a style={{ paddingLeft: '10px' }} href={`tel:+${storeMetaData.countryCode} ${storeMetaData.phone1}`}> +{storeMetaData.countryCode} {storeMetaData.phone1}</a></>}
                        </span>

                        <span className="containt">
                            {storeMetaData.phone && <a href={`tel:${storeMetaData.phone?.length == 10 ? `${basicConfig.multipleCountryCode ? "+" + storeMetaData.countryCode : ""} ` : ''}${storeMetaData.phone}`}>
                                {storeMetaData.phone?.length == 10 ? `${basicConfig.multipleCountryCode ? "+" + storeMetaData.countryCode : ""} ` : <>&nbsp;&nbsp;&nbsp;</>}{storeMetaData.phone}
                            </a>}
                            {storeMetaData.phone1 && <><br /><a style={{ paddingLeft: '10px' }} href={`tel:${basicConfig.multipleCountryCode ? "+" + storeMetaData.countryCode : ""} ${storeMetaData.phone1}`}></a></>}
                        </span> */}
                        <span className="containt">
                            {storeMetaData.phone && (
                                <a href={`tel:${storeMetaData.phone?.length == 10 ? `${basicConfig.multipleCountryCode ? "+" + storeMetaData.countryCode : ""} ` : ''}${storeMetaData.phone}`}>
                                    {storeMetaData.phone?.length == 10
                                        ? `${basicConfig.multipleCountryCode ? "+" + storeMetaData.countryCode : ""} `
                                        : <>&nbsp;&nbsp;&nbsp;</>
                                    }
                                    {storeMetaData.phone}
                                </a>
                            )}
                            {/* {storeMetaData.phone && <a href={`tel:${storeMetaData.phone}`}>{storeMetaData.phone}</a>} */}
                            {storeMetaData.phone1 &&
                                <>
                                    <br />
                                    <a style={{ paddingLeft: '10px' }} href={`tel:${basicConfig.multipleCountryCode ? "+" + storeMetaData.countryCode : ""} ${storeMetaData.phone1}`}>
                                        {basicConfig.multipleCountryCode ? "+" + storeMetaData.countryCode : ""} {storeMetaData.phone1}
                                    </a>
                                </>
                            }
                        </span>
                    </div>}

                    {Boolean(storeMetaData.email) && <div className="information-wrap">
                        <div className="icon glass-card" onClick={() => onClickType('email')}>
                            <MdEmail />
                        </div>
                        <span className="containt"> <a href={`mailto:${storeMetaData.email}`} rel="noreferrer" target="_blank">{storeMetaData.email}</a></span>
                    </div>}
                    <div className="store-timing-wrap glass-card">
                        <><div className='sub-heading' onClick={toggletimingModal}>*{languageData["Working Hours"] ? languageData["Working Hours"] : "Working Hours"} : {formatTimeTo12Hr(storeData.configData?.dayWiseTiming[(new Date()).getDay()].startTime)} to {formatTimeTo12Hr(storeData.configData?.dayWiseTiming[(new Date()).getDay()].closureTime)}   {CaretDownIcon()}</div></>
                    </div>
                    <div className="copyright-wrp" style={{ textTransform: 'capitalize' }}>
                        <span>Copyright &copy; 2025 {storeData.tenant}, {storeMetaData.name}</span>
                    </div>
                </div >
            </div > : null
            }
            <StoreTimings openModal={timingModal} handleClose={toggletimingModal}></StoreTimings>
        </>
    )
}

export default Footer
