import { APISERVICE } from "@util/apiService/RestClient";

export const getCurrentReleaseVersion = () => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_BASE_PCS_URL}/buildVersions?project=dashboard`)
            .then((response) => {
                res(response.data);
            }).catch(function (error) {
                rej(error);
            });
    })
}

export const getBillPrintByOrder = (order: string, languageCode: string = 'en', combinePrintBill = false) => {
    return new Promise((res, rej) => {
        APISERVICE.POST(`${process.env.NEXT_PUBLIC_PCS_TXN}/printBill?languageCode=${languageCode}&combinePrintBill=${combinePrintBill}&catalougeBill=${true}`, order)
            .then((response) => {
                res(response.data);
            }).catch(function (error) {
                rej(error);
            });
    })
}

export const getLanguages = (tenantId: any, storeId: any) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_BASE_PCS_URL}/languageMappings/tenantId/storeId?tenantId=${tenantId}&storeId=${storeId}`)
            .then((response) => {
                // console.log("response?.data?.data", response?.data?.data)
                res(Boolean(response?.data?.data?.length) ? response?.data?.data : [{ "languageId": "en", "displayName": "English" }]);
            }).catch(function (error) {
                console.log("response?.data?.data   error", error)
                rej(error);
            });
    })
}


export const getDisposableItemsList = (tenantId: any, storeId: any) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_GET_BASE_CATALOG_URL}/disposableItems?tenantId=${tenantId}&storeId=${storeId}`)
            .then((response) => {
                // console.log("response?.data?.data", response?.data?.data)
                res(Boolean(response?.data?.data?.length) && response?.data?.data);
            }).catch(function (error) {
                console.log("response?.data?.data   error", error)
                rej(error);
            });
    })
}

export const getBillingData = (tenantId: any, storeId: any) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_BASE_PCS_URL}/printBillDetails?storeId=${storeId}&tenantId=${tenantId}`)
            .then((response) => {
                // console.log("response?.data?.data", response?.data?.data)
                res(response?.data);
            }).catch(function (error) {
                console.log("response?.data?.data   error", error)
                rej(error);
            });
    })
}

export const getLanguageKeywords = (tenantId: any, storeId: any, langCode: string = 'en') => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_BASE_PCS_URL}/langKeywords?tenantId=${tenantId}&storeId=${storeId}&langCode=${langCode}`)
            .then((response) => {
                res(response?.data);
            }).catch(function (error) {
                console.log("response   error", error)
                rej(error);
            });
    })
}


