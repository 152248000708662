import { disableLoader, enableLoader, showError, showSuccess, showWarning } from '@context/actions';
import SvgIcon from '@element/svgIcon';
import ConfirmationModal from '@module/confirmationModal';
import { getAppointmentByTenantIdAndGuestId, requestAppointmentReschedule, rescheduleAppointment } from '@storeData/appointment';
import { addDayToDate, createSlots, formatTimeTo12Hr, formatToDateFormat, getDateObj } from '@util/utils';
import { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';

function AiOutlineDelete(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" /></svg>;
}

function FaCalendarAlt(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="1em" width="1em" {...props}><path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" /></svg>;
}

function SlotRescheduleModal({ item, onClose, setAppointmentHistoryData }) {

    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const appointmentItems = useSelector((state: any) => state.appointmentServices);
    const [selectedItem, setSelectedItem] = useState(item)
    const [error, setError] = useState({ id: '', text: '' });
    const [anchorEl, setAnchorEl] = useState(null);
    const storeData = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [availableExpertSlotData, setAvailableExpertSlotData] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([])
    const { configData } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [isLoading, setIsLoading] = useState(false);
    const store = useSelector((state: any) => state);
    const appointmentConfig = configData?.storeConfig?.appointmentConfig;
    const [dateChangeConfirmationModal, setDateChangeConfirmationModal] = useState({
        secondaryButtonText: "",
        openModal: false,
        title: '',
        message: '',
        buttonText: '',
        handleClose: null
    })
    const [selectedDate, setSelectedDate] = useState<any>(new Date(item.appointmentDay));
    const [selectedSlot, setSelectedSlot] = useState<any>('');
    const [activeInput, setActiveInput] = useState('');
    const [minDate, setMinDate] = useState(new Date());
    const [cookie, setCookie] = useCookies();
    const [userData, setUserCookie] = useState(cookie['user']);
    const [storeSlot, setStoreSlot] = useState(createSlots(storeData.configData?.dayWiseTiming[(new Date()).getDay()].startTime, storeData.configData?.dayWiseTiming[(new Date()).getDay()].closureTime));
    const languageData: any = store.languageKeywords

    const DatePickerInput = forwardRef((props: any, ref: any) => <button className={`date-picker-button ${error.id == 'date' ? "error" : ""}`}
        onClick={props.onClick} ref={ref}>
        {languageData["Appointment Date"] ? languageData["Appointment Date"] : "Appointment Date"}: {props.value ? `: ${props.value}` : <div className='guided-label'>{languageData["Click to select date"] ? languageData["Click to select date"] : "Click to select date"}</div>}
    </button>);

    useEffect(() => {
        let slots = [];
        if (getDateObj(new Date()).dateObj == getDateObj(selectedDate).dateObj) {
            let hrs = ((new Date().getHours().toString().length == 1) ? '0' + new Date().getHours() : new Date().getHours());
            let mins: any = ((new Date().getMinutes().toString().length == 1) ? '0' + new Date().getMinutes() : new Date().getMinutes());
            if (mins < 30) mins = '30';
            if (mins >= 30) {
                mins = '00';
                hrs = Number(hrs) + 1
            }
            const startTime = hrs + ':' + mins;
            slots = createSlots(startTime, configData?.dayWiseTiming[(new Date(getDateObj(selectedDate).dateObj)).getDay()].closureTime)
        } else {
            slots = createSlots(configData?.dayWiseTiming[(new Date(getDateObj(selectedDate).dateObj)).getDay()].startTime, configData?.dayWiseTiming[(new Date(getDateObj(selectedDate).dateObj)).getDay()].closureTime)
        }
        slots.pop()
        if (!Boolean(slots?.length)) {
            setMinDate(addDayToDate(new Date(), 1))
            setSelectedDate(addDayToDate(new Date(selectedDate), 1));
        }
        setStoreSlot(slots);
    }, [selectedDate]);

    const onOutsideClick = (event: any) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !dateChangeConfirmationModal.openModal) {
            onClose();
        }
    }


    const onSelectDate = (date) => {
        setSelectedDate(date);
    }
    const onSelectSlot = (slot) => {
        setActiveInput('');
        setSelectedSlot(slot);
    }


    const onReschedule = () => {

        if (selectedSlot == '') {
            dispatch(showWarning(`${languageData["Please Select Slot"] ? languageData["Please Select Slot"] : "Please Select Slot"}`))
            return;
        }
        onClose()
        dispatch(enableLoader())
        let currDateObj = item.appointmentDay.split("T")[0]
        let selectedDateObj = selectedDate.toISOString().split("T")[0]
        const requestRescheduleBody = {
            "appId": item.id,
            "currDate": currDateObj,
            "requestedDate": selectedDateObj,
            "currTime": item.appointmentTime,
            "requestedTime": selectedSlot,
            "source": "catalog",
            "status": "PENDING",
            "createdOn": new Date()
        }
        const rescheduleBody = {
            "appId": item.id,
            "updateAllRecurring": false,
            "date": selectedDateObj,
            "time": selectedSlot,
            "staffId": null,
            "sendSms": true,
            "requestId": null,
            "source": "catalog"
        }
        if (!appointmentConfig.autoConfirmed) {
            requestAppointmentReschedule(requestRescheduleBody)
                .then((response: any) => {
                    if (response) {
                        dispatch(disableLoader());
                        dispatch(showSuccess(`${languageData["Appointment Reschedule Requested"] ? languageData["Appointment Reschedule Requested"] : "Appointment Reschedule Requested"}`))
                        getAppointmentByTenantIdAndGuestId(userData?.id, userData?.tenantId)
                            .then((appointment: any) => {
                                if (appointment.length) {
                                    appointment.sort(function (a: any, b: any) {
                                        var dateA: any = new Date(a.appointmentDay).getTime();
                                        var dateB: any = new Date(b.appointmentDay).getTime();
                                        return dateA < dateB ? 1 : -1;
                                    });
                                    setAppointmentHistoryData(appointment);
                                    dispatch(disableLoader());
                                }
                            })
                            .catch((e) => {
                                dispatch(disableLoader());
                                dispatch(showError(e.error));
                            })
                    }
                })
                .catch((e) => {
                    dispatch(disableLoader());
                    dispatch(showError(e.error));
                })
        }
        else {
            requestRescheduleBody.status = "CONFIRMED"
            rescheduleAppointment(rescheduleBody)
                .then((response: any) => {
                    if (response) {
                        if (response.code == 200) {
                            requestAppointmentReschedule(requestRescheduleBody)
                                .then((response: any) => {
                                    if (response) {
                                        dispatch(disableLoader());
                                        dispatch(showSuccess(`${languageData["Appointment Rescheduled Successfully"] ? languageData["Appointment Rescheduled Successfully"] : "Appointment Rescheduled Successfully"}`))
                                        getAppointmentByTenantIdAndGuestId(userData?.id, userData?.tenantId)
                                            .then((appointment: any) => {
                                                if (appointment.length) {
                                                    appointment.sort(function (a: any, b: any) {
                                                        var dateA: any = new Date(a.appointmentDay).getTime();
                                                        var dateB: any = new Date(b.appointmentDay).getTime();
                                                        return dateA < dateB ? 1 : -1;
                                                    });
                                                    setAppointmentHistoryData(appointment);
                                                    dispatch(disableLoader());
                                                }
                                            })
                                            .catch((e) => {
                                                dispatch(disableLoader());
                                                dispatch(showError(e.error));
                                            })
                                    }
                                })
                                .catch((e) => {
                                    dispatch(disableLoader());
                                    dispatch(showError(e.error));
                                })
                        }
                    }
                }).catch((e) => {
                    dispatch(disableLoader());
                    dispatch(showError(e.error));
                })
        }

    }
    return (
        <div className='services-cart-modal-wrap backdrop-modal-wrapper'
            onClick={onOutsideClick}
            style={{
                height: 'calc(100vh - calc(100vh - 100%))',
                overflow: 'unset',
                maxHeight: 'calc(100vh - calc(100vh - 100%))',
                backgroundColor: '#0000001f'
            }}>
            <div className='main-wrapper appointment-wrap app-cal-wrapper' ref={modalRef}>
                <div className='heading'> {languageData["Select Reschedule Timing"] ? languageData["Select Reschedule Timing"] : "Select Reschedule Timing"}</div>
                <div className='sub-heading'>
                    {item.invoiceId != "" && <span>{item.invoiceId}</span>}
                    <div>
                        <span className="right-align order-type color">
                            {formatToDateFormat(item.appointmentDay, configData.dateFormat)} {formatTimeTo12Hr(item.appointmentTime)}
                        </span>
                    </div>
                </div>
                <div className="modal-close" onClick={onClose}>
                    <SvgIcon icon="close" width={20} height={20} />
                </div>
                <div className='services-list-wrap'>
                    <div className="date-wrap">
                        <div className="date-picker-wrap card app-date-wrap">
                            <DatePicker
                                selected={selectedDate ? new Date(selectedDate) : null}
                                onChange={(date) => onSelectDate(date)}
                                dateFormat="MMMM d"
                                inline
                                minDate={minDate}
                            />
                        </div>
                    </div>

                    <div className="slot-time-outer time-wrap card">
                        <div className="sub-heading heading-index">{languageData["Preferred Time"] ? languageData["Preferred Time"] : "Preferred Time"}</div>
                        {configData && storeSlot.map((storeSlot) => {
                            return <div key={Math.random()} onClick={() => onSelectSlot(storeSlot)} className={selectedSlot == storeSlot ? 'slot-wrap active' : "slot-wrap"}>{formatTimeTo12Hr(storeSlot)}</div>

                        })}
                    </div>
                </div>
                {Boolean(error.text) && <div className='error'>{error.text}</div>}
                <div className="slot-btn-wrap app-slot-align">
                    <button style={{ width: "100%" }} className="primary-btn rounded-btn" onClick={onClose}>{languageData["Cancel"] ? languageData["Cancel"] : "Cancel"}</button>

                    <button style={{ width: "100%" }} className="primary-btn rounded-btn" onClick={onReschedule}>{languageData["Confirm"] ? languageData["Confirm"] : "Confirm"}</button>
                </div>
            </div>
            <ConfirmationModal {...dateChangeConfirmationModal} />
        </div>
    )
}

export default memo(SlotRescheduleModal)

function setMinDate(arg0: any) {
    throw new Error('Function not implemented.');
}
