import SvgIcon from '@element/svgIcon';
import { Backdrop } from '@material-ui/core';
import { formatTimeTo12Hr } from '@util/utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function StoreTimings({ openModal, handleClose }) {
    const { configData } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [currentDay, setCurrentDay] = useState('');
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    useEffect(() => {
        const today = new Date().toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
        setCurrentDay(today.slice(0, 3));
    }, []);

    const daysMap = {
        SUN: 'Sunday',
        MON: 'Monday',
        TUE: 'Tuesday',
        WED: 'Wednesday',
        THU: 'Thursday',
        FRI: 'Friday',
        SAT: 'Saturday',
    };

    // ordered array
    const orderedDays = Object.keys(daysMap).slice(Object.keys(daysMap).indexOf(currentDay)).concat(
        Object.keys(daysMap).slice(0, Object.keys(daysMap).indexOf(currentDay))
    );

    const sortedTimings = configData?.dayWiseTiming
        ? [...configData.dayWiseTiming].sort((a: any, b: any) =>
            orderedDays.indexOf(a.day) - orderedDays.indexOf(b.day)
        )
        : [];

    return (
        <div className="confirmation-modal-wrap">
            <Backdrop
                className="backdrop-modal-wrapper confirmation-modal-wrap"
                open={openModal ? true : false}
            >
                <div className="backdrop-modal-content query-modal-wrap" style={{ height: openModal ? 'auto' : '0px' }}>
                    <div className="modal-close" onClick={() => { handleClose(false) }}>
                        <SvgIcon icon="closeLarge" />
                    </div>
                    <div className="member-modal">
                        <div className='details-heading'>{languageData["Working Hours"] ? languageData["Working Hours"] : "Working Hours"}</div>
                        <ul className="timings-list">
                            {sortedTimings?.map((timing: any, index: any) => (
                                <li key={index}
                                    className={`timing-item ${timing.day === currentDay ? 'current-day' : ''}`}>
                                    <span className="day">{daysMap[timing.day]}</span>
                                    {(configData.weeklyOff)?.toLowerCase().includes((timing.day).toLowerCase()) ? <span className="today-label">Closed</span> : <span className="time">{formatTimeTo12Hr(timing.startTime)} - {formatTimeTo12Hr(timing.closureTime)}</span>}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Backdrop>
        </div>
    );
}

export default StoreTimings;
