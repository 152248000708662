import { showError, showSuccess } from '@context/actions';
import SvgIcon from '@element/svgIcon'
import { Backdrop } from '@material-ui/core'
import { submitUserQuery } from '@storeData/user';
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';

function UserQueryModal({ openModal, handleClose }) {

    const [error, setError] = useState({ id: '', text: '' });
    const storeData = useSelector((state: any) => state.store ? state.store.storeData : null);
    const dispatch = useDispatch();
    const [cookie, setCookie] = useCookies();
    const [userData, setUserCookie] = useState(cookie['user']);
    const storeMetaData = useSelector((state: any) => state.store ? state.store.storeMetaData : null);
    const { configData } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const basicConfig = configData?.storeConfig?.basicConfig
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    const emptyQueryObj = {
        "mobileNo": Boolean(userData) ? userData?.mobileNo : "",
        "countryCode": Boolean(userData) ? userData?.countryCode : "",
        "name": Boolean(userData) ? `${userData?.firstName} ${userData?.lastName}` : "",
        "email": Boolean(userData) ? userData?.email : "",
        "service": "",
        "description": "",
        "tenantId": storeData.tenantId,
        "storeId": storeData.storeId,
    };
    const [userQueryDetails, setuserQueryDetails] = useState(emptyQueryObj);

    useEffect(() => {
        setuserQueryDetails(emptyQueryObj)
    }, [openModal])


    const onInputChange = (from, value, e = null) => {
        if (from == "mobileNo") {
            if (e.which === 38 || e.which === 40) {
                e.preventDefault();
                return
            }
        }
        const userQueryDetailsCopy = { ...userQueryDetails }
        userQueryDetailsCopy[from] = value;
        setuserQueryDetails(userQueryDetailsCopy)
        setError({ id: '', text: '' });
    }
    const onPhoneChange = (mobileNo, countryCode = storeMetaData.countryCode) => {
        setError({ id: '', text: '' });
        const num = mobileNo.charAt(mobileNo?.length - 1).replace(".", '');
        if (((num && num != ' ') && !isNaN(num))) {
            setuserQueryDetails({ ...userQueryDetails, mobileNo: mobileNo, countryCode });

        } else if (!Boolean(mobileNo?.length)) setuserQueryDetails({ ...userQueryDetails, mobileNo: mobileNo, countryCode });
    }
    const isValidEmail = () => {
        if (!userQueryDetails.email) return false
        if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userQueryDetails.email))) return false;
        else return true;
    }

    const isValidNumber = () => {
        if (!userQueryDetails.mobileNo) return false
        if (!basicConfig.multipleCountryCode ? (Boolean(storeMetaData.phoneNoLength) ? (Number(storeMetaData.phoneNoLength) == `${userQueryDetails.mobileNo}`?.length) : (`${userQueryDetails.mobileNo}`?.length >= 5 && `${userQueryDetails.mobileNo}`?.length <= 15)) : (`${userQueryDetails.mobileNo}`?.length >= 5 && `${userQueryDetails.mobileNo}`?.length <= 15)) return true;
        else return false;
    }

    const validateForm = () => {
        if (!userQueryDetails.name) {
            setError({ id: 'name', text: '' });
            dispatch(showError(`${languageData["Please  enter name"] ? languageData["Please  enter name"] : "Please  enter name"}`));
            return false;
        } else if (!isValidNumber()) {
            setError({ id: 'mobileNo', text: '' });
            dispatch(showError(`${languageData["Please  enter mobileNo"] ? languageData["Please  enter mobileNo"] : "Please  enter mobileNo"}`));
            return false;
        } else if (!isValidEmail()) {
            setError({ id: 'email', text: '' });
            dispatch(showError(`${languageData["Please enter valid email"] ? languageData["Please enter valid email"] : "Please enter valid email"}`));
            return false;
        } else if (!userQueryDetails.service) {
            setError({ id: 'service', text: '' });
            dispatch(showError(`${languageData["Please  enter service"] ? languageData["Please  enter service"] : "Please  enter service"}`));
            return false;
        } else return true
    }
    const onSubmitQuery = () => {
        if (validateForm()) {
            submitUserQuery(userQueryDetails).then(() => {
                dispatch(showSuccess(`${languageData["Enquiry submitted successfully"] ? languageData["Enquiry submitted successfully"] : "Enquiry submitted successfully"}`));
                handleClose();
            })
        }
    }

    return (
        <div className="confirmation-modal-wrap">
            <Backdrop
                className="backdrop-modal-wrapper confirmation-modal-wrap"
                open={openModal ? true : false}
            // onClick={() => handleClose(false)}
            >
                <div className="backdrop-modal-content query-modal-wrap" style={{ height: openModal ? 'auto' : '0px' }}>
                    {/* <div className="heading"></div> */}
                    <div className="modal-close" onClick={() => handleClose(false)}>
                        <SvgIcon icon="closeLarge" />
                    </div>
                    <div className="member-modal">
                        <div className='body-text'>{languageData["Please complete the form below and a member of staff will be in touch shortly"] ? languageData["Please complete the form below and a member of staff will be in touch shortly"] : "Please complete the form below and a member of staff will be in touch shortly"}</div>
                        <div className='body'>
                            <div id="name" className={`input-wrap-with-label ${Boolean(userQueryDetails.name) ? 'active' : ''} ${error.id == 'name' ? 'error' : ''}`}>
                                <div className="label"><span className="mandatory">*</span></div>
                                <div className={'form-label'}>{languageData["First name & Last name"] ? languageData["First name & Last name"] : "First name & Last name"}</div>
                                <input className={error.id == 'name' ? 'input invalidInput' : 'input'}
                                    autoComplete="off"
                                    value={userQueryDetails.name || ''}
                                    onChange={(e) => onInputChange('name', e.target.value)}
                                    placeholder={languageData["First name & Last name"] ? languageData["First name & Last name"] : "First name & Last name"} />
                            </div>
                            {/* <div id="mobileNo" className={`input-wrap-with-label ${Boolean(userQueryDetails.mobileNo) ? 'active' : ''} ${error.id == 'mobileNo' ? 'error' : ''}`}>
                                <div className="label"><span className="mandatory">*</span></div>
                                <div className={'form-label'}>Mobile Number</div>
                                <input className={error.id == 'mobileNo' ? 'input invalidInput' : 'input'}
                                    autoComplete="off"
                                    type='number'
                                    value={userQueryDetails.mobileNo || ''}
                                    onChange={(e) => onInputChange('mobileNo', e.target.value, e)}
                                    placeholder="Mobile Number" />
                            </div> */}
                            {basicConfig.multipleCountryCode ? <>
                                <div className={'form-label'}>{languageData["Mobile Number"] ? languageData["Mobile Number"] : "Mobile Number"}</div>
                                <PhoneInput
                                    inputClass={`phonInput input ${error.id == 'phone' ? 'invalidInput' : ''}`}
                                    value={`${userQueryDetails.countryCode || storeMetaData?.countryCode}${userQueryDetails.mobileNo}` || ''}
                                    onChange={(phone: string, country: any) => onPhoneChange(phone.replace(country.dialCode, ''), country.dialCode)}
                                    countryCodeEditable={false}
                                    placeholder={languageData["Mobile Number"] ? languageData["Mobile Number"] : "Mobile Number*"}
                                    country={storeMetaData.code || "in"} // Set the default country here
                                    enableSearch={true}
                                    containerStyle={{ paddingLeft: '0px', width: '100%', height: '44px', marginTop: '10px' }}
                                    inputProps={{
                                        style: { width: '100%', height: '44px', marginTop: '0px' },
                                        required: true
                                    }} />
                            </> : <>
                                <div id="mobileNo" className={`input-wrap-with-label ${Boolean(userQueryDetails.mobileNo) ? 'active' : ''} ${error.id == 'mobileNo' ? 'error' : ''}`}>
                                    <div className={'form-label'}>{languageData["Mobile Number"] ? languageData["Mobile Number"] : "Mobile Number"}</div>
                                    <div className="label"><span className="mandatory">*</span></div>
                                    <input className={error.id == 'mobileNo' ? 'input invalidInput' : 'input'}
                                        autoComplete="off"
                                        type='number'
                                        value={userQueryDetails.mobileNo || ''}
                                        onChange={(e) => onInputChange('mobileNo', e.target.value, e)}
                                        placeholder={languageData["Mobile Number"] ? languageData["Mobile Number"] : "Mobile Number"} />
                                </div>
                            </>}
                            <div id="email" className={`input-wrap-with-label ${Boolean(userQueryDetails.email) ? 'active' : ''} ${error.id == 'email' ? 'error' : ''}`}>
                                <div className="label"><span className="mandatory">*</span></div>
                                <div className={'form-label'}>{languageData["Email"] ? languageData["Email"] : "Email"}</div>
                                <input className={error.id == 'email' ? 'input invalidInput' : 'input'}
                                    autoComplete="off"
                                    value={userQueryDetails.email || ''}
                                    onChange={(e) => onInputChange('email', e.target.value)}
                                    placeholder={languageData["Email"] ? languageData["Email"] : "Email"} />
                            </div>
                            <div id="service" className={`input-wrap-with-label ${Boolean(userQueryDetails.service) ? 'active' : ''} ${error.id == 'service' ? 'error' : ''}`}>
                                <div className="label"><span className="mandatory">*</span></div>
                                <div className={'form-label'}>{languageData["Service Interested In"] ? languageData["Service Interested In"] : "Service Interested In"}</div>
                                <input className={error.id == 'service' ? 'input invalidInput' : 'input'}
                                    autoComplete="off"
                                    value={userQueryDetails.service || ''}
                                    onChange={(e) => onInputChange('service', e.target.value)}
                                    placeholder={languageData["Service Interested In"] ? languageData["Service Interested In"] : "Service Interested In"} />
                            </div>
                            <div id="description" className={`input-wrap-with-label ${Boolean(userQueryDetails.description) ? 'active' : ''} ${error.id == 'description' ? 'error' : ''}`}>
                                <div className="label"></div>
                                <div className={'form-label'}>{languageData["Description"] ? languageData["Description"] : "Description"}</div>
                                <textarea
                                    className={error.id == 'description' ? 'input invalidInput' : 'input'}
                                    autoComplete="off"
                                    value={userQueryDetails.description || ''}
                                    onChange={(e) => onInputChange('description', e.target.value)}
                                    placeholder={languageData["Description"] ? languageData["Description"] : "Description"}
                                >
                                    {userQueryDetails.description || ''}
                                </textarea>
                            </div>
                        </div>
                        <div className='contact-note'>
                            {languageData["For more details, call us on"] ? languageData["For more details, call us on"] : "For more details, call us on"}
                            {storeMetaData?.phone1 && <a href={`tel:${storeMetaData?.phone1?.length == 10 ? `+${storeMetaData?.countryCode} ` : ''}${storeMetaData?.phone1}`}>
                                {storeMetaData?.phone1?.length == 10 ? `+${storeMetaData?.countryCode} ` : <>&nbsp;&nbsp;&nbsp;</>}{storeMetaData?.phone1}
                            </a>}
                        </div>
                        <div className="form-btn-wrap  clearfix">
                            <button className="primary-btn rounded-btn border-btn" onClick={() => handleClose(true)}>{languageData["Cancel"] ? languageData["Cancel"] : "Cancel"}</button>
                            <button className="primary-btn rounded-btn" onClick={onSubmitQuery}>{languageData["Submit"] ? languageData["Submit"] : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </Backdrop>
        </div>
    )
}

export default UserQueryModal