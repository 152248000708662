import { APISERVICE } from "@api/RestClient";

export const getOrderByOrderId = (id) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_ORDERS}/fetch/${id}`)  //get store details
            .then(async (ordersData) => {
                if (ordersData.status == 200) {
                    const orders = ordersData.data;
                    if (orders) {
                        res(orders)
                    } else {
                        rej({ err: 'ordersData data unavailable' });
                    }
                } else if (ordersData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: ordersData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: error });
                console.error("error", error);
            });
    })
};

export const getOrderByTenantIdAndGuestId = (guestid, tenantid) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_ORDERS}/tenantguest/${tenantid}/${guestid}`)  //get order history
            .then(async (ordersData) => {
                if (ordersData.status == 200) {
                    const orders = ordersData.data;
                    if (orders) {
                        res(orders)
                    } else {
                        rej({ error: 'Order unavailable' });
                    }
                } else if (ordersData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/tenantguest/${tenantid}/${guestid}`, status: ordersData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/tenantguest/${tenantid}/${guestid}`, status: error });
                console.error("error", error);
            });
    })
};

export const getMembershipOrderByOrderId = (id) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_CUSTOMER}/guestMembershipTransaction?id=${id}`)  //get store details
            .then(async (membTransactionData) => {
                if (membTransactionData.status == 200) {
                    if (membTransactionData.data) {
                        res(membTransactionData.data.data)
                    } else {
                        rej({ err: 'membTransactionData data unavailable' });
                    }
                } else if (membTransactionData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: membTransactionData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: error });
                console.error("error", error);
            });
    })
};
// https://localhost:8083/pcs-guest/v1/customer/guestPackageDetails?id=1
export const getPackageOrderByOrderId = (id) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_CUSTOMER}/guestPackageDetails?id=${id}`)
            .then(async (transactionData) => {
                if (transactionData.status == 200) {
                    if (transactionData.data) {
                        res(transactionData.data.data)
                    } else {
                        rej({ err: 'transactionData data unavailable' });
                    }
                } else if (transactionData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: transactionData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: error });
                console.error("error", error);
            });
    })
};

export const getGiftCardDetailsById = (id) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_CUSTOMER}/guestGiftCardDetails?id=${id}`)  //get store details
            .then(async (resData) => {
                if (resData.status == 200) {
                    if (resData.data) {
                        res(resData.data.data)
                    } else {
                        rej({ err: 'resData data unavailable' });
                    }
                } else if (resData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: resData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: error });
                console.error("error", error);
            });
    })
};

export const getAdvanceBalanceDetailsById = (id) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_PCS_TXN}/advBalTxnById?txnId=${id}`)  //get store details
            .then(async (resData) => {
                if (resData.status == 200) {
                    if (resData.data) {
                        res(resData.data)
                    } else {
                        rej({ err: 'resData data unavailable' });
                    }
                } else if (resData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: resData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_ORDERS}/${id}`, status: error });
                console.error("error", error);
            });
    })
};