import { SERVICES_INDEX } from '@constant/common';
import { showSuccess, updatePdpItem } from '@context/actions';
import { replaceAppointmentServices } from '@context/actions/appointment';
import SvgIcon from '@element/svgIcon';
import { Box, ClickAwayListener } from '@material-ui/core';
import ConfirmationModal from '@module/confirmationModal';
import { getStaffByTenantAndStoreId, getStaffSlot } from '@storeData/staff';
import { navigateTo } from '@util/routerService';
import { formatTimeTo12Hr, getDateObj, isActiveAtBinaryIndex, removeObjRef } from '@util/utils';
import { forwardRef, Fragment, memo, useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';

function AiOutlineDelete(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" /></svg>;
}

function FaCalendarAlt(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="1em" width="1em" {...props}><path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" /></svg>;
}

function AppointmentServiceModal({ item, onClose }) {

    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const appointmentItems = useSelector((state: any) => state.appointmentServices);
    const [selectedItem, setSelectedItem] = useState(item)
    const [error, setError] = useState({ id: '', text: '' });
    const [anchorEl, setAnchorEl] = useState(null);
    const storeData = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [availableExpertSlotData, setAvailableExpertSlotData] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([])
    const { configData } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [isLoading, setIsLoading] = useState(false);
    const [dateChangeConfirmationModal, setDateChangeConfirmationModal] = useState({
        secondaryButtonText: "",
        openModal: false,
        title: '',
        message: '',
        buttonText: '',
        handleClose: null
    })
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    const activeLanguage: any = store.activeLanguage
    const DatePickerInput = forwardRef((props: any, ref: any) => <button className={`date-picker-button ${error.id == 'date' ? "error" : ""}`}
        onClick={props.onClick} ref={ref}>
        {languageData["Appointment Date"] ? languageData["Appointment Date"] : "Appointment Date"}: {props.value ? `: ${props.value}` : <div className='guided-label'>{languageData["Click to select date"] ? languageData["Click to select date"] : "Click to select date"}</div>}
    </button>);

    useEffect(() => {
        if (item) {
            setSelectedItem(item)
            setIsLoading(true)
            getStaffByTenantAndStoreId(storeData.tenantId, storeData.storeId)
                .then((expertsList: any) => {
                    setIsLoading(false)
                    if (expertsList) {
                        if (expertsList.length != 0) {
                            setAvailableExpertSlotData(expertsList.filter((e: any) => e.active));
                            let availableStaff = [];
                            if (selectedItem.experts) {
                                selectedItem.experts.map((expert: any) => {
                                    let alreadyAdded = availableStaff.length != 0 ? availableStaff.filter((s) => s.id == expert.id) : [];
                                    if (alreadyAdded.length == 0) {
                                        let staffs = expertsList.filter((s) => s.active && s.id == expert.id);
                                        staffs.length != 0 && availableStaff.push(staffs[0])
                                    }
                                })
                            }
                            if (availableStaff.length != 0) setAvailableExpertSlotData(availableStaff);
                            else setAvailableExpertSlotData([]);
                        } else setAvailableExpertSlotData([]);
                    }
                })
                .catch((e) => {
                    setIsLoading(false)
                    // dispatch(showError(e.error));
                })
        }
    }, [item])

    const onOutsideClick = (event: any) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !dateChangeConfirmationModal.openModal) {
            onClose();
        }
    }

    const onInputChange = (from, value) => {
        const selectedItemCopy = removeObjRef(selectedItem)
        selectedItemCopy.slot = null;
        selectedItemCopy[from] = value;
        setSelectedItem(selectedItemCopy)
        setError({ id: '', text: '' });
        setAnchorEl(null)

        if (selectedItemCopy.staff?.id && selectedItemCopy.date) {
            setIsLoading(true)
            getStaffSlot(getDateObj(new Date(selectedItemCopy.date)).dateObj, selectedItemCopy.staff?.id, storeData?.storeId, storeData?.tenantId).then((res: any) => {
                setIsLoading(false)
                setAvailableSlots(res.data || []);
                selectedItemCopy.staff.slots = res.data;
                setSelectedItem(selectedItemCopy)
            }).catch((err) => {
                setAvailableSlots([])
                setIsLoading(false)
            })
        }
        if (from == 'date') {
            setDateChangeConfirmationModal({
                secondaryButtonText: "",
                openModal: false,
                title: '',
                message: '',
                buttonText: '',
                handleClose: null
            })
        }
    }

    const onDateChange = (updatedDate) => {
        if (Boolean(appointmentItems.length) && (getDateObj(new Date(appointmentItems[0].date)).dateObj !== getDateObj(new Date(updatedDate)).dateObj)) {
            setDateChangeConfirmationModal({
                openModal: true,
                secondaryButtonText: `${languageData["Change"] ? languageData["Change"] : "Change"}`,
                title: `${languageData["Date Change Confirmation"] ? languageData["Date Change Confirmation"] : "Date Change Confirmation"}`,
                message: `${languageData["If you change the date, then the previously added service date and slot get reset"] ? languageData["If you change the date, then the previously added service date and slot get reset"] : "If you change the date, then the previously added service date and slot get reset."}`,
                buttonText: `${languageData["Yes"] ? languageData["Yes"] : "Yes"}`,
                handleClose: (status) => status ? onInputChange('date', updatedDate) :
                    setDateChangeConfirmationModal({
                        openModal: false,
                        secondaryButtonText: "",
                        title: '',
                        message: '',
                        buttonText: '',
                        handleClose: null
                    })
            })
        } else {
            //for the first time setting date
            onInputChange('date', updatedDate);
        }
    }

    const renderStaffList = () => {
        return <div className="staff-list-wrapper">
            <ClickAwayListener onClickAway={(e: any) => {
                setAnchorEl(null);
            }}>
                <Box>
                    {Boolean(availableExpertSlotData?.length) ? <>
                        {availableExpertSlotData.map((staff: any, i: number) => {
                            return <Fragment key={i}>
                                <div className={`item-details ${selectedItem.staff?.id == staff.id ? "active" : ''}`} onClick={() => onInputChange("staff", staff)}>{staff.firstName} {staff.lastName}</div>
                            </Fragment>
                        })}
                    </> : <>
                        <div className='unavailable-data'>{languageData["Staff Unavailable"] ? languageData["Staff Unavailable"] : "Staff Unavailable"}</div>
                    </>}
                </Box>
            </ClickAwayListener>
        </div>
    }

    const onSlotClick = (slot: any) => {
        setSelectedItem({ ...selectedItem, slot: slot })
    }

    const renderSlotsList = () => {
        if (!selectedItem.date && !selectedItem?.staff?.id) {
            return <div className='unavailable-data'>
                {languageData["Please select date & staff to view available slots"] ? languageData["Please select date & staff to view available slots"] : "Please select date & staff to view available slots"}
            </div>
        }
        if (!selectedItem.date) {
            return <div className='unavailable-data'>
                {languageData["Please select date to view available slots"] ? languageData["Please select date to view available slots"] : "Please select date to view available slots"}
            </div>
        }
        if (!selectedItem?.staff?.id) {
            return <div className='unavailable-data'>
                {languageData["Please select expert to view available slots"] ? languageData["Please select expert to view available slots"] : "Please select expert to view available slots"}
            </div>
        }
        if (availableSlots.length == 0) {
            return <div className='unavailable-data'>
                {languageData["Slots are unavailable for this date & staff"] ? languageData["Slots are unavailable for this date & staff"] : "Slots are unavailable for this date & staff"}
            </div>
        }

        let slotFounds = 0;
        const slots = appointmentItems.filter((i: any) => i.staff.id == selectedItem.staff.id).map(item => item.duration);
        let duration = slots.reduce((a: any, b: any) => a + b, 0)
        let availableDuration = 0;
        availableSlots.map((slot) => {
            if (slot.isAvailable) availableDuration += 30;
        })
        slotFounds = (availableDuration - duration) / 30
        let allSlotsCheck = (slotFounds >= (selectedItem.duration) / 30);
        let isSlotsAvailable = (slotFounds >= selectedItem.duration / 30);

        return <>
            <div className="slot-time-outer">
                {!allSlotsCheck && isSlotsAvailable && <div className='label error'>{duration} {languageData["mins of services are already added in your cart"] ? languageData["mins of services are already added in your cart"] : "mins of services are already added in your cart"}</div>}
                {!isSlotsAvailable && <div className='label error'>{selectedItem.duration} {languageData["min slot unavailable for selected staff"] ? languageData["min slot unavailable for selected staff"] : "min slot unavailable for selected staff"}</div>}
                {availableSlots.map((storeSlot) => {
                    return <div key={Math.random()} onClick={() => setSelectedItem({ ...selectedItem, slot: storeSlot.slot })}
                        className={`${storeSlot.isAvailable ? '' : "unavailable"} ${selectedItem.slot == storeSlot.slot ? 'slot-wrap active' : "slot-wrap"} ${!(isSlotsAvailable && allSlotsCheck) ? "disabled" : ""}`}>
                        {storeSlot.isAvailable ? formatTimeTo12Hr(storeSlot.slot) : "Booked"}
                    </div>
                })}
            </div>
        </>
    }

    const onAddMoreClick = () => {
        if (!selectedItem.date) {
            setError({ id: 'date', text: `${languageData['Please select date to view available slots'] ? languageData['Please select date to view available slots'] : 'Please select date to view available slots'}` });
            return
        }
        if (!selectedItem?.staff?.id) {
            setError({ id: 'staff', text: `${languageData['Please select expert to view available slots'] ? languageData['Please select expert to view available slots'] : 'Please select expert to view available slots'}` });
            return
        }
        if (!selectedItem?.slot) {
            setError({ id: 'slot', text: `${languageData['Please select slot'] ? languageData['Please select slot'] : 'Please select slot'}` });
            return
        }
        onBookClick(false)
        onClose();
    }

    const calculateTaxes = (taxebalePrice: any) => {
        let txchrgs: any[] = [];
        if (configData.txchConfig && configData.txchConfig.length != 0) {
            configData.txchConfig.map((taxData: any) => {
                if (taxData.active) {
                    if (isActiveAtBinaryIndex(taxData.applyOn, SERVICES_INDEX) && !taxData.charge) {
                        let taxApplied = parseFloat(((parseFloat(taxebalePrice) / 100) * parseFloat(taxData.value)).toFixed(2));
                        if (taxData.isInclusive) {
                            // x = (price * 100) / (tax + 100)
                            let itemActualPrice = ((taxebalePrice * 100) / (100 + taxData.value));
                            let actualTax = (itemActualPrice * taxData.value) / 100;
                            taxApplied = Number((actualTax).toFixed(2));
                            // tax = x * (tax / 100)
                        }
                        const taxObj = {
                            id: taxData.id,
                            name: taxData.name,
                            type: taxData.type,
                            isInclusive: taxData.isInclusive,
                            taxRate: taxData.value,
                            value: parseFloat((taxApplied).toFixed(2)),
                        }
                        txchrgs.push(taxObj);
                    }
                }
            })
        }
        return txchrgs;
    }

    const onBookClick = (navigate) => {
        if (!selectedItem.date) {
            setError({ id: 'date', text: `${languageData['Please select date to view available slots'] ? languageData['Please select date to view available slots'] : 'Please select date to view available slots'}` });
            return
        }
        if (!selectedItem?.staff?.id) {
            setError({ id: 'staff', text: `${languageData['Please select expert to view available slots'] ? languageData['Please select expert to view available slots'] : 'Please select expert to view available slots'}` });
            return
        }
        if (!selectedItem?.slot) {
            setError({ id: 'slot', text: `${languageData['Please select slot'] ? languageData['Please select slot'] : 'Please select slot'}` });
            return
        }
        const appointmentItemsCopy = appointmentItems ? [...appointmentItems] : [];
        const serviceCopy = { ...selectedItem };
        serviceCopy.txchrgs = [];
        let price: any = parseFloat(serviceCopy.price);
        let salePrice: any = parseFloat(serviceCopy.salePrice);
        serviceCopy.price = price;
        serviceCopy.salePrice = salePrice;
        serviceCopy.txchrgs = calculateTaxes(salePrice || price);
        appointmentItemsCopy.push(serviceCopy);
        //if date changed for current selected item then remove others staff and slot
        appointmentItemsCopy.map((item) => {
            if (getDateObj(new Date(item.date)).dateObj !== getDateObj(new Date(serviceCopy.date)).dateObj) {
                item.date = selectedItem.date;
                item.staff = null;
                item.slot = null;
            }
        })
        dispatch(updatePdpItem(null))
        dispatch(replaceAppointmentServices(appointmentItemsCopy));
        dispatch(showSuccess(`${languageData['Service Added'] ? languageData['Service Added'] : 'Service Added'}`, 2000));
        onClose();
        // dispatch(showServicesCart(true));
        navigate && navigateTo('appointment')
    }
    return (
        <div className='services-cart-modal-wrap backdrop-modal-wrapper'
            onClick={onOutsideClick}
            style={{
                height: 'calc(100vh - calc(100vh - 100%))',
                overflow: 'unset',
                maxHeight: 'calc(100vh - calc(100vh - 100%))',
                backgroundColor: '#0000001f'
            }}>
            <div className='services-cart-modal-details appointment-service-form backdrop-modal-content' ref={modalRef}>
                <div className='heading'>
                    {languageData["Service"] ? languageData["Service"] : "Service"} - {selectedItem.languages && selectedItem.languages[activeLanguage?.languageId]?.name ? selectedItem.languages[activeLanguage?.languageId]?.name : selectedItem.name}
                    <br />
                    <span style={{ marginTop: 10 }}> ({languageData["Duration"] ? languageData["Duration"] : "Duration"} : {selectedItem.duration} {languageData["Min"] ? languageData["Min"] : "Min"})</span>
                </div>
                <div className="modal-close" onClick={onClose}>
                    <SvgIcon icon="close" width={20} height={20} />
                </div>
                <div className='services-list-wrap'>
                    <div className="input-wrap-with-label date-picker-wrap card">
                        <DatePicker
                            selected={selectedItem.date ? new Date(selectedItem.date) : null}
                            onChange={(date) => onDateChange(date)}
                            customInput={<DatePickerInput from={"Appointment Date"} />}
                            // peekNextMonth
                            // showMonthDropdown
                            // showYearPicker={false}
                            // showYearDropdown={false}
                            // dropdownMode="select"
                            dateFormat={configData.dateFormat || "dd-MM-yyyy"}
                            minDate={new Date()}
                        />
                    </div>
                    <div className='card staff-list' >
                        <div className={`label ${error.id == 'staff' ? "error" : ""}`} onClick={(e) => setAnchorEl(e.currentTarget)}>
                            {languageData["Expert"] ? languageData["Expert"] : "Expert"}: {selectedItem.staff ? ` ${selectedItem?.staff?.firstName} ${selectedItem?.staff?.lastName}` : <div className='guided-label'>{languageData["Click to select expert"] ? languageData["Click to select expert"] : "Click to select expert"}</div>}
                        </div>
                        {Boolean(anchorEl) && renderStaffList()}
                    </div>

                    <div className='card slot-list'>
                        <div className={`label ${error.id == 'slot' ? "error" : ""}`}>{languageData["Slot"] ? languageData["Slot"] : "Slot"}: {formatTimeTo12Hr(selectedItem.slot)}</div>
                        {renderSlotsList()}
                    </div>
                    {/* {appointmentItems?.map((service: any) => {
                        return <div className="service-details" key={Math.random()}>
                            <div className="service-name">{service.name}
                                {service.variations && service.variations[0] && <>
                                    &nbsp;- {service.variations[0]?.name}
                                </>}
                            </div>

                        </div>
                    })} */}
                </div>
                {Boolean(error.text) && <div className='error'>{error.text}</div>}
                <div className="btn-wrap">
                    <div className="primary-btn" onClick={onAddMoreClick}>{languageData["Add more services"] ? languageData["Add more services"] : "Add more services"}</div>
                    <div className="primary-btn" onClick={() => onBookClick(true)}>{languageData["Book Appointment"] ? languageData["Book Appointment"] : "Book Appointment"}</div>
                </div>
            </div>
            <ConfirmationModal {...dateChangeConfirmationModal} />
        </div>
    )
}

export default memo(AppointmentServiceModal)