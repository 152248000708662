import { APPOINTMENT_CONFIRMED } from '@constant/appointment';
import { LOOK_WOW_TENANT_ID, PRODUCTS_INDEX, SERVICES_INDEX } from '@constant/common';
import { disableLoader, showSuccess } from '@context/actions';
import { replaceAppointmentServices } from '@context/actions/appointment';
import { replaceOrderIitems } from '@context/actions/order';
import { getOrderByOrderId } from '@storeData/order';
import { navigateTo } from '@util/routerService';
import { formatTimeTo12Hr, formatToDateFormat, getTofixValue, isActiveAtBinaryIndex } from '@util/utils';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from 'react-redux';

function OrderConfirmation({ inititalOrder = null }: any) {
    const [cookie] = useCookies();
    const [userData, setUserCookie] = useState(cookie['user']);
    const activeGroup = useSelector((state: any) => state.activeGroup);
    const dispatch = useDispatch();
    const router = useRouter()
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const { configData } = useSelector((state: any) => state.store ? state.store.storeData : null)
    const [pricingBreakdown, setPricingBreakdown] = useState({ total: 0, subTotal: 0, appliedTaxes: [] });
    const isDisposableAvailable = configData?.storeConfig?.basicConfig?.disposable_items;
    const basicConfig = configData?.storeConfig?.basicConfig
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    const activeLanguage: any = store.activeLanguage
    const appointmentItems = useSelector((state: any) => state.appointmentServices);
    const cartItems = useSelector((state: any) => state.orderItems);
    const updateLocalOrder = (orderData: any) => {
        const uniqueTypes = [...new Set(orderData.products.map((item: any) => item.type))];
        orderData.products = orderData.products.map((item: any) => {
            let originalItem = appointmentItems.find((i) => i.name == item.name) || cartItems.find((i) => i.name == item.name);
            if (originalItem) {
                item.languages = originalItem.languages;
            }

            return item;
        });
        setOrderData({ ...orderData, uniqueTypes });
        getPricingBreakdown(orderData)
    }
    const appointmentConfig = configData?.storeConfig?.appointmentConfig;
    useEffect(() => {
        dispatch(replaceAppointmentServices([]));
        dispatch(replaceOrderIitems([]));

        if (Boolean(inititalOrder)) {
            setOrderSuccess(true);
            inititalOrder.taxesTotal = inititalOrder?.total - inititalOrder?.subtotal;
            updateLocalOrder(inititalOrder)
        } else {
            dispatch(disableLoader());
            if (router.query.pagepath.includes("orderconfirmation")) {
                if (router.query.status == 'paid') {
                    setOrderSuccess(true);
                    getOrderByOrderId(router.query.id).then((order: any) => {
                        if (order) {
                            updateLocalOrder(order)
                        } else {
                            setOrderSuccess(false);
                            dispatch(showSuccess(`${languageData['Your payment has been failed'] ? languageData['Your payment has been failed'] : 'Your payment has been failed.'}`, 5000));
                        }
                    })
                } else {
                    setOrderSuccess(false);
                    dispatch(showSuccess(`${languageData['Your payment has been failed'] ? languageData['Your payment has been failed'] : 'Your payment has been failed.'}`, 5000));
                    let paymentFor = localStorage.getItem("payment_type")
                    if (paymentFor) {
                        if (paymentFor.includes('order')) navigateTo('cart')
                        if (paymentFor.includes('appointment')) navigateTo('appointment')
                    } else {
                        navigateTo('home')
                    }
                    localStorage.removeItem("payment_type");
                }
            }
        }
    }, [router])

    const calculateTaxes = (appliedTaxes, taxebalePrice: any, type: any) => {
        let txchrgs: any[] = appliedTaxes || [];
        if (configData.txchConfig && configData.txchConfig.length != 0) {
            configData.txchConfig.map((taxData: any) => {
                if (taxData.active) {
                    if (((isActiveAtBinaryIndex(taxData.applyOn, PRODUCTS_INDEX) && type == "product") || (isActiveAtBinaryIndex(taxData.applyOn, SERVICES_INDEX) && type == "service")) && !taxData.charge) {
                        let taxApplied = parseFloat(getTofixValue((parseFloat(taxebalePrice) / 100) * parseFloat(taxData.value)));
                        if (taxData.isInclusive) {
                            let itemActualPrice = ((taxebalePrice * 100) / (100 + taxData.value));
                            let actualTax = (itemActualPrice * taxData.value) / 100;
                            taxApplied = Number(getTofixValue(actualTax));
                        }
                        let isAVl = txchrgs.findIndex((at: any) => at.name == taxData.name);
                        if (isAVl != -1) {
                            txchrgs[isAVl].total = (Number(getTofixValue(appliedTaxes[isAVl].total + taxApplied)));
                        } else {
                            const taxObj = {
                                id: taxData.id,
                                name: taxData.name,
                                type: taxData.type,
                                isInclusive: taxData.isInclusive,
                                taxRate: taxData.value,
                                total: taxApplied,
                                value: parseFloat(getTofixValue(taxData.value)),
                            }
                            txchrgs.push(taxObj);
                        }

                    }
                }
            })
        }
        return txchrgs;
    }
    const getPricingBreakdown = (order) => {
        let total: any = 0;
        let subTotal: any = 0;
        let appliedTaxes: any[] = [];
        order.products.map((cartItem) => {

            let applicablePrice: any = (userData?.membership?.length == 1 && userData.membership[0].typeId == 4) ? (cartItem.memberPrice) * cartItem.quantity : (cartItem.salePrice || cartItem.price) * cartItem.quantity;
            subTotal += parseFloat(applicablePrice);
            total += parseFloat(applicablePrice);
            appliedTaxes = calculateTaxes(appliedTaxes, applicablePrice, cartItem.type)
        })
        appliedTaxes.map((taxData: any) => {
            if (!taxData.isInclusive) total = Number((total + taxData.total).toFixed(2));
        })
        const dispTotal = Boolean(order?.disposables?.length) ? order?.disposables?.reduce((a: any, b: any) => a + (Number(b.price) * Number(b.quantity)), 0) : 0;
        total = dispTotal + Number(total);
        setPricingBreakdown({ total, subTotal, appliedTaxes });
    }

    const AMPConversionTags = () => {
        return <>
            {Number(configData?.tenantId) == LOOK_WOW_TENANT_ID && <>
                {/* <!-- Event snippet for Page view conversion page -->  */}
                <script dangerouslySetInnerHTML={{ __html: `gtag('event', 'conversion', {'send_to': 'AW-16565257530/YiqyCJCv_bAZELqK99o9'});` }} />

                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                                            <amp-analytics type="gtag" data-credentials="include">
                                            <script type="application/json"> { "vars": { "gtag_id": "AW-16565257530", "config": { "AW-16565257530": { "groups": "default" } } }, "triggers": {"C_KsEUDdlFXOk": { "on": "visible", "vars": { "event_name": "conversion", "send_to": ["AW-16565257530/YiqyCJCv_bAZELqK99o9"] } }} } </script>
                                            </amp-analytics>`
                    }}
                />
            </>}
        </>
    }

    return (
        <>
            {orderSuccess ? <div className={`con-main-wrap clearfix`} style={{ padding: Boolean(inititalOrder) ? 0 : "10px 0 0" }}>
                <div className="main-wrapper order-confirmation" style={{ background: 'transparent' }}>
                    <div className='appointment-checkout-content'>
                        <AMPConversionTags />
                        <div className="con-wrap">
                            {/* <div className='image-wrap'>
                                <img className="cart-logo" alt="Respark" src={`/assets/images/${activeGroup}/order_confirm.png`} />
                            </div> */}
                            {Boolean(orderData?.appointmentId) ?
                                <div className="username instruction appt-status-note cap-text">
                                    Hi {userData?.firstName}, {languageData["your appointment has been initiated for the below services"] ? languageData["your appointment has been initiated for the below services"] : "your appointment has been initiated for the below services"}.</div>
                                : <>
                                    <div className="cart-status">{userData.firstName}</div>
                                    <div className="cart-subtext">{languageData["Thank you for your purchase"] ? languageData["Thank you for your purchase"] : "Thank you for your purchase"}.</div>
                                    {(orderData && orderData?.type == 'delivery') ?
                                        <div className="username instruction">{configData?.deliveryDisclaimer}</div> :
                                        <div className="username instruction">{configData?.pickupDisclaimer}</div>
                                    }
                                </>}
                        </div>

                        <div className="date-id-wrap card">
                            <div className="date-time">
                                <div className='sub-heading'>{languageData["Date"] ? languageData["Date"] : "Date"}:</div>
                                <div className="date">{formatToDateFormat(orderData?.orderDay, configData.dateFormat)}{Boolean(orderData?.orderTime) && <>, {formatTimeTo12Hr(orderData?.orderTime)}</>}
                                </div>
                            </div>
                            {Boolean(orderData?.orderId) && <div className="date-time">
                                <div className='sub-heading'>{languageData["Order Id"] ? languageData["Order Id"] : "Order Id"}:</div>
                                <div className="date">{orderData?.orderId}</div>
                            </div>}
                        </div>

                        {Boolean(orderData?.bookedFor) && <div className="date-id-wrap card">
                            <div className="date-time">
                                <div className='sub-heading'>{languageData["Booked For"] ? languageData["Booked For"] : "Booked For"}:</div>
                                <div className="date cap-text">{orderData?.bookedFor?.name} {basicConfig.multipleCountryCode ? ("+" + orderData?.bookedFor?.countryCode + " " + orderData?.bookedFor?.mobileNo) : (orderData?.bookedFor?.mobileNo)}</div>
                            </div>
                        </div>}

                        {Boolean(orderData?.selectedExpert) && <div className="date-id-wrap card">
                            <div className="date-time">
                                <div className='sub-heading'>{languageData["Preferred Stylist"] ? languageData["Preferred Stylist"] : "Preferred Stylist"}:</div>
                                <div className="date cap-text">{orderData?.selectedExpert?.firstName} {orderData?.selectedExpert?.lastName}</div>
                            </div>
                        </div>}

                        {(Boolean(inititalOrder || orderData) && Boolean((inititalOrder || orderData).products[0].staff)) ? <div className="services-list-wrap card">
                            <div className="sub-heading">{languageData["Services"] ? languageData["Services"] : "Services"}</div>
                            <div className='service-details heading'>
                                <div className="service-name" >{languageData["Name"] ? languageData["Name"] : "Name"}</div>
                                <div className="service-name slot" >{languageData["Slot"] ? languageData["Slot"] : "Slot"}</div>
                                <div className="service-name expert" >{languageData["Expert"] ? languageData["Expert"] : "Expert"}</div>
                                <div className="service-name expert">{languageData["Price"] ? languageData["Price"] : "Price"}</div>
                            </div>
                            {(inititalOrder || orderData).products?.map((item, index) => {
                                return <div className='service-details' key={index}>
                                    <div className="service-name" >{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name}</div>
                                    <div className="service-name slot" >{formatTimeTo12Hr(item.appointmentTime)}</div>
                                    <div className="service-name expert" >{item?.staff || ''}</div>
                                    <div className="service-name expert">{configData?.currencySymbol}{item.billingPrice}</div>
                                </div>
                            })}
                        </div> : <>
                            {orderData?.uniqueTypes?.map((type: any) => {
                                return <Fragment key={type}>
                                    {Boolean(orderData?.products?.length) && <div className="services-list-wrap card">
                                        <div className="sub-heading cap-text">{type + "s"}</div>
                                        {orderData?.products?.map((item) => {
                                            return <div className="service-name" key={Math.random()}>
                                                <div>{item?.languages && item?.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name}</div>
                                                <div>{configData?.currencySymbol}{item.billingPrice}</div>
                                            </div>
                                        })}
                                    </div>}
                                </Fragment>
                            })}
                        </>}


                        {Boolean(orderData?.disposables?.length) && <div className="services-list-wrap card">
                            <div className="sub-heading cap-text">{languageData["Extra Charges"] ? languageData["Extra Charges"] : "Extra Charges"}</div>
                            {orderData?.disposables?.map((item) => {
                                return <div className="service-name" key={item.name}>
                                    <div>{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name} {item.quantity > 1 && <> &nbsp;({item.quantity})</>}</div>
                                    <div>{configData?.currencySymbol}{(item.price) * (item.quantity)}</div>
                                </div>
                            })}
                        </div>}

                        <div className="appt-for-wrap card">
                            <div className='sub-heading'>{languageData["Payment Summary"] ? languageData["Payment Summary"] : "Payment Summary"}</div>
                            <div className='pricing-details-wrap d-f-c'>
                                <div className='heading'>
                                    <div className='title'>{languageData["SubTotal"] ? languageData["SubTotal"] : "SubTotal"}</div>
                                    {(isDisposableAvailable && Boolean(orderData?.disposables?.length)) && <div className='title'>Extra Charges</div>}
                                    {pricingBreakdown.appliedTaxes?.map((taxData: any) => {
                                        return <div className='title' key={taxData.name}>{taxData.name}{taxData.isInclusive ? "(Inclusive)" : ''}({taxData.value}%)</div>
                                    })}
                                    <div className='title grand-total'>{languageData["Grand Total"] ? languageData["Grand Total"] : "Grand Total"}</div>
                                </div>
                                <div className='details'>
                                    <div className='value'>{configData.currencySymbol} {pricingBreakdown.subTotal}</div>
                                    {(isDisposableAvailable && Boolean(orderData?.disposables?.length)) && <div className='value'>{configData.currencySymbol} {orderData?.disposables?.reduce((a: any, b: any) => a + (Number(b.price) * Number(b.quantity)), 0)}</div>}
                                    {pricingBreakdown.appliedTaxes?.map((taxData: any, index: number) => {
                                        return <div className='value' key={taxData.name}>{configData.currencySymbol} {taxData.total}</div>
                                    })}
                                    <div className='value grand-total'>{configData.currencySymbol} {pricingBreakdown.total}</div>
                                </div>
                            </div>
                        </div>

                        {Boolean(orderData?.payments?.length) && <div className="date-id-wrap card">
                            <div className="sub-heading">{languageData["Amount Paid"] ? languageData["Amount Paid"] : "Amount Paid"}</div>
                            {orderData?.payments?.map((paymode: any, i: number) => {
                                return <div key={paymode?.name} className='sub-heading '>{paymode?.name}: {configData.currencySymbol}{paymode.payment}</div>
                            })}
                        </div>}

                        {Boolean(appointmentConfig?.enableCatalogReschedule) && Boolean(orderData?.appointmentId) &&
                            <div className="username instruction">{languageData["Appointment Cancellation Info"]
                                ? languageData["Appointment Cancellation Info"]
                                : `Appointment can be cancelled or rescheduled up to ${appointmentConfig?.rescheduleLimit} ${languageData["Time Before Scheduled Time"] || "hours before the scheduled time"}`}</div>}

                        {Boolean(orderData?.appointmentId) && <>
                            {Boolean(inititalOrder || orderData) && (inititalOrder || orderData).statuses[0].state == APPOINTMENT_CONFIRMED ? <div className="username instruction"> {languageData["We have received your appointment and it is confirmed"] ? languageData["We have received your appointment and it is confirmed"] : "We have received your appointment and it is confirmed"}.</div> :
                                <div className="username instruction">{languageData["We have received your appointment details. Kindly wait for our confirmation"] ? languageData["We have received your appointment details. Kindly wait for our confirmation"] : "We have received your appointment details. Kindly wait for our confirmation"}.</div>}
                        </>}

                        {Boolean(inititalOrder) && <div className='confirm-btn-wrap book-wrap alignment'>
                            <button className="primary-btn rounded-btn" onClick={() => navigateTo('home')}>{languageData["Explore More"] ? languageData["Explore More"] : "Explore More"}</button>
                        </div>}
                    </div>
                </div>
            </div>
                :
                <div className="con-main-wrap">
                    <div className="con-wrap">
                        <div><img className="cart-logo" alt="Respark" src={`/assets/images/${activeGroup}/order_confirm.png`} style={{ width: '70%' }} /></div>
                        <div className="cart-status">{userData.firstName}</div>
                        <div className="cart-subtext">{languageData["Ohh! Your payment has been failed"] ? languageData["Ohh! Your payment has been failed"] : "Ohh! Your payment has been failed"}.</div>
                        {/* <div className="cart-subtext">Your item is ready for pickup, kindly collect it from frontdesk.</div> */}
                        <div><button className="cart-button" onClick={() => navigateTo('home')}>{languageData["Explore More"] ? languageData["Explore More"] : "Explore More"}</button></div>
                    </div>
                </div>}
        </>
    )
}

export default OrderConfirmation;
