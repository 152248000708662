import { APISERVICE } from "@api/RestClient";

export const getAppointmentById = (appId) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_GET_APPOINTMENT_BY_ID}/${appId}`)  //get store details
            .then(async (configResponse) => {
                if (configResponse.status == 200) {
                    const appointmentData = configResponse.data;
                    if (appointmentData) {
                        res(appointmentData)
                    } else {
                        rej({ err: 'Appointment data unavailable' });
                    }
                } else if (configResponse.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_GET_APPOINTMENT_BY_ID}/${appId}`, status: configResponse.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_GET_APPOINTMENT_BY_ID}/${appId}`, status: error });
                console.error("error", error);
            });
    })
};

export const getAppointmentByTenantIdAndGuestId = (guestid, tenantid) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/tenant/${tenantid}/guest/${guestid}`)  //get appointment history
            .then(async (appData) => {
                if (appData.status == 200) {
                    const appointments = appData.data;
                    if (appointments) {
                        res(appointments)
                    } else {
                        rej({ error: 'appointment unavailable' });
                    }
                } else if (appData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/tenant/${tenantid}/guest/${guestid}`, status: appData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/tenant/${tenantid}/guest/${guestid}`, status: error });
                console.error("error", error);
            });
    })
};

  export const rescheduleAppointment = (body: { appId: string, date?: string, time?: string, sendSms?: boolean }) => {
    return new Promise((res, rej) => {
        APISERVICE.PUT(`${process.env.NEXT_PUBLIC_PCS_TXN}/appointment/reschedule`, body)
        .then(async (appData) => {
                if (appData.status == 200) {
                    const appointments = appData.data;
                    if (appointments) {
                        res(appointments)
                    } else {
                        rej({ error: 'appointment unavailable' });
                    }
                } else if (appData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PCS_TXN}/appointment/reschedule`, status: appData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PCS_TXN}/appointment/reschedule`, status: error });
                console.error("error", error);
            });
    })
  }

  export const upDateAppointment = (appointMent) => {
    return new Promise((res, rej) => {
        APISERVICE.POST(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}`, appointMent)
        .then(async (appData) => {
                if (appData.status == 200) {
                    const appointments = appData.data;
                    if (appointments) {
                        res(appointments)
                    } else {
                        rej({ error: 'appointment unavailable' });
                    }
                } else if (appData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}`, status: appData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}`, status: error });
                console.error("error", error);
            });
    })
  }

   export const requestAppointmentReschedule = (appointment) => {
    return new Promise((res, rej) => {
        APISERVICE.POST(`${process.env.NEXT_PUBLIC_PCS_TXN}/appointment/reschedule-request`, appointment)
        .then(async (appData) => {
                if (appData.status == 200) {
                    const appointments = appData.data;
                    if (appointments) {
                        res(appointments)
                    } else {
                        rej({ error: 'appointment unavailable' });
                    }
                } else if (appData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PCS_TXN}/appointment/reschedule-request`, status: appData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_PCS_TXN}/appointment/reschedule-request`, status: error });
                console.error("error", error);
            });
    })
  } 
