import { APISERVICE } from "@api/RestClient";
export const getStaffByTenantAndStoreId = (tenantId, storeId) => {
    return new Promise((res, rej) => {
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_BASE_PCS_URL}/staffs/${tenantId}/${storeId}`)  //get staff details
            .then((staffData) => {
                if (staffData.status == 200) {
                    const staff = staffData.data;
                    if (staff) {
                        res(staff)
                    } else {
                        rej({ err: 'staff data unavailable' });
                    }
                } else if (staffData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_BASE_PCS_URL}/staffs/${tenantId}/${storeId}`, status: staffData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_BASE_PCS_URL}/staffs/${tenantId}/${storeId}`, status: error });
                console.error("error", error);
            });
    })
};

export const getStaffSlot = (date, id, storeId, tenantId) => {
    return new Promise((res, rej) => {
        // https://dev-respark.respark.in:8081/pcs/v1/availableStaffSlots?staffId=2&startDate=2024-08-26&storeId=3&tenantId=2
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_BASE_PCS_URL}/availableStaffSlots?staffId=${id}&startDate=${date}&storeId=${storeId}&tenantId=${tenantId}&timeZone=${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}`)  //get staff details
            .then((slotsData) => {
                if (slotsData.status == 200) {
                    const slots = slotsData.data;
                    if (slots) {
                        res(slots)
                    } else {
                        rej({ err: 'slots data unavailable' });
                    }
                } else if (slotsData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_GET_STAFF}/${id}`, status: slotsData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_GET_STAFF}/${id}`, status: error });
                console.error("error", error);
            });
    })
};

export const getAllStaffSlot = (date, storeId, tenantId) => {
    return new Promise((res, rej) => {
        // All Guest - https://dev-respark.respark.in:8081/pcs/v1/allStaffsSlots?startDate=2024-08-26&storeId=3&tenantId=2
        APISERVICE.GET(`${process.env.NEXT_PUBLIC_BASE_PCS_URL}/allStaffsSlots?startDate=${date}&storeId=${storeId}&tenantId=${tenantId}&timeZone=${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}`)  //get staff details
            .then((slotsData) => {
                if (slotsData.status == 200) {
                    const slots = slotsData.data;
                    if (slots) {
                        res(slots)
                    } else {
                        rej({ err: 'slots data unavailable' });
                    }
                } else if (slotsData.status == 401) {
                    rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_GET_STAFF}`, status: slotsData.status });
                }
            }).catch(function (error) {
                rej({ error: `API FAILED ==> ${process.env.NEXT_PUBLIC_GET_STAFF}`, status: error });
                console.error("error", error);
            });
    })
};