const orderRef = {
    "id": null,
    "storeId": null,
    "tenant": null,
    "store": null,
    "type": null,
    "tenantId": null,
    "storeKey": null,
    "orderId": null,
    "guest": null,
    "guestId": null,
    "phone": null,
    "email": null,
    "remark": null,
    "subtotal": null,
    "total": null,
    "orderDay": null,
    "orderTime": null,
    "createdOn": null,
    "modifiedOn": null,
    "payMode": null,
    "payVendor": null,
    "rzrOrderId": null,
    "rzrPaymentId": null,
    "rzrSignature": null,
    "paidAmount": null,
    "address": null,
    "appointmentId": null,
    "products": null,
    "statuses": null,
    "payments": null,
    "discount": null,
    "smsKeys": {
        "orderFeedback": false,
        "orderAccepted": false,
        "orderRejected": false,
        "orderInvoice": false
    },
    "guestGSTN": null
};


export function createOrderFromAppointment(appointment: any) {

    const order: any = { ...orderRef };
    order.orderDay = `${appointment.appointmentDay}`;
    order.orderTime = appointment.appointmentTime;
    order.remark = appointment.instruction;
    order.storeId = appointment.storeId;
    order.tenantId = appointment.tenantId;
    order.store = appointment.store;
    order.bookedFor = appointment.bookedFor;
    order.tenant = appointment.tenant;
    order.guestId = appointment.guestId;
    order.guest = appointment.guestName;
    order.phone = appointment.guestMobile;
    order.createdOn = appointment.createdOn;
    order.type = appointment.type || "pickup";
    order.appointmentId = appointment.id;
    order.smsKeys = appointment.smsKeys;
    order.disposables = appointment.disposables;
    order.statuses = getOrderStatusesFromAppointment(appointment);
    order.products = getOrderProductFromExprtAppt(appointment);
    order.appointment = appointment;
    order.membershipUtilizedByGuestId = appointment.membershipUtilizedByGuestId;
    order.membershipUtilizedByGuestName = appointment.membershipUtilizedByGuestName;
    order.membershipUtilizedByGuestMobNo = appointment.membershipUtilizedByGuestMobNo;
    order.hsnSac = appointment.hsnSac;
    return JSON.parse(JSON.stringify(order));
}

export function createOrderFromAppointmentAndOrder(appointment: any, order: any) {
    order.remark = appointment.instruction;
    let addedServices = getOrderProductFromExprtAppt(appointment, order)
    order.orderStatuses = order.statuses;
    order.statuses = getOrderStatusesFromAppointment(appointment);
    order.products = [...addedServices, ...order.products.filter((p: any) => p.type == 'product')]
    order.appointment = appointment;
    order.membershipUtilizedByGuestId = appointment.membershipUtilizedByGuestId;
    order.membershipUtilizedByGuestName = appointment.membershipUtilizedByGuestName;
    order.membershipUtilizedByGuestMobNo = appointment.membershipUtilizedByGuestMobNo;
    order.type = appointment.type || "pickup";
    return JSON.parse(JSON.stringify(order));
}

export function updateAppointmentFromOrder(appointment: any, order: any) {
    const appointmentCopy = { ...appointment };
    appointmentCopy.appointmentDay = new Date(order.orderDay);
    appointmentCopy.appointmentTime = new Date(order.orderTime);
    appointmentCopy.instruction = order.remark;
    appointmentCopy.guestId = order.guestId;
    appointmentCopy.guest = order.guestName;
    appointmentCopy.phone = order.guestMobile;
    appointmentCopy.smsKeys = order.smsKeys;
    appointmentCopy.status = order.statuses.map((status: any) => {
        return {
            id: status.id,
            status: status.state,
            staff: status.staff,
            createdOn: status.createdOn,
            remark: status.remark,
        }
    })
    appointmentCopy.expertAppointments = getExpertApptFromOrderProduct(order)
    return JSON.parse(JSON.stringify(appointmentCopy));
}

export function getOrderProductFromExprtAppt(appointment: any, order: any = null) {
    const products = appointment.expertAppointments.map((appointmentService: any) => {
        let orderItem = {};
        if (order) {
            orderItem = order.products.find((i: any) => i.id == appointmentService.serviceId);
        }
        return {
            ...orderItem,
            "id": appointmentService.serviceId,
            "name": appointmentService.service,
            "categoryId": appointmentService.serviceCategoryId,
            "category": appointmentService.serviceCategory,
            "price": appointmentService.price,
            "salePrice": appointmentService.salePrice,
            "billingPrice": appointmentService.billingPrice,
            "memberPrice" : appointmentService.memberPrice,
            "variations": appointmentService.variations,
            "quantity": appointmentService.quantity,
            "reminder": appointmentService.reminder,
            "txchrgs": appointmentService.txchrgs,
            "staffId": appointmentService.expertId,
            "staff": appointmentService.expertName,
            "type": 'service',
            "slot": appointmentService.slot,
            "duration": appointmentService.duration,
            "appointmentTime": appointmentService.appointmentTime,
            "consumables": appointmentService.consumables,
            "contributions": appointmentService.contributions,
            // "appointmentDay": appointmentService.appointmentDay,//remove from expertappointment
        }
    })
    return JSON.parse(JSON.stringify(products));;
}
export function getExpertApptFromOrderProduct(order: any) {
    const expertAppointments = order.products.map((product: any) => {
        return {
            "duration": product.duration || 30,
            "serviceCategory": product.category || '',
            "serviceCategoryId": product.categoryId,
            "service": product.name || '',
            "slot": product.slot || '',
            "expertId": product.staffId || '',
            "expertName": product.staff || '',
            "salePrice": product.salePrice,
            "price": product.price,
            "billingPrice": product.billingPrice,
            "quantity": product.quantity,
            "serviceId": product.id,
            "txchrgs": product.txchrgs,
            "variations": product.variations,
            "reminder": product.reminder,
            "fromPackage": product.fromPackage,
            "discount": product.discount,
            "hsnSac": product.hsnSac,
        }
    })
    return expertAppointments;
}
export function getOrderStatusesFromAppointment(appointment: any) {
    const statuses = appointment.status.map((status: any) => {
        return {
            "id": status.id,
            "state": status.status,
            "staff": status.staff,
            "createdOn": status.createdOn,
            "remark": status.remark || ''
        }
    })
    return statuses;
}

export function getOrderItemApplicablePrice(item: any) {
    // here variation price not considered because at the time of order booking it is set inside products object as price and salePrice
    // const prices = [parseFloat(item.billingPrice), parseFloat(item.salePrice), parseFloat(item.salePrice)]
    // let applicablePrice: any = Math.min.apply(null, prices.filter(Boolean)) || 0;
    let applicablePrice: any = (('billingPrice' in item) ? parseFloat(item.billingPrice) : (parseFloat(item.salePrice) || parseFloat(item.price))) * item.quantity;
    if ((item.complementary?.remark)) {//if item is complimentary do not calculate price
        applicablePrice = 0;
    }
    if (item.fromPackage) {//if item is complimentary do not calculate price
        applicablePrice = 0;
    }
    if (Number(item.membershipPrice)) {//if item is complimentary do not calculate price
        applicablePrice = (item.billingPrice * item.quantity) - Number(item.membershipPrice);
    }
    return applicablePrice;
}