import { disableLoader } from '@context/actions';
import { getBillingData } from '@storeData/common';
import { getUserByGuestId } from '@storeData/user';
import { getCurrencySymbol, getTofixValue } from '@util/utils';
import router from "next/router";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


function AdvanceBalanceInvoiceModal({ handleClose, orderData }) {
    const { configData } = useSelector((state: any) => state.store.storeData);
    const orderId = router.query.pagepath ? router.query.pagepath[1] : '';
    const [storeMetaData, setStoreMetaData] = useState<any>({}); const [userDetails, setUserDetails] = useState(null);
    const dispatch = useDispatch();
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    const [totalAmount, setTotalAmount] = useState(0);
    const isBalanceBill = Boolean(orderData?.paidBalanceAmount);

    useEffect(() => {
        getBillingData(configData.tenantId, configData.storeId).then((res: any) => {
            setStoreMetaData(res);
        });
        getUserByGuestId(orderData?.tenantId, orderData?.storeId, orderData.guestId).then((response) => {
            dispatch(disableLoader());
            setUserDetails(response)
        }).catch(function (error) {
            dispatch(disableLoader());
            console.log("error");
        });

        setTotalAmount(isBalanceBill ? orderData.paidBalanceAmount : orderData.advanceAmount)
    }, [])

    return (
        <div className="invoice-wrapper giftcard-invoice-wrapper ">
            {/* <div className='page-heading'>Order Invoice</div> */}
            {orderData ? <div className="invoice-page-wrap">
                <div className='salon-details-wrap'>
                    <div className='logo-wrap'>
                        <img src={storeMetaData.logoPath} alt="Respark" />
                    </div>
                    <div className='salon-details'>
                        <div className='name'>
                            {storeMetaData?.tenantName && storeMetaData.tenantName}
                            {storeMetaData?.tenantName && storeMetaData?.storeName ? ', ' : ''}
                            {storeMetaData?.storeName && storeMetaData.storeName}
                        </div>
                        {storeMetaData.companyName && <div className='company-name'>{storeMetaData.companyName}</div>}
                        <div className='address s-detail'>{storeMetaData?.address}
                        </div>
                        <div className='phone s-detail'>{storeMetaData?.phone}
                            {storeMetaData?.phone1 && storeMetaData?.phone && <>, </>}
                            {storeMetaData?.phone1 && <>{storeMetaData?.phone1}</>}
                        </div>
                        <div className='email s-detail'>{storeMetaData?.email}</div>
                        {storeMetaData?.gstNo && <div className='gstn s-detail'>{languageData["GSTN"] ? languageData["GSTN"] : "GSTN"}: {storeMetaData?.gstNo}</div>}
                        {storeMetaData?.crNo && <div className='gstn s-detail'>CRNo: {storeMetaData?.crNo}</div>}
                        {storeMetaData?.vatNo && <div className='gstn s-detail'>VAT: {storeMetaData?.vatNo}</div>}
                    </div>
                </div>
                <div className='user-details-wrap'>
                    <div className='user-details'>
                        <div className='subheading'>{languageData["Bill to"] ? languageData["Bill to"] : "Bill to"}:</div>
                        <div className='name'>{userDetails?.firstName}</div>
                        <div className='phone'>{userDetails?.mobileNo} {userDetails?.email && <>{userDetails?.email}</>}</div>
                        {userDetails?.gstN && <div className='name'>{languageData["GSTN"] ? languageData["GSTN"] : "GSTN"}: {userDetails?.gstN}</div>}
                        {(userDetails?.addressList && userDetails?.addressList.length != 0 && userDetails?.addressList[0].line) ?
                            <div className='user-address phone'>{userDetails?.addressList[0].line},&nbsp;
                                {userDetails?.addressList[0].area && <>{userDetails?.addressList[0].area}, </>}
                                {userDetails?.addressList[0].landmark && <>{userDetails?.addressList[0].landmark}, </>}
                                {userDetails?.addressList[0].city && <>{userDetails?.addressList[0].city}, </>}
                                {userDetails?.addressList[0].code && <>{userDetails?.addressList[0].code} </>}
                            </div> :
                            <></>}
                    </div>
                    <div className='date-wrap'>
                        <div className='subheading'>{languageData["Date"] ? languageData["Date"] : "Date"}: <div className='time'>{orderData?.createdOn?.substring(0, 10)}</div></div>
                        <div className='subheading'>{languageData["Time"] ? languageData["Time"] : "Time"}: <div className='time'>{`${new Date(orderData?.createdOn).getHours()}:${new Date(orderData?.createdOn).getMinutes()}`}</div></div>
                    </div>
                </div>

                <div className="bill-item-type-wrap">
                    <div className="heading cap-text">{`${isBalanceBill ? 'Balance Repayment' : 'Advance Payment'} Bill`}</div>
                    <div className='invoice-details-wrap order-invoice-details'>
                        <div className='services-list-wrap'>
                            <div className='heading-wrap d-f-c'>
                                <div className='name'>Name</div>
                                <div className='amt'>{languageData["Amt"] ? languageData["Amt"] : "Amt"}.</div>
                            </div>
                            <div className='details-wrap'>
                                <div className='width-100' >
                                    <div className='service-details d-f-c'>
                                        <div className='name'>{`${isBalanceBill ? 'Balance Paid' : 'Advance Received'}`}</div>
                                        <div className='amt'>{getTofixValue(totalAmount)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='invoice-total-details-wrap type-wise-order-invoice'>
                            <div className='total-details'>
                                <div className="total-entity-wrap">
                                    <div className='title'>{languageData["Grand Total"] ? languageData["Grand Total"] : "Grand Total"}</div>
                                    <div className='value'>{getCurrencySymbol()}{totalAmount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='payment-wrap'>
                    <div className='payment-by d-f-c'>
                        <div className='paid-via'>
                            <span>{languageData["Paid Via"] ? languageData["Paid Via"] : "Paid Via"} : </span>
                            <>{orderData?.paymode} - {getTofixValue(totalAmount, true)}</>

                        </div>
                    </div>
                </div>
                <div className='note'>
                    {languageData["Thank you for choosing us"] ? languageData["Thank you for choosing us"] : "Thank you for choosing us"}
                </div>
            </div> : <>
                {orderId ? <div className='no-data card'>
                    {languageData["The invoice you are looking for is not available"] ? languageData["The invoice you are looking for is not available"] : "The invoice you are looking for is not available"}
                </div> : <div className='no-data card'>
                    {languageData["Invalid link"] ? languageData["Invalid link"] : "Invalid link"}                </div>}
            </>}
        </div>
    )
}

export default AdvanceBalanceInvoiceModal