import { disableLoader, enableLoader, showError, showInfo, showSuccess } from "@context/actions";
import { upDateAppointment } from "@storeData/appointment";
import { formatTimeTo12Hr, formatToDateFormat } from "@util/utils";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop } from "@material-ui/core";
import SvgIcon from '@element/svgIcon';


const AppointmentCancelCard = ({ appointment, openModal, handleClose }) => {
    const APPOINTMENT_CANCELLED = "CANCELLED";
    const dispatch = useDispatch();
    const store = useSelector((state: any) => state);
    const { configData, keywords } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const languageData: any = store.languageKeywords

    const onCancelAppt = () => {
        dispatch(enableLoader());
        const appointmentCopy = appointment;
        appointmentCopy.status.push({
            "status": APPOINTMENT_CANCELLED,
            "staff": null,
            "createdOn": new Date(),
            "staffName": "",
            "remark": "Cancellation Requested by User"
        })
        upDateAppointment(appointmentCopy)
            .then((response: any) => {
                if (response) {
                    dispatch(disableLoader());
                    dispatch(showSuccess(`${languageData["Appointment Cancelled Successfully"] ? languageData["Appointment Cancelled Successfully"] : "Appointment Cancelled Successfully"}`))
                }
            })
            .catch((e) => {
                dispatch(disableLoader());
                dispatch(showError(e.error));
            })
        handleClose();
    }

    return (
        <>
            <div className="confirmation-modal-wrap">
                <Backdrop
                    className="backdrop-modal-wrapper confirmation-modal-wrap"
                    open={openModal ? true : false}
                >
                    <div className="backdrop-modal-content query-modal-wrap" style={{ height: openModal ? 'auto' : '0px' }}>
                        <div className="modal-close" onClick={handleClose}>
                            <SvgIcon icon="closeLarge" />
                        </div>

                        <div className="appointment-details">
                            <div className="heading">  {languageData["Cancel Appointment"] ? languageData["Cancel Appointment"] : "Cancel Appointment"}</div>
                            <p>
                                <h3>{`${formatToDateFormat(appointment.appointmentDay, configData.dateFormat)} at ${formatTimeTo12Hr(appointment.appointmentTime)}`}</h3>
                            </p>
                            <p> {languageData["Are you sure you want to cancel the appointment"] ? languageData["Are you sure you want to cancel the appointment"] : "Are you sure you want to cancel the appointment"} ?</p>
                        </div>
                        <div className="appointment-actions cancel-buttons">
                            {<button className="primary-btn rounded-btn" onClick={onCancelAppt}>
                                {languageData["Confirm"] ? languageData["Confirm"] : "Confirm"}
                            </button>}
                            {<button className="primary-btn rounded-btn" onClick={handleClose}>
                                {languageData["Cancel"] ? languageData["Cancel"] : "Cancel"}
                            </button>}
                        </div>
                    </div>
                </Backdrop>
            </div>
        </>
    );
};

export default AppointmentCancelCard;
