import { disableLoader, enableLoader, showError, showInfo } from '@context/actions';
import SvgIcon from '@element/svgIcon';
import { Backdrop } from '@material-ui/core';
import OrderBillPrintModal from '@module/orderBillPrintModal';
import { getAppointmentByTenantIdAndGuestId } from '@storeData/appointment';
import { formatTimeTo12Hr, formatToDateFormat } from '@util/utils';
import { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from 'react-redux';
import BookedSlotRescheduleModal from './bookedSlotsReschedule'
import SlotRescheduleModal from "./slotsReschedule";
import AppointmentCancelCard from './appointmentCancelModal';

function AppointmentHistoryPage() {
    const dispatch = useDispatch();
    const [cookie, setCookie] = useCookies();
    const [userData, setUserCookie] = useState(cookie['user']);
    const store = useSelector((state: any) => state);
    const [appointmentHistoryData, setAppointmentHistoryData] = useState([]);
    const [activeAppt, setactiveAppt] = useState(null);
    const { configData } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [action, setAction] = useState("")
    const [detailsModal, setDetailsModal] = useState(false);
    const appointmentConfig = configData?.storeConfig?.appointmentConfig;
    const [selectedDate, setSelectedDate] = useState<any>(new Date());
    const languageData: any = store.languageKeywords

    useEffect(() => {
        dispatch(enableLoader());
        getAppointmentByTenantIdAndGuestId(userData?.id, userData?.tenantId)
            .then((appointment: any) => {
                if (appointment?.length) {
                    appointment.sort(function (a: any, b: any) {
                        var dateA: any = a.appointmentDay.split("T")[0]
                        var dateB: any = b.appointmentDay.split("T")[0]
                        if (dateA === dateB) {
                            const [hoursA, minutesA] = a.appointmentTime.split(":").map(Number);
                            const [hoursB, minutesB] = b.appointmentTime.split(":").map(Number);
                            if (hoursA !== hoursB) {
                                return hoursA < hoursB ? 1 : -1;
                            }
                            return minutesA < minutesB ? 1 : -1;
                        }
                        return dateA < dateB ? 1 : -1;
                    });
                    setAppointmentHistoryData(appointment);
                    dispatch(disableLoader());
                }

            })
            .catch((e) => {
                dispatch(disableLoader());
                dispatch(showError(e.error));
            })
        dispatch(disableLoader());
    }, [])


    const validateAppointment = (appointment: any) => {
        const durationLimitHours = appointmentConfig.rescheduleLimit;

        const appointmentStart = new Date(appointment.appointmentDay);
        const [hours, minutes] = appointment.appointmentTime.split(":").map(Number);
        appointmentStart.setHours(hours, minutes, 0, 0);

        const currentTime = new Date();
        const timeDifferenceInHours = (appointmentStart.getTime() - currentTime.getTime()) / (1000 * 60 * 60);

        return timeDifferenceInHours > durationLimitHours;
    };

    const validateExperts = (appointment: any) => {
        let experts = appointment.expertAppointments.map((item: any) => item.expertId)
        let uniqueExperts = new Set(experts);
        return uniqueExperts.size > 1;
    }



    const onReschedule = (item: any) => {
        if (validateExperts(item)) {
            dispatch(showInfo(`${languageData["Reschedule of appointments with multiple stylist not allowed"] ? languageData["Reschedule of appointments with multiple stylist not allowed"] : "Reschedule of appointments with multiple stylist not allowed"}`))
            return;
        }
        if (!validateAppointment(item)) {
            dispatch(showInfo(`${languageData["Reschedule time limit exceeded"] ? languageData["Reschedule time limit exceeded"] : "Reschedule time limit exceeded"}`))
            return;
        }
        setactiveAppt(item);
        setAction("reschedule")
        setDetailsModal(true)
    }

    const onCancel = (item: any) => {
        if (!validateAppointment(item)) {
            dispatch(showInfo(`${languageData["Cancel time limit exceeded"] ? languageData["Cancel time limit exceeded"] : "Cancel time limit exceeded"}`))
            return;
        }
        setactiveAppt(item);
        setAction("cancel")
        setDetailsModal(true)
    }

    const toggleModal = () => {
        setDetailsModal(!detailsModal)
    }

    return (
        <>
            {Boolean(appointmentHistoryData.length) ?
                <div className="order-history-outer">
                    <p className="heading"> {languageData["Appointment Booking History"] ? languageData["Appointment Booking History"] : "Appointment Booking History"}</p>
                    {appointmentHistoryData?.map((item, index) => {
                        const status = item.status[item.status.length - 1].status;
                        return <div className="order-card" key={Math.random()} >
                            {item.invoiceId != "" && <span>{item.invoiceId}</span>}
                            <div>
                                <span className="order-type">{item.status[item.status.length - 1].status}</span>
                                <span className="right-align order-type color">
                                    {formatToDateFormat(item.appointmentDay, configData.dateFormat)} {formatTimeTo12Hr(item.appointmentTime)}
                                </span>
                            </div>
                            <div className='actions'>
                                {!(status == "COMPLETED" || status == "RESCHEDULE_REQUESTED" || status == "CANCELLED") && appointmentConfig?.enableCatalogReschedule && <div>
                                    <span className="action-button" onClick={() => onReschedule(item)}> {languageData["Reschedule"] ? languageData["Reschedule"] : "Reschedule"}</span>
                                </div>}
                                {!(status == "COMPLETED" || status == "CANCELLED") && appointmentConfig?.enableCatalogReschedule && <div>
                                    <span className="action-button" onClick={() => onCancel(item)}>{languageData["Cancel"] ? languageData["Cancel"] : "Cancel"}</span>
                                </div>}
                            </div>
                        </div>
                    })}
                </div> :
                <div className="order-history-outer">
                    <p className="heading"> {languageData["Appointment History"] ? languageData["Appointment History"] : "Appointment History"}</p>
                    <div className="sub-heading"> {languageData["You do not have any appointments"] ? languageData["You do not have any appointments"] : "You do not have any appointments"}</div>
                </div>}

            {activeAppt && (
                <>
                    {action === "reschedule" ? (
                        !appointmentConfig.showBookedSlot ? (
                            detailsModal && <SlotRescheduleModal item={activeAppt} onClose={toggleModal} setAppointmentHistoryData={setAppointmentHistoryData} />
                        ) : (
                            detailsModal && <BookedSlotRescheduleModal item={{ item: activeAppt, date: selectedDate }} onClose={toggleModal} setAppointmentHistoryData={setAppointmentHistoryData} />
                        )
                    ) : (
                        detailsModal && <AppointmentCancelCard appointment={activeAppt} openModal={detailsModal} handleClose={toggleModal} />
                    )}
                </>
            )}
        </>
    )
}

export default AppointmentHistoryPage;
