import { disableLoader, enableLoader } from '@context/actions';
import { getAdvanceBalanceDetailsById } from '@storeData/order';
import { redirectToHome } from '@util/routerService';
import router from "next/router";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdvanceBalanceInvoiceModal from './advanceBalanceInvoice';

function AdvanceBalanceInvoicePage() {
    const dispatch = useDispatch();
    const [orderData, setOrderData] = useState(null)
    const orderId = router.query.pagepath ? router.query.pagepath[1] : '';
    const { configData } = useSelector((state: any) => state.store.storeData);
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    useEffect(() => {
        if (orderId) {
            dispatch(enableLoader());
            getAdvanceBalanceDetailsById(orderId).then((order: any) => {
                dispatch(disableLoader());
                if (order) {
                    setOrderData(order);
                }
            }).catch((error) => {
                dispatch(disableLoader());
                console.log(error);
                setOrderData('');
            })
        }
    }, [orderId])

    return (
        <div className="invoice-wrapper">
            {Boolean(orderData) && <AdvanceBalanceInvoiceModal orderData={orderData} handleClose={() => { }} />}
            {Boolean(configData?.storeConfig?.basicConfig?.catalogue) && <div className="footer-btn-wrap">
                <button className='primary-btn' onClick={redirectToHome}>{languageData["Explore More Services & Products"] ? languageData["Explore More Services & Products"] : "Explore More Services & Products"}</button>
            </div>}
        </div>
    )
}

export default AdvanceBalanceInvoicePage