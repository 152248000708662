import { APPOINTMENT_CREATED, APPOINTMENT_CREATED_FOR_OL_PAYMENT, APPOINTMENT_TYPE } from '@constant/appointment';
import { SERVICE } from '@constant/types';
import { disableLoader, enableLoader } from '@context/actions';
import { showError, showSuccess } from '@context/actions/alert';
import { replaceAppointmentServices, syncLocalStorageAppointment } from '@context/actions/appointment';
import Item from '@element/horizontalItem';
import SvgIcon from '@element/svgIcon';
import Backdrop from '@material-ui/core/Backdrop';
import PaymentVendorModal from '@module/paymentVendorModal';
import UserRegistrationModal from '@module/userRegistration';
import { getDisposableItemsList } from '@storeData/common';
import { getStaffByTenantAndStoreId } from '@storeData/staff';
import { updateUser } from '@storeData/user';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptionsClientSecret, loadStripe } from '@stripe/stripe-js';
import OrderConfirmation from '@template/orderConfirmation';
import CheckoutForm from '@template/stripe/CheckoutForm';
import { APISERVICE } from '@util/apiService/RestClient';
import { createOrderFromAppointment } from '@util/order';
import { navigateTo } from '@util/routerService';
import { addDayToDate, createSlots, formatTimeTo12Hr, formatToDateFormat, getDateObj, getTofixValue } from '@util/utils';
import { windowRef } from '@util/window';
import Script from 'next/script';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import DatePicker from "react-datepicker";
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';

function BiChevronDown() {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline fill="none" stroke="#000" strokeWidth="2" points="9 6 15 12 9 18"></polyline></svg>
}

function Appointment() {

    const imageRef = useRef<HTMLInputElement>(null);
    const [uniqueRecordIdentifier, setUniqueRecordIdentifier] = useState<any>(null);
    const [cookie, setCookie] = useCookies();
    const [userData, setUserCookie] = useState(cookie['user']);
    const store = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const [openCcavenueModal, setopenCcavenueModal] = useState({ active: false, link: "" })
    const appointmentItems = useSelector((state: any) => state.appointmentServices);
    const storeData = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [storeSlot, setStoreSlot] = useState(createSlots(storeData.configData?.dayWiseTiming[(new Date()).getDay()].startTime, storeData.configData?.dayWiseTiming[(new Date()).getDay()].closureTime));
    const [expertData, setexpertData] = useState(null);
    const [allExpertsList, setAllExpertsList] = useState<any>([])
    const [selectedExpert, setSelectedExpert] = useState<any>();
    const [selectedMember, setSelectedMember] = useState<any>();
    const [selectedDate, setSelectedDate] = useState<any>(new Date());
    const [selectedSlot, setSelectedSlot] = useState<any>('');
    const [showAppointmentConfirmation, setShowAppointmentConfirmation] = useState(false);
    const [appointmentInstruction, setAppointmentInstruction] = useState('');
    const [orderData, setOrderData] = useState<any>(null);
    const [showUserRegistration, setShowUserRegistration] = useState(false);
    const [activeInput, setActiveInput] = useState('');
    const [appointmentObj, setAppointmentObj] = useState<any>('');
    const [showTotalBreakdownPopup, setShowTotalBreakdownPopup] = useState(false);
    const { configData, keywords } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [familyMember, setFamilyMember] = useState<any>({ name: '', mobileNo: '', countryCode: '' });
    const [showAddMemberModal, setShowAddMemberModal] = useState(false)
    const [error, setError] = useState<any>('');
    const [selectedStoreLocation, setSelectedStoreLocation] = useState('');
    const [instImages, setInstImages] = useState<string[]>([]);
    const [instrImgReset, setInstrImgReset] = useState<boolean>(false);
    const [instrImgError, setInstrImgError] = useState<boolean>(false);
    const [showInstrImageDelConfirmModal, setShowInstrImageDelConfirmModal] = useState<[boolean, number]>([false, 0]);
    const appointmentConfig = configData?.storeConfig?.appointmentConfig;
    const [openPaymentVendorModal, setOpenPaymentVendorModal] = useState<string>('');
    const [paymentGatewayList, setPaymentGatwayList] = useState<any[]>([]);
    const storeMetaData = useSelector((state: any) => state.store ? state.store.storeMetaData : null);
    const [disposableItemsList, seetDisposableItemsList] = useState<any[]>([]);
    const isDisposableAvailable = configData?.storeConfig?.basicConfig?.disposable_items;
    const [minDate, setMinDate] = useState(new Date())
    const basicConfig = configData?.storeConfig?.basicConfig
    interface StripeValues {
        options: StripeElementsOptionsClientSecret;
        promise: Promise<Stripe>;
        paymentIntentId: string;
        redirectUrl: any,
        order?: any,
        address?: any,
        user?: any
    }
    const languageData = store.languageKeywords
    const activeLanguage: any = store.activeLanguage
    const [stripeModalDetails, setStripeModalDetails] = useState<StripeValues>({
        options: {
            clientSecret: "",
            appearance: {
                theme: 'stripe',
                labels: 'floating',
            }
        },
        promise: undefined,
        paymentIntentId: "",
        redirectUrl: ""
    });

    useEffect(() => {
        APISERVICE.GET(process.env.NEXT_PUBLIC_PAYMENT_VENDOR + `/getPaymentGatewayData/${storeData.tenantId}/${storeData.storeId}`)
            .then((resp) => {
                if (!resp.data) {
                    // INSERT ERROR MESSAGE HERE
                    console.log("Unable to load payment configurations");
                    return;
                }
                // let gateways: { [key: string]: PaymentGateway } = { [resp.data[0].toLowerCase()]: { key: resp.data[1], redirectUrl: resp.data[2] } };
                setPaymentGatwayList(resp.data);
                const stripePaymode = resp.data.find((paymode) => paymode.name == "Stripe");
                if (stripePaymode) {
                    setStripeModalDetails({ ...stripeModalDetails, ...stripePaymode, promise: loadStripe(stripePaymode.key) })
                }
            }).catch((err) => {
                console.log(err);
            })
        if (isDisposableAvailable) {
            getDisposableItemsList(storeData.tenantId, storeData.storeId).then((res: any) => {
                seetDisposableItemsList(res);
            })
        }
    }, []);

    useEffect(() => {
        if (windowRef) {
            dispatch(syncLocalStorageAppointment());
            localStorage.removeItem("payment_type");
            window.scrollTo(0, 0);
        }
        getStaffByTenantAndStoreId(storeData.tenantId, storeData.storeId)
            .then((expert: any) => {
                if (expert) {
                    setAllExpertsList(expert.filter((e: any) => e.active))
                    setexpertData(expert.filter((e: any) => e.active));
                }
            })
            .catch((e) => {
                dispatch(showError(e.error));
            })
    }, [windowRef])

    useEffect(() => {
        setUserCookie(cookie['user']);
    }, [cookie])

    useEffect(() => {
        if (windowRef && (showTotalBreakdownPopup || showAppointmentConfirmation)) {
            document.body.classList.add("o-h")
        } else {
            document.body.classList.remove("o-h")
        }
        return () => {
            document.body.classList.remove("o-h")
        }

    }, [windowRef, showTotalBreakdownPopup, showAppointmentConfirmation]);

    useEffect(() => {
        if (allExpertsList?.length != 0 && appointmentItems?.length != 0) {
            let availableStaff = [];
            appointmentItems.map((service: any) => {
                if (service.experts) {
                    service.experts.map((expert: any) => {
                        let alreadyAdded = availableStaff.length != 0 ? availableStaff.filter((s) => s.id == expert.id) : [];
                        if (alreadyAdded.length == 0) {
                            let staffs = allExpertsList.filter((s) => s.id == expert.id);
                            staffs.length != 0 && availableStaff.push(staffs[0])
                        }
                    })
                }
            })
            let selectedExpertAvl = availableStaff.length != 0 ? availableStaff.filter((s) => s.id == selectedExpert?.id) : [];
            if (selectedExpertAvl.length == 0) setSelectedExpert(null)
            if (availableStaff.length != 0) setexpertData(availableStaff);
            else setexpertData(null);
        } else setexpertData(null);
    }, [allExpertsList, appointmentItems])

    useEffect(() => {
        if (appointmentItems.length != 0) {
            const appointmentCopy = { ...appointmentItems }
            let appliedTaxes: any[] = [];
            let subTotal = 0;
            let total = 0;
            appointmentItems.map((service: any, i: number) => {

                // her  variation price not considered because at the time of appointment booking it is set inside service object as price and salePrice
                let applicablePrice: any = userData?.membership?.length == 1 && userData?.membership[0]?.typeId == 4 ? (parseFloat(service?.memberPrice)) : (parseFloat(service.salePrice) || parseFloat(service.price));
                subTotal += parseFloat(applicablePrice);
                total += parseFloat(applicablePrice);

                if (service.txchrgs) {
                    service.txchrgs.map((taxData: any) => {

                        let tDetails = configData?.txchConfig ? configData?.txchConfig?.filter((t: any) => t.name == taxData.name) : [];
                        taxData.isInclusive = tDetails[0]?.isInclusive;
                        //update global total
                        if (tDetails.length != 0) {
                            let taxApplied = getTofixValue(Number((parseFloat(applicablePrice) / 100) * parseFloat(tDetails[0].value)))
                            if (!taxData.isInclusive) total = getTofixValue(Number(total + taxApplied));
                            if (taxData.isInclusive) {
                                // x = (price * 100) / (tax + 100)
                                let itemActualPrice = ((applicablePrice * 100) / (100 + tDetails[0].value));
                                let actualTax = (itemActualPrice * tDetails[0].value) / 100;
                                taxApplied = getTofixValue(Number(actualTax));
                                // tax = x * (tax / 100)
                            }
                            //update global applied taxes total
                            let isAVl = appliedTaxes.findIndex((at: any) => at.name == taxData.name);
                            if (isAVl != -1) {
                                appliedTaxes[isAVl].total = getTofixValue(Number(appliedTaxes[isAVl].total + taxApplied));
                            } else {
                                appliedTaxes.push({ name: tDetails[0].name, value: tDetails[0].value, total: taxApplied, isInclusive: tDetails[0].isInclusive })
                            }
                            // //overAll tax calculation
                            // let isAVlinOverall = appliedTaxes.findIndex((at: any) => at.name == taxData.name);
                            // if (isAVlinOverall != -1) {
                            //     appliedTaxes[isAVlinOverall].total = Number((appliedTaxes[isAVlinOverall].total + taxApplied).toFixed(2));
                            // } else {
                            //     appliedTaxes.push({ name: tDetails[0].name, value: tDetails[0].value, total: taxApplied, isInclusive: tDetails[0].isInclusive })
                            // }
                            //overAll tax calculation

                        }
                    })
                }
                if (i == appointmentItems.length - 1) {
                    appointmentCopy.total = getTofixValue(total);
                    appointmentCopy.subTotal = getTofixValue(subTotal);
                    appointmentCopy.txchrgs = appliedTaxes;
                    appointmentCopy.disposables = getDisposablesItems(disposableItemsList, appointmentItems);
                    const dispTotal = Boolean(appointmentCopy.disposables?.length) ? appointmentCopy.disposables.reduce((a: any, b: any) => a + (Number(b.price) * Number(b.quantity)), 0) : 0;
                    appointmentCopy.total = Number(getTofixValue(total)) + Number(dispTotal);
                    if (appointmentCopy.total != appointmentObj.total) {//check for total beacause of stopping rerendering on setting appointment
                        setAppointmentObj(appointmentCopy);
                    }
                    // console.log("appointmentCopy", appointmentCopy)
                }
            })
        } else {
            setAppointmentObj({ total: 0, subTotal: 0 });
        }
        setActiveInput('');
    }, [appointmentItems, disposableItemsList, userData]);

    useEffect(() => {
        let slots = [];
        if (getDateObj(new Date()).dateObj == getDateObj(selectedDate).dateObj) {// check for todays date create slot after current time
            let hrs = ((new Date().getHours().toString().length == 1) ? '0' + new Date().getHours() : new Date().getHours());
            let mins: any = ((new Date().getMinutes().toString().length == 1) ? '0' + new Date().getMinutes() : new Date().getMinutes());
            if (mins < 30) mins = '30';//for 00:27 set min to 30
            if (mins >= 30) {
                mins = '00';//for 00:37 set min to 00
                hrs = Number(hrs) + 1
            }
            const startTime = hrs + ':' + mins;
            slots = createSlots(startTime, configData?.dayWiseTiming[(new Date(getDateObj(selectedDate).dateObj)).getDay()].closureTime)
        } else {
            slots = createSlots(configData?.dayWiseTiming[(new Date(getDateObj(selectedDate).dateObj)).getDay()].startTime, configData?.dayWiseTiming[(new Date(getDateObj(selectedDate).dateObj)).getDay()].closureTime)
        }
        slots.pop()
        if (!Boolean(slots?.length)) {
            setMinDate(addDayToDate(new Date(), 1))
            setSelectedDate(addDayToDate(new Date(selectedDate), 1));
        }
        setStoreSlot(slots);
    }, [selectedDate]);

    const getDisposablesItems = (disposableItemsList: any, appointmentItems: any) => {

        let disposables = [];
        let totalServiceQuantity = appointmentItems.length;
        if (totalServiceQuantity && Boolean(disposableItemsList?.length)) {
            const serviceLevelItems = disposableItemsList.filter((i: any) => i.isServiceLevel && i.active);
            const orderLevelItems = disposableItemsList.filter((i: any) => !i.isServiceLevel && i.active);

            if (serviceLevelItems.length != 0) {
                //add service level items
                serviceLevelItems.map((dItem: any) => {
                    disposables.push({ ...dItem, quantity: totalServiceQuantity })
                })
            }
            if (orderLevelItems.length != 0) {
                //add order level items
                orderLevelItems.map((dItem: any) => {
                    disposables.push({ ...dItem, quantity: 1 })
                })
            }
        }
        return disposables;
    }

    const removeService = (index) => {
        const appointmentCopy = [...appointmentItems];
        appointmentCopy.splice(index, 1);
        dispatch(replaceAppointmentServices(appointmentCopy));
        dispatch(showSuccess(`${languageData['Service Removed'] ? languageData['Service Removed'] : 'Service Removed'}`, 2000));
    }

    const onSelectExpert = (expert) => {
        setSelectedExpert({ ...expert });
        setActiveInput('');
    }
    const onSelectMember = (member) => {
        setSelectedMember({ ...member });
        setActiveInput('');
    }

    const onSelectDate = (date) => {
        setActiveInput('');
        setSelectedDate(date);
    }
    const onSelectSlot = (slot) => {
        setActiveInput('');
        setSelectedSlot(slot);
    }

    const onLoginClose = (user) => {
        if (user) {
            onConfirmBookingClick(user);
        }
        setShowUserRegistration(false);
    }

    const getServiceDuration = (duration, durationType) => {
        if (durationType?.includes('hrs')) {
            return (parseFloat(duration) * 60).toFixed(1);
        } else return parseFloat(duration);
    }

    const createIndividualExpertService = (serviceDetails: any) => {
        const { id, name, duration, durationType, categoryName, price, quantity, salePrice, memberPrice, categoryId, txchrgs, variations, consumables, hsnSac } = serviceDetails;
        const individualAppointmentObj = {
            "appointmentDay": selectedDate,
            "appointmentTime": selectedSlot,
            "duration": getServiceDuration(duration, durationType),
            "serviceCategory": categoryName,
            "service": name,
            "slot": '',
            "expertId": selectedExpert?.id,
            "expertName": selectedExpert ? (selectedExpert.lastName ? `${selectedExpert.firstName} ${selectedExpert.lastName}` : selectedExpert.firstName) : '',
            "price": price,
            "billingPrice": userData?.membership?.length == 1 && userData.membership[0].typeId == 4 ? memberPrice : price,
            "quantity": quantity || 1,
            "salePrice": salePrice,
            "memberPrice": memberPrice,
            "serviceCategoryId": categoryId,
            "serviceId": id,
            "txchrgs": txchrgs,
            "variations": variations,
            "consumables": consumables,
            "hsnSac": hsnSac
        }
        return individualAppointmentObj;
    }

    const dataUrlToFile = (data: string, name: string) => {
        let splitString = data.split(',');
        let byteString = atob(splitString[1]);
        let mimeString = splitString[0].split(":")[1].split(';')[0];

        let ia = new Uint8Array(new ArrayBuffer(byteString.length));

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ia], name, { type: mimeString });
    }

    const uploadImage = async (imagePath: string, index: number) => {
        return new Promise<string>((res: Function) => {
            let formData: any = new FormData();
            formData.append("id", storeData.tenantId);
            formData.append("file", dataUrlToFile(imagePath, `instruction_${index}.png`));
            formData.append("type", "orders");
            APISERVICE.POST(process.env.NEXT_PUBLIC_GET_BASE_CATALOG_URL + "/s3/uploadwithtype", formData, { resoponseType: 'text' }).then((resp: any) => res(resp.data)).catch(() => res(null));

        })
    }

    const getDisposableItems = () => {
        const items: any = []
        appointmentObj.disposables.map((i: any) => {
            items.push({
                "name": i.name,
                "itemId": i.id,
                "quantity": i.quantity,
                "price": i.price,
                "remark": null
            })
        })
        return items
    }

    const submitAppointment = async (user, paymode: string = '', onlineMode: string = '') => {
        setShowAppointmentConfirmation(false);
        if (user) {
            dispatch(enableLoader());
            const appointmentsList = [];
            appointmentItems.map((item) => {
                appointmentsList.push(createIndividualExpertService(item));
            })
            appointmentsList[0].slot = selectedSlot;
            let familyMember = null;
            if (selectedMember) {
                familyMember = selectedMember.mobileNo != userData.mobileNo ? selectedMember : null;
            }
            let imageList = [];
            let imageUploadFail = false;
            for (let i = 0; i < instImages.length; i++) {

                if (instImages[i].startsWith("data:image")) {
                    let f: string = await uploadImage(instImages[i], i);
                    if (f) imageList.push(f);
                    else {
                        imageUploadFail = true;
                        break
                    }
                }
                if (imageUploadFail) {
                    console.log("Error uploading images")
                    return;
                }
            }
            const apiBody = {
                "appointmentDay": appointmentsList[0].appointmentDay,
                "appointmentTime": appointmentsList[0].appointmentTime,
                "duration": appointmentsList[0].duration,
                "instruction": appointmentInstruction,
                "instrImages": imageList,
                "slot": appointmentsList[0].slot,
                "expertId": appointmentsList[0].expertId,
                "expertName": appointmentsList[0].expertName,
                "storeId": storeData.storeId,
                "tenantId": storeData.tenantId,
                "store": storeData.store,
                "tenant": storeData.tenant,
                "guestId": user.id,
                "guestName": user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName,
                "guestMobile": user.mobileNo,
                "guestCountryCode": user.countryCode,
                "guestEmail": user.email,
                "createdOn": new Date().toISOString(),
                "expertAppointments": appointmentsList,
                "noOfRemindersSent": '',
                "rescheduled": false,
                "feedbackLinkShared": false,
                "bookedFor": familyMember,
                "storeLocation": selectedStoreLocation,
                "type": APPOINTMENT_TYPE,
                "advanceAmount": 0,
                "disposables": getDisposableItems(),
                "smsKeys": {
                    "appointmentConfirmed": true,
                    "appointmentCancelled": false,
                    "appointmentFeedback": false,
                    "smsForAppointments": true,
                    "combineFeedbackAndInvoice": false,
                },
                "status": [{
                    "status": Boolean(onlineMode == "partial" || onlineMode == "fullpayment") ? APPOINTMENT_CREATED_FOR_OL_PAYMENT : APPOINTMENT_CREATED,
                    "staff": "",
                    "staffName": "",
                    "remark": "",
                    "createdOn": new Date().toISOString()
                }],
                "uniqueRecordIdentifier": uniqueRecordIdentifier
            }
            if (onlineMode == "partial") {
                apiBody.advanceAmount = getAdvanceAmount(appointmentObj.total);
            } else if (onlineMode == "fullpayment") {
                apiBody.advanceAmount = appointmentObj.total;
            }
            localStorage.setItem("payment_type", `appointment-${paymode.toLowerCase()}`);
            switch (paymode.toLowerCase()) {
                case "pay_later":
                    // create regular pay later order
                    APISERVICE.POST(process.env.NEXT_PUBLIC_PLACE_APPOINTMENT, apiBody).then((res) => {
                        setTimeout(() => {
                            // sendWhatsappMsg(user)
                            const orderDetails = createOrderFromAppointment(res.data);
                            setOrderData({ ...orderDetails, selectedExpert });
                            dispatch(disableLoader());
                            dispatch(replaceAppointmentServices([]));
                            dispatch(showSuccess(`${languageData['Appointment request submitted'] ? languageData['Appointment request submitted'] : 'Appointment request submitted'}`))
                        }, 1000)
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Appointment booking failed'] ? languageData['Appointment booking failed'] : 'Appointment booking failed'}`))
                    })
                    break;
                case "razorpay":
                    // create razor pay order
                    APISERVICE.POST(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/rzr`, apiBody).then((res) => {
                        setTimeout(() => {
                            const { Razorpay }: any = window;
                            const rzp1 = new Razorpay(res.data);
                            rzp1.open();
                            dispatch(disableLoader());
                        }, 1000)
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Order creation failed'] ? languageData['Order creation failed'] : 'Order creation failed'}`))
                    })
                    break;
                case "ccavenue":
                    // create razor pay order
                    APISERVICE.POST(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/ccavenue?env=test`, apiBody).then((res) => {
                        setTimeout(() => {
                            window.open(res.data.redirctTo, "_self")
                            // setopenCcavenueModal({ active: true, link: res.data.redirctTo })
                            dispatch(disableLoader());
                        }, 1000)
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Order creation failed'] ? languageData['Order creation failed'] : 'Order creation failed'}`))
                    })
                    break;
                case "stripe":
                    // create stripe order
                    APISERVICE.POST(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/stripe${'?currencyCode=usd'}`, apiBody).then((res: any) => {

                        appointmentObj.id = res.data.orderId;
                        // setOrderData({ ...appointmentObj });
                        const paymentAddress = {
                            area: storeMetaData.area,
                            city: storeMetaData.city,
                            country: storeMetaData.code,//used as country code // IN
                            code: storeMetaData.pincode,
                            landmark: storeMetaData.landmark,
                            latitude: storeMetaData.latitude,
                            line: storeMetaData.address,
                            longitude: storeMetaData.longitude,
                        }
                        setStripeModalDetails({
                            ...stripeModalDetails,
                            options: {
                                ...stripeModalDetails.options,
                                clientSecret: res.data.clientSecret
                            },
                            paymentIntentId: res.data.paymentIntentId,
                            order: appointmentObj,
                            address: paymentAddress,
                            user: userData
                        });
                        dispatch(disableLoader());
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Order creation failed'] ? languageData['Order creation failed'] : 'Order creation failed'}`))
                    })
                    break;
            }
        }
    }

    const onConfirmBookingClick = (user) => {
        if (!appointmentItems || appointmentItems.length == 0) {
            dispatch(showError(`${languageData['Select any service'] ? languageData['Select any service'] : 'Select any service'}`));
        } else if (!selectedDate) {
            dispatch(showError(`${languageData['Select preferred date'] ? languageData['Select preferred date'] : 'Select preferred date'}`));
        } else if (!selectedExpert && configData?.storeConfig?.appointmentConfig?.showExpertAsSalon) {
            dispatch(showError(`${languageData['Select salon location'] ? languageData['Select salon location'] : 'Select salon location'}`));
        } else if (!selectedStoreLocation && configData?.storeConfig?.storeLocations && configData?.storeConfig?.storeLocations?.length != 0) {
            dispatch(showError(`${languageData["Select store location"] ? languageData["Select store location"] : "Select store location"}`));
        } else if (!selectedSlot) {
            dispatch(showError(`${languageData["Select preferred slot"] ? languageData["Select preferred slot"] : "Select preferred slot"}`));
        } else {
            if (user) {
                setUniqueRecordIdentifier(new Date().getTime());
                setSelectedMember({ name: user?.firstName, mobileNo: user?.mobileNo, countryCode: user?.countryCode });
                setShowAppointmentConfirmation(true);
            } else setShowUserRegistration(true);
        }
    }
    const onClickExpertInput = () => {
        setActiveInput(activeInput == 'expert' ? '' : 'expert');
    }

    const onClickStoreLocationInput = () => {
        setActiveInput(activeInput == 'store-location' ? '' : 'store-location');
    }

    const onChangeMemberValue = (from, value) => {
        setError('')
        const familyMemberCopy = { ...familyMember };
        if (from == 'mobileNo') {
            if (value && value.length > 10) {
                setFamilyMember(familyMemberCopy)
            } else {
                familyMemberCopy[from] = value;
                setFamilyMember(familyMemberCopy)
            }
        } else {
            familyMemberCopy[from] = value;
            setFamilyMember(familyMemberCopy)
        }
    }

    const onPhoneChange = (mobileNo, countryCode = storeMetaData.countryCode) => {
        setError({ id: '', text: '' });
        const num = mobileNo.charAt(mobileNo?.length - 1).replace(".", '');
        if (((num && num != ' ') && !isNaN(num))) {
            setFamilyMember({ ...familyMember, mobileNo: mobileNo, countryCode });

        } else if (!Boolean(mobileNo?.length)) setFamilyMember({ ...familyMember, mobileNo: mobileNo, countryCode });
    }

    const closeMemberForm = () => {
        setFamilyMember({ name: '', mobileNo: '' });
        setShowAddMemberModal(false)
    }

    const validateExistingMembers = (userDataCopy) => {
        if (userDataCopy.firstName == familyMember.name) {
            setError({ id: "existing-member", text: `${languageData["Its your registred name, try different one"] ? languageData["Its your registred name, try different one"] : "Its your registred name, try different one"}` });
            return userDataCopy;
        }

        if (userDataCopy.mobileNo == familyMember.mobileNo) {
            setError({ id: "existing-member", text: `${languageData["Its your registred number, try different one"] ? languageData["Its your registred number, try different one"] : "Its your registred number, try different one"}` });
            return userDataCopy;
        }

        let existingName = userDataCopy?.familyMembers?.find((u: any) => u.name == familyMember.name);
        if (Boolean(existingName)) {
            setError({ id: "existing-member", text: `${languageData["Name already registred with number"] ? languageData["Name already registred with number"] : "Name already registred with number"} ${existingName.mobileNo}` });
            return existingName;
        }

        let existingNumber = userDataCopy?.familyMembers?.find((u: any) => u.mobileNo == familyMember.mobileNo);
        if (Boolean(existingNumber)) {
            setError({ id: "existing-member", text: `${languageData["Number already registred with name"] ? languageData["Number already registred with name"] : "Number already registred with name"} ${existingNumber.name}` });
            return existingNumber;
        }

        return null;
    }

    const submitMemberForm = () => {
        const userDataCopy = { ...userData }
        setError("")
        if (!familyMember.name) {
            setError('member-name')
        } else if (!familyMember.mobileNo) {
            setError('member-mobileNo')
        } else if (!validateExistingMembers(userDataCopy)) {
            if (userDataCopy.familyMembers) userDataCopy.familyMembers.push(familyMember)
            else userDataCopy.familyMembers = [familyMember];
            dispatch(enableLoader());
            updateUser(userDataCopy)
                .then((res: any) => {
                    dispatch(disableLoader());
                    dispatch(showSuccess(`${languageData['Member Added'] ? languageData['Member Added'] : 'Member Added'}`, 2000));
                    setCookie("user", res.data, { //user registration fields
                        path: "/",
                        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                        sameSite: true,
                    })
                    setSelectedMember({ ...familyMember })
                }).catch(() => {
                    dispatch(disableLoader());
                    dispatch(showError(`${languageData['Something went wrong. please try again'] ? languageData['Something went wrong. please try again'] : 'Something went wrong. please try again'}`, 2000));
                })
            setShowAddMemberModal(false);
            setFamilyMember({ name: '', mobileNo: '' });
            setActiveInput('');
        }
    }

    const filterCategory = async (category: any, searchQuery: any) => {
        return new Promise((res) => {
            if (category.showOnUi) {
                category.display = !category.hideFromCatalogue;
                if (category.name.toLowerCase().includes(searchQuery)) {
                    category.display = true;
                }
                if (category?.categoryList && category?.categoryList?.length != 0) {
                    category.categoryList?.map(async (subcat: any) => {
                        if (subcat.showOnUi) {
                            subcat = await filterCategory(subcat, category.display ? '' : searchQuery)
                        } else subcat.display = false;
                    })
                    let isAnyDisplaySubcat = category?.categoryList?.filter((cat: any) => cat.display);
                    category.display = isAnyDisplaySubcat?.length != 0 ? true : false;
                } else if (category?.itemList?.length != 0) {//3rd level itemslist
                    let filteredItems = category?.itemList;
                    if (searchQuery) filteredItems = category?.itemList.filter((item: any) => item.showOnUi && !item.hideFromCatalogue && item.name.toLowerCase().includes(category.display ? '' : searchQuery));
                    filteredItems.map((item: any) => {
                        item.serviceId = item.id;
                        item.duration = item.durationType ? ((item.durationType?.includes('hrs')) ? (parseFloat(item.duration) * 60).toFixed(1) : parseFloat(item.duration)) : 30;
                    })
                    category.itemList = filteredItems;
                    category.display = filteredItems.length != 0 ? true : false;
                } else category.display = false;
            } else category.display = false;
            res(category);
        })
    }

    const renderCategoryItemsView = (category: any) => {
        if (category && category.display && !category.hideFromCatalogue) {
            return <div className={`items-cat-wrap clearfix ${(category?.categoryList && category?.categoryList.length != 0) ? 'has-sub-category' : ''}`}>
                <div className={`cat-name ${(category?.categoryList && category?.categoryList.length != 0) ? 'has-sub-category' : ''}`}>
                    {category.name}
                </div>
                {((category?.categoryList && category?.categoryList.length != 0) && category?.categoryList?.length != 0) ? <>
                    {category?.categoryList?.map((category: any) => {
                        return <React.Fragment key={category.id}>
                            {renderCategoryItemsView(category)}
                        </React.Fragment>
                    })}
                </> : <>
                    {(category?.itemList && category?.itemList?.length != 0) ? <>
                        {renderItems(category.itemList)}
                    </> : <></>}
                </>}
            </div>

        } else return null;
    }

    const renderItems = (itemsList: any) => {
        if (itemsList && itemsList?.length != 0) {
            return <React.Fragment>
                {itemsList?.map((item: any) => {
                    return <React.Fragment key={Math.random()}>
                        {item.showOnUi && !item.hideFromCatalogue && <Item item={item} config={{ redirection: false, onClickAction: true }} />}
                    </React.Fragment>
                })}
            </React.Fragment>
        } else return null;
    }

    const redirectToCategories = () => {
        console.log(languageData)
        let activeCats = storeData.categories.filter((c: any) => c.active && c.type == keywords[SERVICE]);
        let itemUrl = activeCats[0].name.toLowerCase().split(" ").join("-");
        navigateTo(`${itemUrl}-srp`);
    }

    const openInstrFileInput = () => { imageRef.current.click(); }

    const resetInstrFileInput = () => { setInstrImgReset(!instrImgReset); }

    const addInstrImage = (el: HTMLInputElement) => {
        if (!el.files.length) return;

        let file = el.files[0];
        if (file.size > 2097152) {
            setInstrImgError(true);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof reader.result == 'string') {
                let flag = true;
                for (let i = 0; i < instImages.length; i++) {
                    if (reader.result === instImages[i]) {
                        flag = false;
                        break;
                    }
                }

                if (flag) setInstImages([...instImages, reader.result]);
            }
        }
        reader.readAsDataURL(file);
        setInstrImgError(false);

        resetInstrFileInput();
    }

    const deleteImage = (index: number) => {
        let imageList = [...instImages];
        imageList.splice(index, 1);
        setInstImages(imageList);
    }

    const getAdvanceAmount = (total: any) => {
        let amount = 0;
        if (Boolean(appointmentConfig?.advancePayments)) {
            amount = appointmentConfig?.advanceType == "PERCENTAGE" ? getTofixValue(Number(Number(appointmentConfig.advanceValue) / 100 * total)) : appointmentConfig?.advanceValue
        }
        return Number(amount);
    }

    const openInstrImageDeleteConfirmModal = (index: number) => { setShowInstrImageDelConfirmModal([true, index]); }
    const closeInstrImageDeleteConfirmModal = () => { setShowInstrImageDelConfirmModal([false, 0]); }

    const onAppointmentConfirmation = () => {
        if (userData) submitAppointment(userData, "pay_later");
        else setShowUserRegistration(true);
    }

    const handlePaymentVendorSelection = (paymode) => {
        if (paymode) {
            submitAppointment(userData, paymode.name, openPaymentVendorModal);
        }
        setOpenPaymentVendorModal('');
    }

    const proceedToPayments = (mode: string = '') => {
        setOpenPaymentVendorModal('')
        if ((paymentGatewayList?.length) > 1) {
            //open paymode vendor selection modal
            setOpenPaymentVendorModal(mode)
        } else {
            //redirect to payments screen
            submitAppointment(userData, paymentGatewayList[0].name, mode)
        }
    }

    const closeStripeWindow = (e: any) => {
        setStripeModalDetails({ ...stripeModalDetails, options: { ...stripeModalDetails.options, clientSecret: "" } });
        APISERVICE.PUT(process.env.NEXT_PUBLIC_STRIPE_PAYMENT + "/cancel?paymentIntentId=" + stripeModalDetails.paymentIntentId, null)
    }

    return (
        <>
            {Boolean(appointmentConfig?.advancePayments) && <Script src="https://checkout.razorpay.com/v1/checkout.js" strategy="lazyOnload" />}
            {!Boolean(orderData) ?
                <div className="main-wrapper appointment-wrap ">
                    {userData && <div className="card">
                        <div className="sub-heading">{languageData["User Details"] ? languageData["User Details"] : "User Details"}</div>
                        <div className="username">{userData.firstName} {userData.lastName}
                        </div>
                        <div className="number">{basicConfig.multipleCountryCode ? '+' + userData.countryCode : ""} {userData.mobileNo}</div>
                        <div className="usernumber">{userData.email}</div>

                    </div>}
                    <div className="selected-service-list-wrap card clearfix">
                        <div className="sub-heading">{languageData["Selected Services"] ? languageData["Selected Services"] : "Selected Services"}</div>
                        {appointmentItems?.length != 0 && appointmentItems?.map((item, index) => {
                            return <div key={Math.random()} className="service-wrap">
                                <div className='service-details'>
                                    <div className='service-name'>{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name} </div>
                                    {item.variations && item.variations.length != 0 && <div className='variations-wrap'>
                                        ({item.variations[0].name}
                                        {item.variations[0]?.variations?.length != 0 && <>-{item.variations[0]?.variations[0]?.name}</>}
                                        {item.variations[0]?.variations?.length != 0 && item.variations[0]?.variations[0]?.variations?.length != 0 && <>-{item.variations[0]?.variations[0]?.variations[0]?.name}</>})
                                    </div>}
                                </div>
                                <div className="service-cancel-icon" onClick={() => removeService(index)} >
                                    <SvgIcon icon="close" />
                                </div>
                            </div>
                        })}
                        <div className="btn-wrap fullwidth">
                            <div className='btn' onClick={redirectToCategories}>{languageData["Add more services"] ? languageData["Add more services"] : "Add more services"}</div>
                        </div>
                    </div>

                    {expertData && <div className="input-wrap expert-list card">
                        {configData?.storeConfig.appointmentConfig?.showExpertAsSalon ? <div className="sub-heading">{languageData["Salon"] ? languageData["Salon"] : "Salon"}</div> : <div className="sub-heading">{languageData["Expert"] ? languageData["Expert"] : "Expert"}</div>}
                        {selectedExpert ? <div className="selected-service-list-wrap">
                            <div className="service-wrap">
                                <div className='service-details' onClick={onClickExpertInput}>
                                    <div className='service-name'>{selectedExpert?.firstName} {selectedExpert?.lastName} </div>
                                </div>
                                <div className="service-cancel-icon" onClick={() => { setSelectedExpert(null); onClickExpertInput() }}>
                                    <SvgIcon icon="close" />
                                </div>
                            </div>
                        </div> :
                            <>
                                <input className="inputbox" value={''}
                                    placeholder={`${selectedExpert ? `${selectedExpert?.firstName} ${selectedExpert?.lastName}` : configData.storeConfig?.appointmentConfig?.showExpertAsSalon ? `${languageData["Select salon location"] ? languageData["Select salon location"] : "Select salon location"}` : `${languageData["Select Expert"] ? languageData["Select Expert"] : "Select Expert"}`}`}
                                    readOnly onClick={onClickExpertInput} />
                                <span className={activeInput == 'expert' ? "input-icon active" : "input-icon"} onClick={onClickExpertInput}><BiChevronDown /></span>
                            </>
                        }

                        <div className="searched-item-list-wrap"
                            style={{ height: activeInput == 'expert' ? `${expertData ? (expertData.length + 1) * 30 : 0}px` : '0' }} onClick={() => { }} >

                            <div className="items-list">
                                {expertData?.map((expert) => {
                                    return <React.Fragment key={Math.random()}>
                                        {!!expert.active && <div className="expert-details" onClick={() => onSelectExpert(expert)}>
                                            <div className="expert-name">{expert.firstName} {expert.lastName}</div>
                                        </div>}
                                    </React.Fragment>
                                })}
                            </div>
                        </div>
                    </div>}


                    {(configData?.storeConfig?.storeLocations && configData?.storeConfig?.storeLocations?.length != 0) && <div className="input-wrap expert-list card">
                        <div className="sub-heading">{languageData["Location"] ? languageData["Location"] : "Location"}</div>
                        {selectedStoreLocation ? <div className="selected-service-list-wrap">
                            <div className="service-wrap">
                                <div className='service-details' onClick={onClickStoreLocationInput}>
                                    <div className='service-name'>{selectedStoreLocation} </div>
                                </div>
                                <div className="service-cancel-icon" onClick={() => { setSelectedStoreLocation(''); onClickStoreLocationInput() }}>
                                    <SvgIcon icon="close" />
                                </div>
                            </div>
                        </div> :
                            <>
                                <input className="inputbox" value={''}
                                    readOnly
                                    placeholder={`${selectedStoreLocation ? `${selectedStoreLocation}` : `${languageData["Select store location"] ? languageData["Select store location"] : "Select store location"}`}`}
                                    onClick={onClickStoreLocationInput} />
                                <div className={activeInput == 'store-location' ? "input-icon active" : "input-icon"} onClick={onClickStoreLocationInput} ><BiChevronDown /></div>
                            </>
                        }

                        <div className="searched-item-list-wrap"
                            style={{ height: activeInput == 'store-location' ? `${configData?.storeConfig?.storeLocations ? (configData?.storeConfig?.storeLocations.length + 1) * 30 : 0}px` : '0' }}>

                            <div className="items-list">
                                {configData?.storeConfig?.storeLocations?.map((store) => {
                                    return <React.Fragment key={Math.random()}>
                                        <div className="expert-details" onClick={() => { setSelectedStoreLocation(store.name); setActiveInput('') }}>
                                            <div className="expert-name">{languageData[store.name] ? languageData[store.name] : store.name}</div>
                                        </div>
                                    </React.Fragment>
                                })}
                            </div>
                        </div>
                    </div>}



                    <div className="date-wrap card">
                        <div className="sub-heading">{languageData["Preferred Date"] ? languageData["Preferred Date"] : "Preferred Date"}</div>
                        <div className="input-wrap-with-label date-picker-wrap">
                            <DatePicker
                                selected={selectedDate ? new Date(selectedDate) : null}
                                onChange={(date) => onSelectDate(date)}
                                dateFormat="MMMM d"
                                inline
                                minDate={minDate}
                            />
                        </div>
                    </div>

                    <div className="slot-time-outer card">
                        <div className="sub-heading">{languageData["Preferred Time"] ? languageData["Preferred Time"] : "Preferred Time"}</div>
                        {configData && Boolean(storeSlot?.length) && storeSlot?.map((storeSlot) => {
                            return <div key={Math.random()} onClick={() => onSelectSlot(storeSlot)} className={selectedSlot == storeSlot ? 'slot-wrap active' : "slot-wrap"}>{formatTimeTo12Hr(storeSlot)}</div>

                        })}
                    </div>

                    <div className="input-wrap card">
                        <div className="sub-heading">{languageData["Instruction/Address"] ? languageData["Instruction/Address"] : "Instruction/Address"}</div>
                        <textarea className={"inputbox instr" + (!appointmentInstruction ? " instrShare" : "")} placeholder={languageData["Note"] ? languageData["Note"] : "Note"} value={appointmentInstruction || ''} onChange={(e) => setAppointmentInstruction(e.target.value)} />
                        {Boolean(instImages?.length) ? <div className="instrImgScrollContainer">
                            <div className="instrImgScroll">
                                {instImages?.map((link: string, index: number) => <div key={link} className="instrImgContainer card">
                                    <div className="instrImageDelIcon" onClick={() => openInstrImageDeleteConfirmModal(index)} >
                                        <SvgIcon icon="close" />
                                    </div>
                                    <img className="instrImg" src={link} onClick={() => openInstrImageDeleteConfirmModal(index)} /></div>)}
                            </div>
                        </div> : null}
                        <div className="instrFileInputButton">
                            <button className="primary-btn border-btn" onClick={openInstrFileInput}>{languageData["Add Image"] ? languageData["Add Image"] : "Add Image"}</button>
                            <div className={instrImgError ? " add-img-note error" : "add-img-note"}>({languageData["(Max 2MB per image"] ? languageData["(Max 2MB per image"] : "(Max 2MB per image"})</div>
                            <input key={instrImgReset ? "y" : "n"} type="file" ref={imageRef} onChange={(e) => addInstrImage(e.target)} className="hide" accept="image/jfif,image/png,image/jpeg,image/webp" />
                        </div>
                    </div>

                    {<div className="book-wrap">
                        {Boolean(appointmentObj?.total) && <>
                            <div className='total-wrap d-f-c' style={{ width: "300px" }}>
                                <div className='title'>Total : </div>
                                <div className='value' onClick={() => setShowTotalBreakdownPopup(true)}>{configData?.currencySymbol} {appointmentObj?.total}</div>
                                <div className='info d-f-c info-color' onClick={() => setShowTotalBreakdownPopup(true)}><SvgIcon icon="info" /></div>
                            </div>
                        </>}
                        <button style={{ width: "100%" }} className="primary-btn rounded-btn" onClick={() => onConfirmBookingClick(userData)}>{languageData["Confirm Booking"] ? languageData["Confirm Booking"] : "Confirm Booking"}</button>
                    </div>}

                    <Backdrop
                        className="backdrop-modal-wrapper instrImageDelConfirmModal"
                        open={showInstrImageDelConfirmModal[0]}
                        title={languageData["Are you sure you want to delete this?"] ? languageData["Are you sure you want to delete this?"] : "Are you sure you want to delete this?"}
                    >
                        <div className='backdrop-modal-content' style={{ height: showInstrImageDelConfirmModal[0] ? 445 : 0 }}>
                            <div className="heading"> {languageData["Are you sure you want to delete this?"] ? languageData["Are you sure you want to delete this?"] : "Are you sure you want to delete this?"}</div>
                            <div className="imageHeightLimiter">
                                <img src={showInstrImageDelConfirmModal[0] ? instImages[showInstrImageDelConfirmModal[1]] : ""} />
                            </div>
                            <div className="buttonContainer">
                                <button onClick={closeInstrImageDeleteConfirmModal} className='primary-btn border-btn'>{languageData["No"] ? languageData["No"] : "No"}</button>
                                <button onClick={() => { deleteImage(showInstrImageDelConfirmModal[1]); closeInstrImageDeleteConfirmModal(); }} className='primary-btn'>{languageData["Yes"] ? languageData["Yes"] : "Yes"}</button>
                            </div>
                        </div>
                    </Backdrop>

                    <Backdrop
                        className="backdrop-modal-wrapper appointment-checkout-wrapper"
                        open={showAppointmentConfirmation ? true : false}
                        title={'Appointment confirmation'}

                    >

                        <div className="backdrop-modal-content" style={{ height: `${showAppointmentConfirmation ? '580px' : '0'}` }}>
                            <div className="modal-close" onClick={() => setShowAppointmentConfirmation(false)}>
                                <SvgIcon icon="close" />
                            </div>
                            <div className='appointment-checkout-content'>
                                <div className="heading">{languageData["Appointment Details"] ? languageData["Appointment Details"] : "Appointment Details"}</div>
                                <div className="date-time card">
                                    <div className='sub-heading'>{languageData["Date & Time"] ? languageData["Date & Time"] : "Date & Time"}</div>
                                    <div className="date">{formatToDateFormat(selectedDate, configData.dateFormat)}, {selectedSlot}</div>
                                </div>
                                <div className="services-list-wrap card">
                                    <div className="sub-heading">{languageData["Services"] ? languageData["Services"] : "Services"}</div>
                                    {appointmentItems?.map((item) => {
                                        return <div className="service-name" key={Math.random()}>{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name}</div>
                                    })}
                                </div>
                                {selectedExpert && <div className="services-list-wrap card">
                                    {configData.storeConfig?.appointmentConfig?.showExpertAsSalon ? <div className="sub-heading">{languageData["Salon"] ? languageData["Salon"] : "Salon"}</div> : <div className="sub-heading">{languageData["Expert"] ? languageData["Expert"] : "Expert"}</div>}
                                    <div className="service-name">{selectedExpert?.firstName} {selectedExpert?.lastName}</div>
                                </div>}

                                <div className="appt-for-wrap card">
                                    <div className="sub-heading">{languageData["Appointment For"] ? languageData["Appointment For"] : "Appointment For"}</div>
                                    <div className='selected-profile'>
                                        <div className='name'>{selectedMember?.name},{basicConfig.multipleCountryCode ? '+' + selectedMember?.countryCode : ""} {selectedMember?.mobileNo}</div>
                                    </div>
                                    <div className='profile-list'>
                                        <div className={`profile ${selectedMember?.name == userData?.firstName ? 'active' : ''}`} onClick={() => onSelectMember({ name: userData?.firstName, mobileNo: userData?.mobileNo, countryCode: userData?.countryCode })}  >{languageData["Self"] ? languageData["Self"] : "Self"}</div>
                                        {userData?.familyMembers?.map((member) => {
                                            return <div key={Math.random()} onClick={() => onSelectMember(member)} className={`profile ${selectedMember?.name == member.name ? 'active' : ''}`}>{member.name}</div>
                                        })}
                                    </div>
                                    <div className='add-profile'>
                                        <div onClick={() => { setShowAddMemberModal(true); setActiveInput('') }} >{languageData["Add Member"] ? languageData["Add Member"] : "Add Member"} +</div>
                                    </div>
                                </div>

                                {Boolean(appointmentObj?.disposables?.length) && <div className="services-list-wrap card disposables-list">
                                    <div className="sub-heading cap-text">{languageData["Extra Charges"] ? languageData["Extra Charges"] : "Extra Charges"}</div>
                                    {appointmentObj?.disposables?.map((item) => {
                                        return <div className="service-name" key={Math.random()}>
                                            <div>{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name} <>{item.quantity > 1 && item.quantity}</></div>
                                            <div>{configData?.currencySymbol}{(item.price) * (item.quantity)}</div>
                                        </div>
                                    })}
                                </div>}

                                <div className="appt-for-wrap card">
                                    <div className="sub-heading" >{languageData["Pricing Details"] ? languageData["Pricing Details"] : "Pricing Details"}</div>
                                    <div className='pricing-details-wrap d-f-c'>
                                        <div className='heading'>
                                            <div className='title'>{languageData["SubTotal"] ? languageData["SubTotal"] : "SubTotal"}</div>
                                            {isDisposableAvailable && <div className='title'>{languageData["Extra Charges"] ? languageData["Extra Charges"] : "Extra Charges"}</div>}
                                            {appointmentObj.txchrgs?.map((taxData: any) => {
                                                return <div className='title' key={Math.random()}>{taxData.name}{taxData.isInclusive ? "(Inclusive)" : ''}({taxData.value}%)</div>
                                            })}
                                            <div className='title grand-total'>{languageData["Grand Total"] ? languageData["Grand Total"] : "Grand Total"}</div>
                                        </div>
                                        <div className='details'>
                                            <div className='value'>{configData.currencySymbol} {appointmentObj.subTotal}</div>
                                            {isDisposableAvailable && <div className='value'>{configData.currencySymbol} {appointmentObj?.disposables?.reduce((a: any, b: any) => a + (Number(b.price) * Number(b.quantity)), 0)}</div>}
                                            {appointmentObj.txchrgs?.map((taxData: any) => {
                                                return <div className='value' key={Math.random()}>{configData.currencySymbol} {taxData.total}</div>
                                            })}
                                            <div className='value grand-total'>{configData.currencySymbol} {appointmentObj.total}</div>
                                        </div>
                                    </div>
                                </div>

                                {Boolean(appointmentConfig?.advancePayments) && Boolean(Number(appointmentConfig.advanceValue)) && <div className="appt-for-wrap card">
                                    <div className='sub-heading adv-amt'>{languageData["Advance Amount"] ? languageData["Advance Amount"] : "Advance Amount"}: {configData.currencySymbol}{getAdvanceAmount(appointmentObj.total)}</div>
                                </div>}

                                {Boolean(appointmentConfig.paymentNote) && <div className="appt-for-wrap card">
                                    <div className='sub-heading'>*{languageData["Note"] ? languageData["Note"] : "Note"}: {appointmentConfig.paymentNote}</div>
                                </div>}

                                <Backdrop
                                    className="backdrop-modal-wrapper"
                                    open={showAddMemberModal ? true : false}
                                >
                                    <div className="backdrop-modal-content"
                                        style={{ height: showAddMemberModal ? '250px' : '0px' }}
                                    >
                                        <div className="modal-close" onClick={closeMemberForm}>
                                            <SvgIcon icon="close" />
                                        </div>
                                        <div className="member-modal">
                                            <div className='heading'>{languageData["Add Member"] ? languageData["Add Member"] : "Add Member"}</div>
                                            <div className="member-form">
                                                <div className='input-wrap'>
                                                    <span className="label">{languageData["Name"] ? languageData["Name"] : "Name"}*</span>
                                                    <input className={error == 'member-name' ? 'input error' : 'input'}
                                                        autoComplete="off"
                                                        placeholder={languageData["Profile name"] ? languageData["Profile name"] : "Profile name"}
                                                        value={familyMember.name || ''}
                                                        onChange={(e) => onChangeMemberValue('name', e.target.value)} />
                                                    {error == 'member-name' && <div className="error">{languageData["Please enter name"] ? languageData["Please enter name"] : "Please enter name"}</div>}
                                                </div>
                                                {/* <div className='input-wrap'>
                                                    <span className="label">mobileNo*</span>
                                                    <input className={error == 'member-mobileNo' ? 'input error' : 'input'}
                                                        autoComplete="off"
                                                        placeholder='Profile number'
                                                        minLength={10}
                                                        maxLength={10}
                                                        type="number"
                                                        value={familyMember.mobileNo || ''}
                                                        onChange={(e) => onChangeMemberValue('mobileNo', e.target.value)} />
                                                    {error == 'member-mobileNo' && <div className="error">Please enter phone number</div>}
                                                    {error?.id == 'existing-member' && <div className="error">{error.text}</div>}
                                                </div> */}
                                                {basicConfig.multipleCountryCode ? <>
                                                    <PhoneInput
                                                        inputClass={`phonInput input ${error.id == 'phone' ? 'invalidInput' : ''}`}
                                                        value={`${familyMember.countryCode || storeMetaData?.countryCode}${familyMember.mobileNo}` || ''}
                                                        onChange={(phone: string, country: any) => onPhoneChange(phone.replace(country.dialCode, ''), country.dialCode)}
                                                        countryCodeEditable={false}
                                                        placeholder={languageData["Mobile Number"] ? languageData["Mobile Number"] : "Mobile Number*"}
                                                        country={storeMetaData.code || "in"} // Set the default country here
                                                        enableSearch={true}
                                                        // containerStyle={{ paddingLeft: '36px', width: '80%' }}
                                                        inputProps={{ style: { width: '100%', height: '34px' } }}
                                                    />
                                                </> : <>
                                                    {/* {storeMetaData?.countryCode && <div className="country-code">+{storeMetaData?.countryCode}</div>} */}
                                                    <div className='input-wrap'>
                                                        <span className="label">{languageData["Mobile Number"] ? languageData["Mobile Number"] : "Mobile Number*"}</span>
                                                        <input className="input"
                                                            autoComplete="off"
                                                            type='tel'
                                                            id="sign-in-phone"
                                                            value={familyMember.mobileNo || ''}
                                                            onChange={(e) => onPhoneChange(e.target.value)}
                                                            maxLength={10}
                                                            placeholder={languageData["Enter phone number"] ? languageData["Enter phone number"] : "Enter phone number"}
                                                        />
                                                    </div>
                                                </>}
                                            </div>
                                            <div className="form-btn-wrap">
                                                <button className="primary-btn rounded-btn border-btn" onClick={closeMemberForm}>{languageData["Cancel"] ? languageData["Cancel"] : "Cancel"}</button>
                                                <button className="primary-btn rounded-btn" onClick={submitMemberForm}>{languageData["Add"] ? languageData["Add"] : "Add"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Backdrop>
                                <div className='confirm-btn-wrap'>
                                    {Boolean(appointmentConfig?.advancePayments) ?
                                        <>
                                            {Boolean(Number(appointmentConfig.advanceValue)) && <button className="primary-btn rounded-btn" onClick={() => proceedToPayments('partial')}>{languageData["Pay Advance"] ? languageData["Pay Advance"] : "Pay Advance"}</button>}
                                            {Boolean(appointmentConfig.fullPayment) && <button className="primary-btn rounded-btn" onClick={() => proceedToPayments('fullpayment')}>{languageData["Pay Full"] ? languageData["Pay Full"] : "Pay Full"}</button>}
                                            {Boolean(appointmentConfig.payLater) && <button className="primary-btn rounded-btn" onClick={onAppointmentConfirmation}>{languageData["Pay Later"] ? languageData["Pay Later"] : "Pay Later"}</button>}
                                            {Boolean(!("payLater" in appointmentConfig)) && <button className="primary-btn rounded-btn" onClick={onAppointmentConfirmation}>{languageData["Confirm"] ? languageData["Confirm"] : "Confirm"}</button>}
                                        </> : <button className="primary-btn rounded-btn" onClick={onAppointmentConfirmation}>{languageData["Confirm"] ? languageData["Confirm"] : "Confirm"}</button>}
                                </div>
                            </div>
                        </div>
                    </Backdrop>

                    {showUserRegistration && <UserRegistrationModal
                        handleResponse={(e) => onLoginClose(e)}
                        isApppGrpChangeOnUserGdrChange={true}
                        open={true}
                        fromPage={'APPOINTMENT'}
                        heading={'Login for appointment booking'}
                    />}

                    <PaymentVendorModal
                        open={Boolean(openPaymentVendorModal)}
                        handleClose={(e: any) => handlePaymentVendorSelection(e)}
                        paymentGatewayList={paymentGatewayList}
                    />

                    <Backdrop
                        className="backdrop-modal-wrapper"
                        open={openCcavenueModal.active ? true : false}
                        onClick={() => setopenCcavenueModal({ active: false, link: "" })}
                    >
                        <div className="backdrop-modal-content"
                            style={{ height: `80vh` }}
                        >
                            <div className="heading" >{languageData["Pay Amount"] ? languageData["Pay Amount"] : "Pay Amount"}</div>
                            <div className="modal-close" onClick={() => setopenCcavenueModal({ active: false, link: "" })}>
                                <SvgIcon icon="close" />
                            </div>
                            <div className='pricing-details-wrap d-f-c'>
                                <iframe src={openCcavenueModal.link} width="400" height="300"></iframe>
                            </div>
                        </div>
                    </Backdrop>

                    {stripeModalDetails?.options?.clientSecret ? (
                        <Backdrop
                            className="backdrop-modal-wrapper"
                            open={!!stripeModalDetails.options.clientSecret}
                            onClick={closeStripeWindow}
                        >
                            <div className="backdrop-modal-content"
                                onClick={(e) => e.stopPropagation()}
                                style={{ height: `${stripeModalDetails.options.clientSecret ? 390 : 0}px` }}
                            >
                                <div className="heading" >{languageData["Pay Here"] ? languageData["Pay Here"] : "Pay Here"}</div>
                                <div className="modal-close" onClick={closeStripeWindow}>
                                    <SvgIcon icon="close" />
                                </div>
                                <Elements options={stripeModalDetails.options} stripe={stripeModalDetails.promise}>
                                    <CheckoutForm stripeModalDetails={stripeModalDetails} />
                                </Elements>
                            </div>
                        </Backdrop>
                    ) : null}

                    <Backdrop
                        className="backdrop-modal-wrapper"
                        open={showTotalBreakdownPopup ? true : false}
                        onClick={() => setShowTotalBreakdownPopup(false)}
                    >
                        <div className="backdrop-modal-content total-wrap"
                            style={{ height: `${showTotalBreakdownPopup ? `${150 + (appointmentObj?.txchrgs?.length * 30) + (appointmentObj.disposables?.length * 30)}px` : '0'}` }}
                        >
                            <div className="heading" >{languageData["Pricing Details"] ? languageData["Pricing Details"] : "Pricing Details"}</div>
                            <div className="modal-close" onClick={() => setShowTotalBreakdownPopup(false)}>
                                <SvgIcon icon="close" />
                            </div>
                            <div className='pricing-details-wrap d-f-c'>
                                <div className='heading'>
                                    <div className='title'>{languageData["SubTotal"] ? languageData["SubTotal"] : "SubTotal"}</div>
                                    {/* {isDisposableAvailable && <div className='title'>Extra Charges</div>} */}
                                    {isDisposableAvailable && appointmentObj?.disposables?.map((item: any) => {
                                        return <div className='title' key={Math.random()}>{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name}</div>
                                    })}
                                    {appointmentObj?.txchrgs?.map((taxData: any) => {
                                        return <div className='title' key={Math.random()}>{taxData?.name}{taxData?.isInclusive ? "(Inclusive)" : ''}({taxData?.value}%)</div>
                                    })}
                                    <div className='title grand-total'>{languageData["Grand Total"] ? languageData["Grand Total"] : "Grand Total"}</div>
                                </div>
                                <div className='details'>
                                    <div className='value'>{configData?.currencySymbol} {appointmentObj?.subTotal}</div>
                                    {isDisposableAvailable && appointmentObj?.disposables?.map((item: any) => {
                                        return <div className='value' key={Math.random()}>{configData.currencySymbol} {(Number(item?.price) * Number(item?.quantity))}</div>
                                    })}
                                    {appointmentObj?.txchrgs?.map((taxData: any) => {
                                        return <div className='value' key={Math.random()}>{configData?.currencySymbol} {taxData?.total}</div>
                                    })}
                                    <div className='value grand-total'>{configData?.currencySymbol} {appointmentObj?.total}</div>
                                </div>
                            </div>
                        </div>
                    </Backdrop>
                </div >
                : <>
                    <OrderConfirmation inititalOrder={orderData} />
                </>
            }
        </>
    )
}

export default Appointment
