import { APPOINTMENT_CONFIRMED, APPOINTMENT_CREATED, APPOINTMENT_CREATED_FOR_OL_PAYMENT, APPOINTMENT_SLOT_GAP_IN_MINS, APPOINTMENT_TYPE, DEFAULT_SERVICE_DURATION } from '@constant/appointment';
import { SERVICE } from '@constant/types';
import { disableLoader, enableLoader, updatePdpItem } from '@context/actions';
import { showError, showSuccess } from '@context/actions/alert';
import { replaceAppointmentServices, syncLocalStorageAppointment } from '@context/actions/appointment';
import SvgIcon from '@element/svgIcon';
import { Box, ClickAwayListener } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import ConfirmationModal from '@module/confirmationModal';
import PaymentVendorModal from '@module/paymentVendorModal';
import UserRegistrationModal from '@module/userRegistration';
import { getDisposableItemsList } from '@storeData/common';
import { getAllStaffSlot } from '@storeData/staff';
import { updateUser } from '@storeData/user';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import OrderConfirmation from '@template/orderConfirmation';
import CheckoutForm from '@template/stripe/CheckoutForm';
import { APISERVICE } from '@util/apiService/RestClient';
import { createOrderFromAppointment } from '@util/order';
import { navigateTo } from '@util/routerService';
import { formatTimeTo12Hr, formatToDateFormat, getDateObj, getTofixValue, removeObjRef } from '@util/utils';
import { windowRef } from '@util/window';
import Script from 'next/script';
import { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import DatePicker from "react-datepicker";
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';

function BookedSlotAppointmentFlow() {

    const imageRef = useRef<HTMLInputElement>(null);
    const [uniqueRecordIdentifier, setuniqueRecordIdentifier] = useState<any>(null);
    const [cookie, setCookie] = useCookies();
    const [userData, setUserCookie] = useState(cookie['user']);
    const store = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const [openCcavenueModal, setopenCcavenueModal] = useState({ active: false, link: "" })
    const appointmentItems = useSelector((state: any) => state.appointmentServices);
    const storeData = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [selectedMember, setSelectedMember] = useState<any>();
    const [showAppointmentConfirmation, setShowAppointmentConfirmation] = useState(false);
    const [appointmentInstruction, setAppointmentInstruction] = useState('');
    const [orderData, setOrderData] = useState<any>(null);
    const [showUserRegistration, setShowUserRegistration] = useState(false);
    const [activeInput, setActiveInput] = useState('');
    const [appointmentObj, setAppointmentObj] = useState<any>('');
    const [showTotalBreakdownPopup, setShowTotalBreakdownPopup] = useState(false);
    const { configData, keywords } = useSelector((state: any) => state.store ? state.store.storeData : null);
    const [familyMember, setFamilyMember] = useState<any>({ name: '', mobileNo: '', countryCode: '' });
    const [showAddMemberModal, setShowAddMemberModal] = useState(false)
    const [error, setError] = useState<any>('');
    const [selectedStoreLocation, setSelectedStoreLocation] = useState('');
    const [instImages, setInstImages] = useState<string[]>([]);
    const [instrImgReset, setInstrImgReset] = useState<boolean>(false);
    const [instrImgError, setInstrImgError] = useState<boolean>(false);
    const [showInstrImageDelConfirmModal, setShowInstrImageDelConfirmModal] = useState<[boolean, number]>([false, 0]);
    const appointmentConfig = configData?.storeConfig?.appointmentConfig;
    const [openPaymentVendorModal, setOpenPaymentVendorModal] = useState<string>('');
    const [paymentGatewayList, setPaymentGatwayList] = useState<any[]>([]);
    const storeMetaData = useSelector((state: any) => state.store ? state.store.storeMetaData : null);
    const [disposableItemsList, seetDisposableItemsList] = useState<any[]>([]);
    const isDisposableAvailable = configData?.storeConfig?.basicConfig?.disposable_items;
    const basicConfig = configData?.storeConfig?.basicConfig
    const [anchorEl, setAnchorEl] = useState({ id: "", ele: null });
    const [allStaffWithSlot, setAllStaffWithSlot] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    interface StripeValues {
        options: StripeElementsOptionsClientSecret;
        promise: Promise<Stripe>;
        paymentIntentId: string;
        redirectUrl: any,
        order?: any,
        address?: any,
        user?: any
    }
    const [dateChangeConfirmationModal, setDateChangeConfirmationModal] = useState({
        secondaryButtonText: "",
        openModal: false,
        title: '',
        message: '',
        buttonText: '',
        handleClose: null
    })
    const languageData: any = store.languageKeywords
    const activeLanguage: any = store.activeLanguage
    const [stripeModalDetails, setStripeModalDetails] = useState<StripeValues>({
        options: {
            clientSecret: "",
            appearance: {
                theme: 'stripe',
                labels: 'floating',
            }
        },
        promise: undefined,
        paymentIntentId: "",
        redirectUrl: ""
    });

    useEffect(() => {

        if (windowRef) {
            dispatch(syncLocalStorageAppointment());
            localStorage.removeItem("payment_type");
            window.scrollTo(0, 0);
            dispatch(updatePdpItem(null));
        }

        if (Boolean(appointmentItems?.length)) {
            getAllStaffSlot(getDateObj(new Date(appointmentItems[0].date)).dateObj, storeData.storeId, storeData.tenantId).then((res: any) => {
                setAllStaffWithSlot(res.data || [])
            })
        }

        APISERVICE.GET(process.env.NEXT_PUBLIC_PAYMENT_VENDOR + `/getPaymentGatewayData/${storeData.tenantId}/${storeData.storeId}`)
            .then((resp) => {
                if (!resp.data) {
                    // INSERT ERROR MESSAGE HERE
                    console.log("Unable to load payment configurations");
                    return;
                }
                // let gateways: { [key: string]: PaymentGateway } = { [resp.data[0].toLowerCase()]: { key: resp.data[1], redirectUrl: resp.data[2] } };
                setPaymentGatwayList(resp.data);
                const stripePaymode = resp.data.find((paymode) => paymode.name == "Stripe");
                if (stripePaymode) {
                    setStripeModalDetails({ ...stripeModalDetails, ...stripePaymode, promise: loadStripe(stripePaymode.key) })
                }
            }).catch((err) => {
                console.log(err);
            })
        if (isDisposableAvailable) {
            getDisposableItemsList(storeData.tenantId, storeData.storeId).then((res: any) => {
                seetDisposableItemsList(res);
            })
        }
    }, []);

    useEffect(() => {
        setUserCookie(cookie['user']);
    }, [cookie])

    useEffect(() => {
        if (windowRef && (showTotalBreakdownPopup || showAppointmentConfirmation)) {
            document.body.classList.add("o-h")
        } else {
            document.body.classList.remove("o-h")
        }
        return () => {
            document.body.classList.remove("o-h")
        }

    }, [windowRef, showTotalBreakdownPopup, showAppointmentConfirmation]);

    useEffect(() => {
        if (appointmentItems.length != 0) {
            const appointmentCopy = { ...appointmentItems }
            let appliedTaxes: any[] = [];
            let subTotal = 0;
            let total = 0;
            appointmentItems.map((service: any, i: number) => {

                // her  variation price not considered because at the time of appointment booking it is set inside service object as price and salePrice
                //condition for member pricing
                let applicablePrice: any = (userData?.membership?.length == 1 && userData.membership[0].typeId == 4) ? (service.memberPrice) : (service.salePrice || service.price);
                subTotal += parseFloat(applicablePrice);
                total += parseFloat(applicablePrice);

                if (service.txchrgs) {
                    service.txchrgs.map((taxData: any) => {

                        let tDetails = configData?.txchConfig ? configData?.txchConfig?.filter((t: any) => t.name == taxData.name) : [];
                        taxData.isInclusive = tDetails[0]?.isInclusive;
                        //update global total
                        if (tDetails.length != 0) {
                            let taxApplied = getTofixValue(Number((parseFloat(applicablePrice) / 100) * parseFloat(tDetails[0].value)))
                            if (!taxData.isInclusive) total = getTofixValue(Number(total + taxApplied));
                            if (taxData.isInclusive) {
                                // x = (price * 100) / (tax + 100)
                                let itemActualPrice = ((applicablePrice * 100) / (100 + tDetails[0].value));
                                let actualTax = (itemActualPrice * tDetails[0].value) / 100;
                                taxApplied = getTofixValue(Number(actualTax));
                                // tax = x * (tax / 100)
                            }
                            //update global applied taxes total
                            let isAVl = appliedTaxes.findIndex((at: any) => at.name == taxData.name);
                            if (isAVl != -1) {
                                appliedTaxes[isAVl].total = getTofixValue(Number(appliedTaxes[isAVl].total + taxApplied));
                            } else {
                                appliedTaxes.push({ name: tDetails[0].name, value: tDetails[0].value, total: taxApplied, isInclusive: tDetails[0].isInclusive })
                            }
                        }
                    })
                }
                if (i == appointmentItems.length - 1) {
                    appointmentCopy.total = getTofixValue(total);
                    appointmentCopy.subTotal = getTofixValue(subTotal);
                    appointmentCopy.txchrgs = appliedTaxes;
                    appointmentCopy.disposables = getDisposablesItems(disposableItemsList, appointmentItems);
                    const dispTotal = Boolean(appointmentCopy.disposables?.length) ? appointmentCopy.disposables.reduce((a: any, b: any) => a + (Number(b.price) * Number(b.quantity)), 0) : 0;
                    appointmentCopy.total = getTofixValue(Number(total) + Number(dispTotal));
                    if (appointmentCopy.total != appointmentObj.total) {//check for total beacause of stopping rerendering on setting appointment
                        setAppointmentObj(appointmentCopy);
                    }
                    // console.log("appointmentCopy", appointmentCopy)
                }
            })
        } else {
            setAppointmentObj({ total: 0, subTotal: 0 });
        }
        setActiveInput('');
    }, [appointmentItems, disposableItemsList]);

    const getDisposablesItems = (disposableItemsList: any, appointmentItems: any) => {

        let disposables = [];
        let totalServiceQuantity = appointmentItems.length;
        if (totalServiceQuantity && Boolean(disposableItemsList?.length)) {
            const serviceLevelItems = disposableItemsList.filter((i: any) => i.isServiceLevel && i.active);
            const orderLevelItems = disposableItemsList.filter((i: any) => !i.isServiceLevel && i.active);

            if (serviceLevelItems.length != 0) {
                //add service level items
                serviceLevelItems.map((dItem: any) => {
                    disposables.push({ ...dItem, quantity: totalServiceQuantity })
                })
            }
            if (orderLevelItems.length != 0) {
                //add order level items
                orderLevelItems.map((dItem: any) => {
                    disposables.push({ ...dItem, quantity: 1 })
                })
            }
        }
        return disposables;
    }

    const removeService = (index) => {
        const appointmentCopy = [...appointmentItems];
        appointmentCopy.splice(index, 1);
        dispatch(replaceAppointmentServices(appointmentCopy));
        dispatch(showSuccess(`${languageData['Service Removed'] ? languageData['Service Removed'] : 'Service Removed'}`, 2000));
    }

    const onSelectMember = (member) => {
        setSelectedMember({ ...member });
        setActiveInput('');
    }

    const onLoginClose = (user) => {
        if (user) {
            onConfirmBookingClick(user);
        }
        setShowUserRegistration(false);
    }

    const getServiceDuration = (duration, durationType) => {
        if (durationType?.includes('hrs')) {
            return (parseFloat(duration) * 60).toFixed(1);
        } else return parseFloat(duration);
    }

    const createIndividualExpertService = (serviceDetails: any) => {
        const { id, name, duration, durationType, categoryName, price, quantity, salePrice, memberPrice, categoryId, txchrgs, variations, consumables, hsnSac, staff, date, slot } = serviceDetails;
        let toSlot = slot;
        const drtn = getServiceDuration(duration, durationType) || DEFAULT_SERVICE_DURATION;

        if (Boolean(appointmentConfig?.autoConfirmed)) {
            const fromSlotIndex = staff.slots.findIndex((s) => s.slot == slot);
            const toIndex = (fromSlotIndex + Number(Number(drtn) / APPOINTMENT_SLOT_GAP_IN_MINS)).toFixed();

            if (toIndex > (staff.slots.length - 1)) {
                //exceeded salon closing time slot then assign end slot which is current selected slot
                if ((fromSlotIndex + 1) > (staff.slots.length - 1)) {
                    toSlot = slot;
                } else {
                    // if duration uncluded slot increases then assign toslot as slot next to from slot
                    toSlot = staff.slots[fromSlotIndex + 1].slot;
                }
            } else {
                toSlot = staff.slots[toIndex].slot
            }
        }

        const contributions: any = serviceDetails.contributions || [];
        contributions.push({
            slot: `${slot}-${toSlot}`,
            duration: drtn,
            expertId: staff.id,
            expertName: staff.lastName ? `${staff.firstName} ${staff.lastName}` : staff.firstName,
            workPercentage: 100,
            workAmount: (userData?.membership?.length == 1 && userData.membership[0].typeId == 4) ? memberPrice : (salePrice || price),
            specialist: true
        })
        const individualAppointmentObj = {
            "appointmentDay": date,
            "appointmentTime": slot,
            "duration": drtn,
            "serviceCategory": categoryName,
            "service": name,
            "slot": `${slot}-${toSlot}`,
            "expertId": staff?.id,
            "expertName": staff ? (staff.lastName ? `${staff.firstName} ${staff.lastName}` : staff.firstName) : '',
            "price": price,
            "billingPrice": (userData?.membership?.length == 1 && userData.membership[0].typeId == 4) ? memberPrice : price,
            "quantity": quantity || 1,
            "salePrice": salePrice,
            "memberPrice": memberPrice,
            "serviceCategoryId": categoryId,
            "serviceId": id,
            "txchrgs": txchrgs,
            "variations": variations,
            "consumables": consumables,
            "hsnSac": hsnSac,
            contributions
        }
        return individualAppointmentObj;
    }

    const dataUrlToFile = (data: string, name: string) => {
        let splitString = data.split(',');
        let byteString = atob(splitString[1]);
        let mimeString = splitString[0].split(":")[1].split(';')[0];

        let ia = new Uint8Array(new ArrayBuffer(byteString.length));

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ia], name, { type: mimeString });
    }

    const uploadImage = async (imagePath: string, index: number) => {
        return new Promise<string>((res: Function) => {
            let formData: any = new FormData();
            formData.append("id", storeData.tenantId);
            formData.append("file", dataUrlToFile(imagePath, `instruction_${index}.png`));
            formData.append("type", "orders");
            APISERVICE.POST(process.env.NEXT_PUBLIC_GET_BASE_CATALOG_URL + "/s3/uploadwithtype", formData, { resoponseType: 'text' }).then((resp: any) => res(resp.data)).catch(() => res(null));

        })
    }

    const getDisposableItems = () => {
        const items: any = []
        appointmentObj.disposables.map((i: any) => {
            items.push({
                "name": i.name,
                "itemId": i.id,
                "quantity": i.quantity,
                "price": i.price,
                "remark": null
            })
        })
        return items
    }

    const submitAppointment = async (user, paymode: string = '', onlineMode: string = '') => {
        setShowAppointmentConfirmation(false);
        if (user) {
            dispatch(enableLoader());
            const appointmentsList = [];
            appointmentItems.map((item) => {
                appointmentsList.push(createIndividualExpertService(item));
            });
            let familyMember = null;
            if (selectedMember) {
                familyMember = selectedMember.mobileNo != userData.mobileNo ? selectedMember : null;
            }
            let imageList = [];
            let imageUploadFail = false;
            for (let i = 0; i < instImages.length; i++) {

                if (instImages[i].startsWith("data:image")) {
                    let f: string = await uploadImage(instImages[i], i);
                    if (f) imageList.push(f);
                    else {
                        imageUploadFail = true;
                        break
                    }
                }
                if (imageUploadFail) {
                    console.log("Error uploading images")
                    return;
                }
            }
            const apiBody = {
                "appointmentDay": appointmentsList[0].appointmentDay,
                "appointmentTime": appointmentsList[0].appointmentTime,
                "duration": appointmentsList[0].duration,
                "instruction": appointmentInstruction,
                "instrImages": imageList,
                "slot": appointmentsList[0].slot,
                "expertId": appointmentsList[0].expertId,
                "expertName": appointmentsList[0].expertName,
                "storeId": storeData.storeId,
                "tenantId": storeData.tenantId,
                "store": storeData.store,
                "tenant": storeData.tenant,
                "guestId": user.id,
                "guestName": user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName,
                "guestMobile": user.mobileNo,
                "guestCountryCode": user.countryCode,
                "guestEmail": user.email,
                "createdOn": new Date().toISOString(),
                "expertAppointments": appointmentsList,
                "noOfRemindersSent": '',
                "rescheduled": false,
                "feedbackLinkShared": false,
                "bookedFor": familyMember,
                "storeLocation": selectedStoreLocation,
                "type": APPOINTMENT_TYPE,
                "advanceAmount": 0,
                "disposables": getDisposableItems(),
                "smsKeys": {
                    "appointmentConfirmed": true,
                    "appointmentCancelled": false,
                    "appointmentFeedback": false,
                    "smsForAppointments": true,
                    "combineFeedbackAndInvoice": false,
                },
                "status": [{
                    "status": Boolean(appointmentConfig.autoConfirmed) ? APPOINTMENT_CONFIRMED : ((Boolean(onlineMode == "partial" || onlineMode == "fullpayment") ? APPOINTMENT_CREATED_FOR_OL_PAYMENT : APPOINTMENT_CREATED)),
                    "staff": "",
                    "staffName": "",
                    "remark": "",
                    "createdOn": new Date().toISOString()
                }],
                "uniqueRecordIdentifier": uniqueRecordIdentifier
            }
            if (onlineMode == "partial") {
                apiBody.advanceAmount = getAdvanceAmount(appointmentObj.total);
            } else if (onlineMode == "fullpayment") {
                apiBody.advanceAmount = appointmentObj.total;
            }
            localStorage.setItem("payment_type", `appointment-${paymode.toLowerCase()}`);
            switch (paymode.toLowerCase()) {
                case "pay_later":
                    // create regular pay later order
                    APISERVICE.POST(process.env.NEXT_PUBLIC_PLACE_APPOINTMENT, apiBody).then((res) => {
                        setTimeout(() => {
                            // sendWhatsappMsg(user)
                            const orderDetails = createOrderFromAppointment(res.data);
                            setOrderData({ ...orderDetails });
                            dispatch(disableLoader());
                            dispatch(replaceAppointmentServices([]));
                            dispatch(showSuccess(`${languageData['Appointment request submitted'] ? languageData['Appointment request submitted'] : 'Appointment request submitted'}`))
                        }, 1000)
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Appointment booking failed'] ? languageData['Appointment booking failed'] : 'Appointment booking failed'}`))
                    })
                    break;
                case "razorpay":
                    // create razor pay order
                    APISERVICE.POST(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/rzr`, apiBody).then((res) => {
                        setTimeout(() => {
                            const { Razorpay }: any = window;
                            const rzp1 = new Razorpay(res.data);
                            rzp1.open();
                            dispatch(disableLoader());
                        }, 1000)
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Order creation failed'] ? languageData['Order creation failed'] : 'Order creation failed'}`))
                    })
                    break;
                case "ccavenue":
                    // create razor pay order
                    APISERVICE.POST(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/ccavenue?env=test`, apiBody).then((res) => {
                        setTimeout(() => {
                            window.open(res.data.redirctTo, "_self")
                            // setopenCcavenueModal({ active: true, link: res.data.redirctTo })
                            dispatch(disableLoader());
                        }, 1000)
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Order creation failed'] ? languageData['Order creation failed'] : 'Order creation failed'}`))
                    })
                    break;
                case "stripe":
                    // create stripe order
                    APISERVICE.POST(`${process.env.NEXT_PUBLIC_PLACE_APPOINTMENT}/stripe${'?currencyCode=usd'}`, apiBody).then((res: any) => {

                        appointmentObj.id = res.data.orderId;
                        // setOrderData({ ...appointmentObj });
                        const paymentAddress = {
                            area: storeMetaData.area,
                            city: storeMetaData.city,
                            country: storeMetaData.code,//used as country code // IN
                            code: storeMetaData.pincode,
                            landmark: storeMetaData.landmark,
                            latitude: storeMetaData.latitude,
                            line: storeMetaData.address,
                            longitude: storeMetaData.longitude,
                        }
                        setStripeModalDetails({
                            ...stripeModalDetails,
                            options: {
                                ...stripeModalDetails.options,
                                clientSecret: res.data.clientSecret
                            },
                            paymentIntentId: res.data.paymentIntentId,
                            order: appointmentObj,
                            address: paymentAddress,
                            user: userData
                        });
                        dispatch(disableLoader());
                    }).catch((error) => {
                        console.log(error);
                        dispatch(disableLoader());
                        dispatch(showError(`${languageData['Order creation failed'] ? languageData['Order creation failed'] : 'Order creation failed'}`))
                    })
                    break;
            }
        }
    }

    const expertSlotValidation = () => {
        let err = { id: "", text: "" }
        appointmentItems.map((item, index) => {
            if (!Boolean(err.id)) {
                if (!Boolean(item.staff?.id)) err = { id: `staff-${index}`, text: `${languageData["Select expert for"] ? languageData["Select expert for"] : "Select expert for"} ${item.name}` };
                if (!Boolean(err.id) && !item.slot) err = { id: `slot-${index}`, text: `${languageData["Select slot for"] ? languageData["Select slot for"] : "Select slot for"} ${item.name}` };
            }
        })
        if (Boolean(err.id)) {
            dispatch(showError(err.text))
            setError(err);
            return false;
        } else return true;
    }
    const onConfirmBookingClick = (user) => {
        if (!appointmentItems || appointmentItems.length == 0) {
            dispatch(showError(`${languageData['Select any service'] ? languageData['Select any service'] : 'Select any service'}`));
            return
        } else if (!appointmentItems[0]?.date) {
            dispatch(showError(`${languageData['Select date'] ? languageData['Select date'] : 'Select date'}`));
            return
        } else if (!expertSlotValidation()) {
            return
            // dispatch(showError('Select preferred slot'));
        } else {
            if (user) {
                setuniqueRecordIdentifier(new Date().getTime());
                setSelectedMember({ name: user?.firstName, mobileNo: user?.mobileNo, countryCode: user?.countryCode });
                setShowAppointmentConfirmation(true);
            } else setShowUserRegistration(true);
        }
    }

    const onChangeMemberValue = (from, value) => {
        setError('')
        const familyMemberCopy = { ...familyMember };
        if (from == 'mobileNo') {
            if (value && value.length > 10) {
                setFamilyMember(familyMemberCopy)
            } else {
                familyMemberCopy[from] = value;
                setFamilyMember(familyMemberCopy)
            }
        } else {
            familyMemberCopy[from] = value;
            setFamilyMember(familyMemberCopy)
        }
    }

    const onPhoneChange = (mobileNo, countryCode = storeMetaData.countryCode) => {
        setError({ id: '', text: '' });
        const num = mobileNo.charAt(mobileNo?.length - 1).replace(".", '');
        if (((num && num != ' ') && !isNaN(num))) {
            setFamilyMember({ ...familyMember, mobileNo: mobileNo, countryCode });

        } else if (!Boolean(mobileNo?.length)) setFamilyMember({ ...familyMember, mobileNo: mobileNo, countryCode });
    }

    const closeMemberForm = () => {
        setFamilyMember({ name: '', mobileNo: '' });
        setShowAddMemberModal(false)
    }

    const validateExistingMembers = (userDataCopy) => {
        if (userDataCopy.firstName == familyMember.name) {
            setError({ id: "existing-member", text: `${languageData["Its your registred name, try different one"] ? languageData["Its your registred name, try different one"] : "Its your registred name, try different one"}` });
            return userDataCopy;
        }

        if (userDataCopy.mobileNo == familyMember.mobileNo) {
            setError({ id: "existing-member", text: `${languageData["Its your registred number, try different one"] ? languageData["Its your registred number, try different one"] : "Its your registred number, try different one"}` });
            return userDataCopy;
        }

        let existingName = userDataCopy?.familyMembers?.find((u: any) => u.name == familyMember.name);
        if (Boolean(existingName)) {
            setError({ id: "existing-member", text: `${languageData["Name already registred with number"] ? languageData["Name already registred with number"] : "Name already registred with number"} ${existingName.mobileNo}` });
            return existingName;
        }

        let existingNumber = userDataCopy?.familyMembers?.find((u: any) => u.mobileNo == familyMember.mobileNo);
        if (Boolean(existingNumber)) {
            setError({ id: "existing-member", text: `${languageData["Number already registred with name"] ? languageData["Number already registred with name"] : "Number already registred with name"} ${existingNumber.name}` });
            return existingNumber;
        }

        return null;
    }

    const submitMemberForm = () => {
        const userDataCopy = { ...userData }
        setError("")
        if (!familyMember.name) {
            setError('member-name')
        } else if (!familyMember.mobileNo) {
            setError('member-mobileNo')
        } else if (!validateExistingMembers(userDataCopy)) {
            if (userDataCopy.familyMembers) userDataCopy.familyMembers.push(familyMember)
            else userDataCopy.familyMembers = [familyMember];
            dispatch(enableLoader());
            updateUser(userDataCopy)
                .then((res: any) => {
                    dispatch(disableLoader());
                    dispatch(showSuccess(`${languageData['Member Added'] ? languageData['Member Added'] : 'Member Added'}`, 2000));
                    setCookie("user", res.data, { //user registration fields
                        path: "/",
                        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
                        sameSite: true,
                    })
                    setSelectedMember({ ...familyMember })
                }).catch(() => {
                    dispatch(disableLoader());
                    dispatch(showError(`${languageData['Something went wrong. please try again'] ? languageData['Something went wrong. please try again'] : 'Something went wrong. please try again'}`, 2000));
                })
            setShowAddMemberModal(false);
            setFamilyMember({ name: '', mobileNo: '' });
            setActiveInput('');
        }
    }

    const redirectToCategories = () => {
        let activeCats = storeData.categories.filter((c: any) => c.active && c.type == keywords[SERVICE]);
        let itemUrl = activeCats[0].name.toLowerCase().split(" ").join("-");
        navigateTo(`${itemUrl}-srp`);
    }

    const openInstrFileInput = () => { imageRef.current.click(); }

    const resetInstrFileInput = () => { setInstrImgReset(!instrImgReset); }

    const addInstrImage = (el: HTMLInputElement) => {
        if (!el.files.length) return;

        let file = el.files[0];
        if (file.size > 2097152) {
            setInstrImgError(true);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            if (typeof reader.result == 'string') {
                let flag = true;
                for (let i = 0; i < instImages.length; i++) {
                    if (reader.result === instImages[i]) {
                        flag = false;
                        break;
                    }
                }

                if (flag) setInstImages([...instImages, reader.result]);
            }
        }
        reader.readAsDataURL(file);
        setInstrImgError(false);

        resetInstrFileInput();
    }

    const deleteImage = (index: number) => {
        let imageList = [...instImages];
        imageList.splice(index, 1);
        setInstImages(imageList);
    }

    const getAdvanceAmount = (total: any) => {
        let amount = 0;
        if (Boolean(appointmentConfig?.advancePayments)) {
            amount = appointmentConfig?.advanceType == "PERCENTAGE" ? getTofixValue(Number(Number(appointmentConfig.advanceValue) / 100 * total)) : appointmentConfig?.advanceValue
        }
        return Number(amount);
    }

    const openInstrImageDeleteConfirmModal = (index: number) => { setShowInstrImageDelConfirmModal([true, index]); }
    const closeInstrImageDeleteConfirmModal = () => { setShowInstrImageDelConfirmModal([false, 0]); }

    const onAppointmentConfirmation = () => {
        if (userData) submitAppointment(userData, "pay_later");
        else setShowUserRegistration(true);
    }

    const handlePaymentVendorSelection = (paymode) => {
        if (paymode) {
            submitAppointment(userData, paymode.name, openPaymentVendorModal);
        }
        setOpenPaymentVendorModal('');
    }

    const proceedToPayments = (mode: string = '') => {
        setOpenPaymentVendorModal('')
        if ((paymentGatewayList?.length) > 1) {
            //open paymode vendor selection modal
            setOpenPaymentVendorModal(mode)
        } else {
            //redirect to payments screen
            submitAppointment(userData, paymentGatewayList[0].name, mode)
        }
    }

    const onInputChange = (from, value, index) => {
        setError({ id: '', text: '' });
        const appointmentItemsCopy = removeObjRef(appointmentItems);
        if (from == "date") {
            setIsLoading(true)
            getAllStaffSlot(getDateObj(new Date(value)).dateObj, storeData.storeId, storeData.tenantId).then((res: any) => {
                setIsLoading(false)
                setAllStaffWithSlot(res.data || [])
                appointmentItemsCopy.map((item) => {
                    item.date = value;
                    item.staff = null;
                    item.slot = null;
                })
                dispatch(replaceAppointmentServices(appointmentItemsCopy));
                setDateChangeConfirmationModal({
                    secondaryButtonText: "",
                    openModal: false,
                    title: '',
                    message: '',
                    buttonText: '',
                    handleClose: null
                })
            }).catch(() => {
                setIsLoading(false)
                dispatch(showError(`${languageData["Staffs not available on this date"] ? languageData["Staffs not available on this date"] : "Staffs not available on this date"}`));
            })
        } else if (from == "staff") {
            appointmentItemsCopy[index].staff = value;
            appointmentItemsCopy[index].slot = null;
            setAnchorEl({ id: null, ele: null });
            dispatch(replaceAppointmentServices(appointmentItemsCopy));
        } else if (from == "slot") {
            appointmentItemsCopy[index].slot = value;
            setAnchorEl({ id: null, ele: null });
            dispatch(replaceAppointmentServices(appointmentItemsCopy));
        }
    }

    const onDateChange = (updatedDate, index) => {
        setError({ id: '', text: '' });
        if (Boolean(appointmentItems.length) && (getDateObj(new Date(appointmentItems[0].date)).dateObj !== getDateObj(new Date(updatedDate)).dateObj)) {
            setDateChangeConfirmationModal({
                openModal: true,
                secondaryButtonText: `${languageData["Change"] ? languageData["Change"] : "Change"}`,
                title: `${languageData["Date Change Confirmation"] ? languageData["Date Change Confirmation"] : "Date Change Confirmation"}`,
                message: `${languageData["If you change the date, then the previously added service date and slot get reset"] ? languageData["If you change the date, then the previously added service date and slot get reset"] : "If you change the date, then the previously added service date and slot get reset."}`,
                buttonText: `${languageData["Yes"] ? languageData["Yes"] : "Yes"}`,
                handleClose: (status) => status ? onInputChange('date', updatedDate, index) :
                    setDateChangeConfirmationModal({
                        openModal: false,
                        secondaryButtonText: "",
                        title: '',
                        message: '',
                        buttonText: '',
                        handleClose: null
                    })
            })
        } else {
            //for the first time setting date
            onInputChange('date', updatedDate, index);
        }
    }


    const renderStaffList = (index, item) => {
        let availableStaff = [];
        if (allStaffWithSlot.length != 0) {
            if (Boolean(item?.experts?.length)) {
                item.experts.map((expert: any) => {
                    let alreadyAdded = availableStaff.find((s) => s.id == expert.id);
                    if (!Boolean(alreadyAdded)) {
                        let staff = allStaffWithSlot.find((s) => s.id == expert.id);
                        Boolean(staff) && availableStaff.push(staff)
                    }
                })
            }
        }
        return <div className={`staff-list-wrapper ${!Boolean(availableStaff.length) ? "error" : ""}`}>
            <ClickAwayListener onClickAway={(e: any) => {
                setAnchorEl({ id: null, ele: null });
            }}>
                <Box>
                    {(!Boolean(availableStaff.length)) ? <div className='unavailable-data'>{languageData["Experts not available for this date"] ? languageData["Experts not available for this date"] : "Experts not available for this date"}</div> : <>
                        {availableStaff.map((staff: any, i: number) => {
                            return <Fragment key={i}>
                                <div className={`item-details ${item.staff?.id == staff.id ? "active" : ''}`} onClick={() => onInputChange("staff", staff, index)}>{staff.firstName} {staff.lastName}</div>
                            </Fragment>
                        })}
                    </>}
                </Box>
            </ClickAwayListener>
        </div>
    }

    const closeStripeWindow = (e: any) => {
        setStripeModalDetails({ ...stripeModalDetails, options: { ...stripeModalDetails.options, clientSecret: "" } });
        APISERVICE.PUT(process.env.NEXT_PUBLIC_STRIPE_PAYMENT + "/cancel?paymentIntentId=" + stripeModalDetails.paymentIntentId, null)
    }

    const renderSlotsList = (index, item) => {

        let errorElement = null;
        if (!Boolean(item.date) && !Boolean(item?.staff?.id)) {
            errorElement = <div className='unavailable-data'>
                {languageData["Please select date & staff to view available slots"] ? languageData["Please select date & staff to view available slots"] : "Please select date & staff to view available slots"}
            </div>
        }
        if (!errorElement && !Boolean(item.date)) {
            errorElement = <div className='unavailable-data'>
                {languageData["Please select date to view available slots"] ? languageData["Please select date to view available slots"] : "Please select date to view available slots"}
            </div>
        }
        if (!errorElement && !Boolean(item?.staff?.id)) {
            errorElement = <div className='unavailable-data'>
                {languageData["Please select expert to view available slots"] ? languageData["Please select expert to view available slots"] : "Please select expert to view available slots"}
            </div>
        }
        const availableSlots: any = allStaffWithSlot.filter((s) => s.id == item.staff?.id)[0]?.slots;

        if (!errorElement && !Boolean(availableSlots?.length)) {
            errorElement = <div className='unavailable-data'>
                {languageData["Slots are unavailable for this date & staff"] ? languageData["Slots are unavailable for this date & staff"] : "Slots are unavailable for this date & staff"}
            </div>
        }

        let slotFounds = 0;
        availableSlots.map((slot) => {
            if (slotFounds <= Number(item.duration || 30) / 30) {
                if (slot.isAvailable) slotFounds += 1
                else slotFounds = 0
            }
        })
        let isSlotsAvailable = slotFounds >= Number(item.duration || 30) / 30;

        return <>
            <div className={`staff-list-wrapper ${Boolean(errorElement) ? "error" : "slot-time-outer"}`}>
                <ClickAwayListener onClickAway={(e: any) => {
                    setAnchorEl({ id: null, ele: null });
                }}>
                    <Box>
                        {Boolean(errorElement) ? errorElement : <>
                            {!isSlotsAvailable && <div className='label error slot-error' >{Number(item.duration || 30)} {languageData["min slot unavailable for selected staff"] ? languageData["min slot unavailable for selected staff"] : "min slot unavailable for selected staff"}</div>}
                            {availableSlots.map((storeSlot, i) => {
                                return <div key={i} onClick={() => onInputChange('slot', storeSlot.slot, index)}
                                    className={`${storeSlot.isAvailable ? '' : "unavailable"} ${item.slot == storeSlot.slot ? 'slot-wrap active' : "slot-wrap"}  ${!isSlotsAvailable ? "disabled" : ""}`}>
                                    {storeSlot.isAvailable ? formatTimeTo12Hr(storeSlot.slot) : "Booked"}
                                </div>
                            })}
                        </>}
                    </Box>
                </ClickAwayListener>
            </div>
        </>
    }

    const DatePickerInput = forwardRef((props: any, ref: any) => <button className={`date-picker-button ${error.id == 'date' ? "error" : ""}`}
        onClick={props.onClick} ref={ref}>
        {languageData["Appointment Date"] ? languageData["Appointment Date"] : "Appointment Date"}: {props.value ? `: ${props.value}` : <div className='guided-label'>{languageData["Click to select date"] ? languageData["Click to select date"] : "Click to select date"}</div>}
    </button>);

    return (
        <>
            {Boolean(appointmentConfig?.advancePayments) && <Script src="https://checkout.razorpay.com/v1/checkout.js" strategy="lazyOnload" />}
            {!Boolean(orderData) ?
                <div className="main-wrapper appointment-wrap booked-slot-appointment-wrap  ">
                    {userData && <div className="card">
                        <div className="sub-heading">{languageData["User Details"] ? languageData["User Details"] : "User Details"}</div>
                        <div className="username">{userData.firstName} {userData.lastName}
                        </div>
                        <div className="number">{basicConfig.multipleCountryCode ? '+' + userData.countryCode : ""} {userData.mobileNo}</div>
                        <div className="usernumber">{userData.email}</div>

                    </div>}
                    {Boolean(appointmentItems?.length) && <div className="input-wrap-with-label date-picker-wrap card">
                        <DatePicker
                            selected={appointmentItems[0]?.date ? new Date(appointmentItems[0]?.date) : null}
                            onChange={(date) => onDateChange(date, 0)}
                            customInput={<DatePickerInput from={"Appointment Date"} />}
                            // peekNextMonth={false}
                            // showMonthDropdown={false}
                            // showYearPicker={false}
                            // showYearDropdown={false}
                            // dropdownMode="select"
                            dateFormat={configData.dateFormat || "dd-MM-yyyy"}
                            minDate={new Date()}
                        />
                    </div>}
                    <>
                        <div className="selected-service-list-wrap card clearfix">
                            <div className="sub-heading">{languageData["Selected Services"] ? languageData["Selected Services"] : "Selected Services"}</div>
                            {appointmentItems?.length != 0 && appointmentItems?.map((item, index) => {
                                return <div key={index} className="service-wrap">
                                    <div className='service-details'>
                                        <div className='service-name'>{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item.name} </div>
                                        {item.variations && item.variations.length != 0 && <div className='variations-wrap'>
                                            ({item.variations[0].name}
                                            {item.variations[0]?.variations?.length != 0 && <>-{item.variations[0]?.variations[0]?.name}</>}
                                            {item.variations[0]?.variations?.length != 0 && item.variations[0]?.variations[0]?.variations?.length != 0 && <>-{item.variations[0]?.variations[0]?.variations[0]?.name}</>})
                                        </div>}
                                    </div>
                                    <div className='staff-slot-wrap'>
                                        <div className='staff-list' >
                                            <div className='value-wrap'>
                                                <div className="value-label">{languageData["Expert"] ? languageData["Expert"] : "Expert"}: </div>
                                                <div className={`label ${error.id == `staff-${index}` ? "error" : ""}`} onClick={(e: any) => setAnchorEl({ id: `staff-${index}`, ele: e.currentTarget })}>
                                                    {item.staff ? <div className='staff-input'>{item?.staff?.firstName} {item?.staff?.lastName}</div> :
                                                        <div className='guided-label'>{languageData["Select expert"] ? languageData["Select expert"] : "Select expert"}</div>}
                                                </div>
                                                {anchorEl.id == `staff-${index}` && renderStaffList(index, item)}
                                            </div>
                                        </div>
                                        <div className='staff-list slot-list'>
                                            <div className='value-wrap'>
                                                <div className="value-label">{languageData["Slot"] ? languageData["Slot"] : "Slot"}: </div>
                                                <div className={`label ${error.id == `slot-${index}` ? "error" : ""} ${!item.staff ? 'disabled' : ''}`} onClick={(e: any) => setAnchorEl({ id: `slot-${index}`, ele: e.currentTarget })}>
                                                    {item.slot ? <div className='staff-input'>{formatTimeTo12Hr(item?.slot)}</div> :
                                                        <div className='guided-label'>{languageData["Select Slot"] ? languageData["Select Slot"] : "Select Slot"}</div>}
                                                </div>
                                                {anchorEl.id == `slot-${index}` && renderSlotsList(index, item)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-cancel-icon" onClick={() => removeService(index)} >
                                        <SvgIcon icon="close" />
                                    </div>
                                </div>
                            })}
                            <div className="btn-wrap fullwidth">
                                <div className='btn' onClick={redirectToCategories}>{languageData["Add more services"] ? languageData["Add more services"] : "Add more services"}</div>
                            </div>
                        </div>
                    </>

                    <div className="input-wrap card">
                        <div className="sub-heading">{languageData["Instruction/Address"] ? languageData["Instruction/Address"] : "Instruction/Address"}</div>
                        <textarea className={"inputbox instr" + (!appointmentInstruction ? " instrShare" : "")} placeholder={languageData["Note"] ? languageData["Note"] : "Note"} value={appointmentInstruction || ''} onChange={(e) => setAppointmentInstruction(e.target.value)} />
                        {instImages.length ? <div className="instrImgScrollContainer">
                            <div className="instrImgScroll">
                                {instImages.map((link: string, index: number) => <div key={link} className="instrImgContainer card">
                                    <div className="instrImageDelIcon" onClick={() => openInstrImageDeleteConfirmModal(index)} >
                                        <SvgIcon icon="close" />
                                    </div>
                                    <img className="instrImg" src={link} onClick={() => openInstrImageDeleteConfirmModal(index)} /></div>)}
                            </div>
                        </div> : null}
                        <div className="instrFileInputButton">
                            <button className="primary-btn border-btn" onClick={openInstrFileInput}>{languageData["Add Image"] ? languageData["Add Image"] : "Add Image"}</button>
                            <div className={instrImgError ? " add-img-note error" : "add-img-note"}>({languageData["Max 2MB per image"] ? languageData["Max 2MB per image"] : "Max 2MB per image"})</div>
                            <input key={instrImgReset ? "y" : "n"} type="file" ref={imageRef} onChange={(e) => addInstrImage(e.target)} className="hide" accept="image/jfif,image/png,image/jpeg,image/webp" />
                        </div>
                    </div>

                    {<div className="book-wrap">
                        {!(!appointmentObj.total) && <>
                            <div className='total-wrap d-f-c' style={{ width: "300px" }}>
                                <div className='title'>Total : </div>
                                <div className='value' onClick={() => setShowTotalBreakdownPopup(true)}>{configData.currencySymbol} {appointmentObj.total}</div>
                                <div className='info d-f-c info-color' onClick={() => setShowTotalBreakdownPopup(true)}><SvgIcon icon="info" /></div>
                            </div>
                        </>}
                        <button style={{ width: "100%" }} className="primary-btn rounded-btn" onClick={() => onConfirmBookingClick(userData)}>{languageData["Confirm Booking"] ? languageData["Confirm Booking"] : "Confirm Booking"}</button>
                    </div>}

                    <Backdrop
                        className="backdrop-modal-wrapper instrImageDelConfirmModal"
                        open={showInstrImageDelConfirmModal[0]}
                        title="Are you sure you want to delete this?"
                    >
                        <div className='backdrop-modal-content' style={{ height: showInstrImageDelConfirmModal[0] ? 445 : 0 }}>
                            <div className="heading">{languageData["Are you sure you want to delete this?"] ? languageData["Are you sure you want to delete this?"] : "Are you sure you want to delete this?"}</div>
                            <div className="imageHeightLimiter">
                                <img src={showInstrImageDelConfirmModal[0] ? instImages[showInstrImageDelConfirmModal[1]] : ""} />
                            </div>
                            <div className="buttonContainer">
                                <button onClick={closeInstrImageDeleteConfirmModal} className='primary-btn border-btn'>{languageData["No"] ? languageData["No"] : "No"}</button>
                                <button onClick={() => { deleteImage(showInstrImageDelConfirmModal[1]); closeInstrImageDeleteConfirmModal(); }} className='primary-btn'>{languageData["Yes"] ? languageData["Yes"] : "Yes"}</button>
                            </div>
                        </div>
                    </Backdrop>

                    <Backdrop
                        className="backdrop-modal-wrapper appointment-checkout-wrapper"
                        open={showAppointmentConfirmation ? true : false}
                        title={'Appointment confirmation'}

                    >

                        <div className="backdrop-modal-content" style={{ height: `${showAppointmentConfirmation ? '580px' : '0'}` }}>
                            <div className="modal-close" onClick={() => setShowAppointmentConfirmation(false)}>
                                <SvgIcon icon="close" />
                            </div>
                            <div className='appointment-checkout-content'>
                                <div className="heading">{languageData["Appointment Details"] ? languageData["Appointment Details"] : "Appointment Details"}</div>
                                <div className="date-time card">
                                    <div className='sub-heading'>{languageData["Date"] ? languageData["Date"] : "Date"}</div>
                                    <div className="date">{formatToDateFormat(appointmentItems[0]?.date, configData.dateFormat)}</div>
                                </div>
                                <div className="services-list-wrap card">
                                    <div className="sub-heading">{languageData["Services"] ? languageData["Services"] : "Services"}</div>
                                    <div className='service-details heading'>
                                        <div className="service-name">{languageData["Name"] ? languageData["Name"] : "Name"}</div>
                                        <div className="service-name slot">{languageData["Slot"] ? languageData["Slot"] : "Slot"}</div>
                                        <div className="service-name expert">{languageData["Expert"] ? languageData["Expert"] : "Expert"}</div>
                                    </div>
                                    {appointmentItems?.map((item, index) => {
                                        return <div className='service-details' key={index}>
                                            <div className="service-name">{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item?.name}</div>
                                            <div className="service-name slot">{formatTimeTo12Hr(item.slot)}</div>
                                            <div className="service-name expert">{item?.staff ? (item?.staff?.lastName ? `${item?.staff?.firstName} ${item?.staff?.lastName}` : item?.staff?.firstName) : ''}</div>
                                        </div>
                                    })}
                                </div>

                                <div className="appt-for-wrap card">
                                    <div className="sub-heading">{languageData["Appointment For"] ? languageData["Appointment For"] : "Appointment For"}</div>
                                    <div className='selected-profile'>
                                        <div className='name'>{selectedMember?.name},{basicConfig.multipleCountryCode ? '+' + selectedMember?.countryCode : ""} {selectedMember?.mobileNo}</div>
                                    </div>
                                    <div className='profile-list'>
                                        <div className={`profile ${selectedMember?.name == userData?.firstName ? 'active' : ''}`} onClick={() => onSelectMember({ name: userData?.firstName, mobileNo: userData?.mobileNo, countryCode: userData?.countryCode })}  >{languageData["Self"] ? languageData["Self"] : "Self"}</div>
                                        {userData?.familyMembers?.map((member) => {
                                            return <div key={Math.random()} onClick={() => onSelectMember(member)} className={`profile ${selectedMember?.name == member.name ? 'active' : ''}`}>{member.name}</div>
                                        })}
                                    </div>
                                    <div className='add-profile'>
                                        <div onClick={() => { setShowAddMemberModal(true); setActiveInput('') }} >{languageData["Add Member"] ? languageData["Add Member"] : "Add Member"} +</div>
                                    </div>
                                </div>

                                {Boolean(appointmentObj?.disposables?.length) && <div className="services-list-wrap card disposables-list">
                                    <div className="sub-heading cap-text">{languageData["Extra Charges"] ? languageData["Extra Charges"] : "Extra Charges"}</div>
                                    {appointmentObj?.disposables?.map((item) => {
                                        return <div className="service-name" key={Math.random()}>
                                            <div>{item.languages && item.languages[activeLanguage?.languageId]?.name ? item.languages[activeLanguage?.languageId]?.name : item?.name} <>{item.quantity > 1 && item.quantity}</></div>
                                            <div>{configData?.currencySymbol}{(item.price) * (item.quantity)}</div>
                                        </div>
                                    })}
                                </div>}

                                <div className="appt-for-wrap card">
                                    <div className="sub-heading" >{languageData["Pricing Details"] ? languageData["Pricing Details"] : "Pricing Details"}</div>
                                    <div className='pricing-details-wrap d-f-c'>
                                        <div className='heading'>
                                            <div className='title'>{languageData["SubTotal"] ? languageData["SubTotal"] : "SubTotal"}</div>
                                            {isDisposableAvailable && <div className='title'>{languageData["Extra Charges"] ? languageData["Extra Charges"] : "Extra Charges"}</div>}
                                            {appointmentObj.txchrgs?.map((taxData: any) => {
                                                return <div className='title' key={Math.random()}>{taxData.name}{taxData.isInclusive ? "(Inclusive)" : ''}({taxData.value}%)</div>
                                            })}
                                            <div className='title grand-total'>{languageData["Grand Total"] ? languageData["Grand Total"] : "Grand Total"}</div>
                                        </div>
                                        <div className='details'>
                                            <div className='value'>{configData.currencySymbol} {appointmentObj.subTotal}</div>
                                            {isDisposableAvailable && <div className='value'>{configData.currencySymbol} {appointmentObj?.disposables?.reduce((a: any, b: any) => a + (Number(b.price) * Number(b.quantity)), 0)}</div>}
                                            {appointmentObj.txchrgs?.map((taxData: any) => {
                                                return <div className='value' key={Math.random()}>{configData.currencySymbol} {taxData.total}</div>
                                            })}
                                            <div className='value grand-total'>{configData.currencySymbol} {appointmentObj.total}</div>
                                        </div>
                                    </div>
                                </div>

                                {Boolean(appointmentConfig?.advancePayments) && <div className="appt-for-wrap card">
                                    <div className='sub-heading adv-amt'>{languageData["Advance Amount"] ? languageData["Advance Amount"] : "Advance Amount"}: {configData.currencySymbol}{getAdvanceAmount(appointmentObj.total)}</div>
                                </div>}

                                {Boolean(appointmentConfig.paymentNote) && <div className="appt-for-wrap card">
                                    <div className='sub-heading'>*{languageData["Note"] ? languageData["Note"] : "Note"}: {appointmentConfig.paymentNote}</div>
                                </div>}

                                <Backdrop
                                    className="backdrop-modal-wrapper"
                                    open={showAddMemberModal ? true : false}
                                >
                                    <div className="backdrop-modal-content"
                                        style={{ height: showAddMemberModal ? '250px' : '0px' }}
                                    >
                                        <div className="modal-close" onClick={closeMemberForm}>
                                            <SvgIcon icon="close" />
                                        </div>
                                        <div className="member-modal">
                                            <div className='heading'>{languageData["Add Member"] ? languageData["Add Member"] : "Add Member"}</div>
                                            <div className="member-form">
                                                <div className='input-wrap'>
                                                    <span className="label">{languageData["Name"] ? languageData["Name"] : "Name"}*</span>
                                                    <input className={error == 'member-name' ? 'input error' : 'input'}
                                                        autoComplete="off"
                                                        placeholder={languageData["Profile name"] ? languageData["Profile name"] : "Profile name"}
                                                        value={familyMember.name || ''}
                                                        onChange={(e) => onChangeMemberValue('name', e.target.value)} />
                                                    {error == 'member-name' && <div className="error">{languageData["Please enter name"] ? languageData["Please enter name"] : "Please enter name"}</div>}
                                                </div>
                                                {/* <div className='input-wrap'>
                                                    <span className="label">mobileNo*</span>
                                                    <input className={error == 'member-mobileNo' ? 'input error' : 'input'}
                                                        autoComplete="off"
                                                        placeholder='Profile number'
                                                        minLength={10}
                                                        maxLength={10}
                                                        type="number"
                                                        value={familyMember.mobileNo || ''}
                                                        onChange={(e) => onChangeMemberValue('mobileNo', e.target.value)} />
                                                    {error == 'member-mobileNo' && <div className="error">Please enter phone number</div>}
                                                    {error?.id == 'existing-member' && <div className="error">{error.text}</div>}
                                                </div> */}
                                                {basicConfig.multipleCountryCode ? <>
                                                    <PhoneInput
                                                        inputClass={`phonInput input ${error.id == 'phone' ? 'invalidInput' : ''}`}
                                                        value={`${familyMember.countryCode || storeMetaData?.countryCode}${familyMember.mobileNo}` || ''}
                                                        onChange={(phone: string, country: any) => onPhoneChange(phone.replace(country.dialCode, ''), country.dialCode)}
                                                        countryCodeEditable={false}
                                                        placeholder={languageData["Mobile Number"] ? languageData["Mobile Number*"] : "Mobile Number*"}
                                                        country={storeMetaData.code || "in"} // Set the default country here
                                                        enableSearch={true}
                                                        // containerStyle={{ paddingLeft: '36px', width: '80%' }}
                                                        inputProps={{ style: { width: '100%', height: '34px' } }}
                                                    />
                                                </> : <>
                                                    {/* {storeMetaData?.countryCode && <div className="country-code">+{storeMetaData?.countryCode}</div>} */}
                                                    <div className='input-wrap'>
                                                        <span className="label">{languageData["Mobile Number"] ? languageData["Mobile Number*"] : "Mobile Number*"}</span>
                                                        <input className="input"
                                                            autoComplete="off"
                                                            type='tel'
                                                            id="sign-in-phone"
                                                            value={familyMember.mobileNo || ''}
                                                            onChange={(e) => onPhoneChange(e.target.value)}
                                                            maxLength={10}
                                                            placeholder={languageData["Enter phone number"] ? languageData["Enter phone number"] : "Enter phone number"}
                                                        />
                                                    </div>
                                                </>}
                                            </div>
                                            <div className="form-btn-wrap">
                                                <button className="primary-btn rounded-btn border-btn" onClick={closeMemberForm}>{languageData["Cancel"] ? languageData["Cancel"] : "Cancel"}</button>
                                                <button className="primary-btn rounded-btn" onClick={submitMemberForm}>{languageData["Add"] ? languageData["Add"] : "Add"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Backdrop>
                                <div className='confirm-btn-wrap'>
                                    {(Boolean(appointmentConfig?.advancePayments) && Boolean(appointmentObj.total)) ?
                                        <>
                                            {Boolean(appointmentConfig.advanceValue) && <button className="primary-btn rounded-btn" onClick={() => proceedToPayments('partial')}>{languageData["Pay Advance"] ? languageData["Pay Advance"] : "Pay Advance"}</button>}
                                            {Boolean(appointmentConfig.fullPayment) && <button className="primary-btn rounded-btn" onClick={() => proceedToPayments('fullpayment')}>{languageData["Pay Full"] ? languageData["Pay Full"] : "Pay Full"}</button>}
                                            {Boolean(appointmentConfig.payLater) && <button className="primary-btn rounded-btn" onClick={onAppointmentConfirmation}>{languageData["Pay Later"] ? languageData["Pay Later"] : "Pay Later"}</button>}
                                            {Boolean(!("payLater" in appointmentConfig)) && <button className="primary-btn rounded-btn" onClick={onAppointmentConfirmation}>{languageData["Confirm"] ? languageData["Confirm"] : "Confirm"}</button>}
                                        </> : <button className="primary-btn rounded-btn" onClick={onAppointmentConfirmation}>{languageData["Confirm"] ? languageData["Confirm"] : "Confirm"}</button>}
                                </div>
                            </div>
                        </div>
                    </Backdrop>

                    {showUserRegistration && <UserRegistrationModal
                        handleResponse={(e) => onLoginClose(e)}
                        isApppGrpChangeOnUserGdrChange={true}
                        open={true}
                        fromPage={'APPOINTMENT'}
                        heading={'Login for appointment booking'}
                    />}

                    <PaymentVendorModal
                        open={Boolean(openPaymentVendorModal)}
                        handleClose={(e: any) => handlePaymentVendorSelection(e)}
                        paymentGatewayList={paymentGatewayList}
                    />

                    <Backdrop
                        className="backdrop-modal-wrapper"
                        open={openCcavenueModal.active ? true : false}
                        onClick={() => setopenCcavenueModal({ active: false, link: "" })}
                    >
                        <div className="backdrop-modal-content"
                            style={{ height: `80vh` }}
                        >
                            <div className="heading" >{languageData["Pay Amount"] ? languageData["Pay Amount"] : "Pay Amount"}</div>
                            <div className="modal-close" onClick={() => setopenCcavenueModal({ active: false, link: "" })}>
                                <SvgIcon icon="close" />
                            </div>
                            <div className='pricing-details-wrap d-f-c'>
                                <iframe src={openCcavenueModal.link} width="400" height="300"></iframe>
                            </div>
                        </div>
                    </Backdrop>

                    {stripeModalDetails.options.clientSecret ? (
                        <Backdrop
                            className="backdrop-modal-wrapper"
                            open={!!stripeModalDetails.options.clientSecret}
                            onClick={closeStripeWindow}
                        >
                            <div className="backdrop-modal-content"
                                onClick={(e) => e.stopPropagation()}
                                style={{ height: `${stripeModalDetails.options.clientSecret ? 390 : 0}px` }}
                            >
                                <div className="heading" >{languageData["Pay Here"] ? languageData["Pay Here"] : "Pay Here"}</div>
                                <div className="modal-close" onClick={closeStripeWindow}>
                                    <SvgIcon icon="close" />
                                </div>
                                <Elements options={stripeModalDetails.options} stripe={stripeModalDetails.promise}>
                                    <CheckoutForm stripeModalDetails={stripeModalDetails} />
                                </Elements>
                            </div>
                        </Backdrop>
                    ) : null}

                    <Backdrop
                        className="backdrop-modal-wrapper"
                        open={showTotalBreakdownPopup ? true : false}
                        onClick={() => setShowTotalBreakdownPopup(false)}
                    >
                        <div className="backdrop-modal-content total-wrap"
                            style={{ height: `${showTotalBreakdownPopup ? `${150 + (appointmentObj?.txchrgs?.length * 30) + (appointmentObj.disposables?.length * 30)}px` : '0'}` }}
                        >
                            <div className="heading" >{languageData["Pricing Details"] ? languageData["Pricing Details"] : "Pricing Details"}</div>
                            <div className="modal-close" onClick={() => setShowTotalBreakdownPopup(false)}>
                                <SvgIcon icon="close" />
                            </div>
                            <div className='pricing-details-wrap d-f-c'>
                                <div className='heading'>
                                    <div className='title'>{languageData["SubTotal"] ? languageData["SubTotal"] : "SubTotal"}</div>
                                    {/* {isDisposableAvailable && <div className='title'>Extra Charges</div>} */}
                                    {isDisposableAvailable && appointmentObj.disposables?.map((item: any) => {
                                        return <div className='title' key={Math.random()}>{item.name}</div>
                                    })}
                                    {appointmentObj.txchrgs?.map((taxData: any) => {
                                        return <div className='title' key={Math.random()}>{taxData.name}{taxData.isInclusive ? "(Inclusive)" : ''}({taxData.value}%)</div>
                                    })}
                                    <div className='title grand-total'>{languageData["Grand Total"] ? languageData["Grand Total"] : "Grand Total"}</div>
                                </div>
                                <div className='details'>
                                    <div className='value'>{configData.currencySymbol} {appointmentObj.subTotal}</div>
                                    {isDisposableAvailable && appointmentObj.disposables?.map((item: any) => {
                                        return <div className='value' key={Math.random()}>{configData.currencySymbol} {(Number(item.price) * Number(item.quantity))}</div>
                                    })}
                                    {appointmentObj.txchrgs?.map((taxData: any) => {
                                        return <div className='value' key={Math.random()}>{configData.currencySymbol} {taxData.total}</div>
                                    })}
                                    <div className='value grand-total'>{configData.currencySymbol} {appointmentObj.total}</div>
                                </div>
                            </div>
                        </div>
                    </Backdrop>
                </div >
                : <>
                    <OrderConfirmation inititalOrder={orderData} />
                </>
            }
            <ConfirmationModal {...dateChangeConfirmationModal} />
        </>
    )
}

export default BookedSlotAppointmentFlow