import { disableLoader, enableLoader } from '@context/actions';
import SvgIcon from '@element/svgIcon';
import Backdrop from '@material-ui/core/Backdrop';
import { updateUserAddress } from '@storeData/user';
import { showError, showSuccess } from 'app/redux/actions/alert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function AddressModal({ open, handleClose, addressToEdit, userId }) {

    const dispatch = useDispatch();
    const [error, seterror] = useState('');
    const [addressObj, setAddressObj] = useState({ id: null, line: '', landmark: '', city: '', area: '', code: '', type: 'Home' });
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    useEffect(() => {
        if (addressToEdit) setAddressObj({ ...addressObj, ...addressToEdit })
        else setAddressObj({ id: null, line: '', landmark: '', city: '', area: '', code: '', type: 'Home' });
    }, [addressToEdit])

    const closeModal = () => {
        handleClose(null);
    }

    const onSaveClick = () => {
        if (!addressObj.line) {
            seterror('address');
        } else if (!addressObj.area) {
            seterror('area');
        } else if (!addressObj.city) {
            seterror('city');
        } else {
            dispatch(enableLoader());
            updateUserAddress(addressObj, userId)
                .then((user: any) => {
                    let updatedAdd = user.addressList.filter((a: any) => a.type == addressObj.type);
                    dispatch(disableLoader());
                    if (addressObj.id) dispatch(showSuccess(`${languageData['Address Updated'] ? languageData['Address Updated'] : 'Address Updated'}`, 2000));
                    else dispatch(showSuccess(`${languageData['Address Added'] ? languageData['Address Added'] : 'Address Added'}`, 2000));
                    handleClose(updatedAdd[0]);
                }).catch((error) => {
                    dispatch(disableLoader());
                    dispatch(showError(`${languageData['Something went wrong. please try again'] ? languageData['Something went wrong. please try again'] : 'Something went wrong. please try again'}`, 2000));
                })
        }
    }

    return (
        <div className="address-modal-wrap">

            <Backdrop
                className="backdrop-modal-wrapper"
                open={open ? true : false}
            >
                <div className="backdrop-modal-content"
                    style={{ height: open ? '310px' : '0px' }}
                >
                    <div className="modal-close" onClick={closeModal}>
                        <SvgIcon icon="closeLarge" />
                    </div>
                    <div className="address-modal">
                        {addressToEdit?.id ? <div className='heading'> {languageData["Update"] ? languageData["Update"] : "Update"} {addressToEdit?.type} {languageData["Address"] ? languageData["Address"] : "Address"}</div> : <div className='heading'>{languageData["Add"] ? languageData["Add"] : "Add"} {addressToEdit?.type} {languageData["Address"] ? languageData["Address"] : "Address"}</div>}
                        <div className="address-form">
                            <div className="input-wrap">
                                <span className="label">{languageData["Address"] ? languageData["Address"] : "Address"}*</span>
                                <input className={error == 'address' ? 'input invalidInput' : 'input'} autoComplete="off"
                                    value={addressObj.line}
                                    onChange={(e) => { setAddressObj({ ...addressObj, line: e.target.value }); seterror('') }} />
                                {error == 'address' && <div className="error"> {languageData["Please enter address"] ? languageData["Please enter address"] : "Please enter address"}</div>}
                            </div>
                            <div className="address-wrapper" >
                                <div className='input-wrap'>
                                    <span className="label">{languageData["Area"] ? languageData["Area"] : "Area"}*</span>
                                    <input className={error == 'area' ? 'input invalidInput' : 'input'} autoComplete="off"
                                        value={addressObj.area}
                                        onChange={(e) => { setAddressObj({ ...addressObj, area: e.target.value }); seterror('') }} />
                                    {error == 'area' && <div className="error">{languageData["Please enter area"] ? languageData["Please enter area"] : "Please enter area"}</div>}
                                </div>
                                <div className='input-wrap'>
                                    <span className="label">{languageData["City"] ? languageData["City"] : "City"}*</span>
                                    <input className={error == 'city' ? 'input invalidInput' : 'input'} autoComplete="off"
                                        value={addressObj.city}
                                        onChange={(e) => { setAddressObj({ ...addressObj, city: e.target.value }); seterror('') }} />
                                </div>
                            </div>
                            <div className="address-wrapper">
                                <div className='input-wrap'>
                                    <span className="label">{languageData["Landmark"] ? languageData["Landmark"] : "Landmark"}</span>
                                    <input className={error == 'landmark' ? 'input invalidInput' : 'input'} autoComplete="off"
                                        value={addressObj.landmark}
                                        onChange={(e) => { setAddressObj({ ...addressObj, landmark: e.target.value }); seterror('') }} />
                                    {error == 'landmark' && <div className="error"> {languageData["Please enter landmark"] ? languageData["Please enter landmark"] : "Please enter landmark"}</div>}
                                </div>
                                <div className='input-wrap'>
                                    <span className="label">{languageData["Pincode"] ? languageData["Pincode"] : "Pincode"}</span>
                                    <input className={error == 'pincode' ? 'input invalidInput' : 'input'} autoComplete="off"
                                        value={addressObj.code}
                                        onChange={(e) => { setAddressObj({ ...addressObj, code: e.target.value }); seterror('') }} />
                                </div>
                            </div>
                        </div>
                        <div className="save-add-btn">
                            <div className='primary-btn border-btn' onClick={closeModal}>{languageData["Cancel"] ? languageData["Cancel"] : "Cancel"}</div>
                            <div className='primary-btn' onClick={onSaveClick}>{languageData["Save"] ? languageData["Save"] : "Save"}</div>
                        </div>
                    </div>
                </div>
            </Backdrop>
        </div>
    );
}

export default AddressModal;