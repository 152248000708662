import { disableLoader, enableLoader } from '@context/actions';
import { getUserByGuestId } from '@storeData/user';
import { getCurrencySymbol, getTofixValue } from '@util/utils';
import router from "next/router";
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingData } from '@storeData/common';


function PackageInvoiceModal({ handleClose, orderData }) {
    const { configData } = useSelector((state: any) => state.store.storeData);
    const orderId = router.query.pagepath ? router.query.pagepath[1] : '';
    const [storeMetaData, setStoreMetaData] = useState<any>({});
    const [userDetails, setUserDetails] = useState(null);
    const dispatch = useDispatch();
    const [billingObj, setBillingObj] = useState({
        appliedTaxes: [],
        total: 0,
        taxesTotal: 0,
    })
    const store = useSelector((state: any) => state);
    const languageData: any = store.languageKeywords
    const activeLanguage: any = store.activeLanguage
    useEffect(() => {
        if (orderData) {
            getBillingData(configData.tenantId, configData.storeId).then((res: any) => {
                setStoreMetaData(res);
            });
            dispatch(enableLoader());
            getUserByGuestId(orderData?.tenantId, orderData?.storeId, orderData.guestId).then((response) => {
                dispatch(disableLoader());
                setUserDetails(response)
            }).catch(function (error) {
                dispatch(disableLoader());
                console.log("error");
            });
        }
    }, [orderData])


    return (
        <div className="invoice-wrapper membership-invoice-wrapper ">
            {/* <div className='page-heading'>Order Invoice</div> */}
            {orderData ? <div className="invoice-page-wrap">
                <div className='salon-details-wrap'>
                    <div className='logo-wrap'>
                        <img src={storeMetaData.logoPath} alt="Respark" />
                    </div>
                    <div className='salon-details'>
                        <div className='name'>
                            {storeMetaData?.tenantName && storeMetaData.tenantName}
                            {storeMetaData?.tenantName && storeMetaData?.storeName ? ', ' : ''}
                            {storeMetaData?.storeName && storeMetaData.storeName}
                        </div>
                        {storeMetaData.companyName && <div className='company-name'>{storeMetaData.companyName}</div>}
                        <div className='address s-detail'>{storeMetaData?.address}
                        </div>
                        <div className='phone s-detail'>{storeMetaData?.phone}
                            {storeMetaData?.phone1 && storeMetaData?.phone && <>, </>}
                            {storeMetaData?.phone1 && <>{storeMetaData?.phone1}</>}
                        </div>
                        <div className='email s-detail'>{storeMetaData?.email}</div>
                        {storeMetaData?.gstNo && <div className='gstn s-detail'>{languageData["GSTN"] ? languageData["GSTN"] : "GSTN"}: {storeMetaData?.gstNo}</div>}
                        {storeMetaData?.crNo && <div className='gstn s-detail'>{languageData["CRNo"] ? languageData["CRNo"] : "CRNo"}: {storeMetaData?.crNo}</div>}
                        {storeMetaData?.vatNo && <div className='gstn s-detail'>{languageData["VAT"] ? languageData["VAT"] : "VAT"}: {storeMetaData?.vatNo}</div>}
                    </div>
                </div>
                <div className='user-details-wrap'>
                    <div className='user-details'>
                        <div className='subheading'>{languageData["Bill to"] ? languageData["Bill to"] : "Bill to"}:</div>
                        <div className='name'>{userDetails?.firstName}</div>
                        <div className='phone'>{userDetails?.mobileNo} {userDetails?.email && <>{userDetails?.email}</>}</div>
                        {userDetails?.gstN && <div className='name'>{languageData["GSTN"] ? languageData["GSTN"] : "GSTN"}: {userDetails?.gstN}</div>}
                        {(userDetails?.addressList && userDetails?.addressList.length != 0 && userDetails?.addressList[0].line) ?
                            <div className='user-address phone'>{userDetails?.addressList[0].line},&nbsp;
                                {userDetails?.addressList[0].area && <>{userDetails?.addressList[0].area}, </>}
                                {userDetails?.addressList[0].landmark && <>{userDetails?.addressList[0].landmark}, </>}
                                {userDetails?.addressList[0].city && <>{userDetails?.addressList[0].city}, </>}
                                {userDetails?.addressList[0].code && <>{userDetails?.addressList[0].code} </>}
                            </div> :
                            <></>}
                    </div>
                    <div className='date-wrap'>
                        <div className='subheading'>{languageData["Invoice No"] ? languageData["Invoice No"] : "Invoice No"}: <div className='order-id'>{orderData?.invoiceNo}</div></div>
                        <div className='subheading'>{languageData["Date"] ? languageData["Date"] : "Date"}: <div className='time'>{orderData?.createdOn?.substring(0, 10)}</div></div>
                        <div className='subheading'>{languageData["Time"] ? languageData["Time"] : "Time"}: <div className='time'>{`${new Date(orderData?.createdOn).getHours()}:${new Date(orderData?.createdOn).getMinutes()}`}</div></div>
                        <div className='subheading'>{languageData["Purchase Date"] ? languageData["Purchase Date"] : "Purchase Date"}: <div className='time'>{orderData?.fromDate?.substring(0, 10)}</div></div>
                        <div className='subheading'>{languageData["Expiry Date"] ? languageData["Expiry Date"] : "Expiry Date"}: <div className='time'>{orderData?.toDate?.substring(0, 10)}</div></div>
                    </div>
                </div>

                <div className="bill-item-type-wrap">
                    <div className="heading cap-text">{languageData["Package Purchased Bill"] ? languageData["Package Purchased Bill"] : "Package Purchased Bill"}</div>
                    <div className='invoice-details-wrap order-invoice-details package-invoice-details'>
                        <div className='package-name'>{languageData["Package Name"] ? languageData["Package Name"] : "Package Name"}: <span className='package-item'>{orderData.packageName}</span></div>
                        <div className='package-items-wrap'>
                            <div className='package-items'>
                                {(orderData?.utilizedPackageItems?.filter((i: any) => i.type == 'service'))?.map((item: any, i: number) => {
                                    return <Fragment key={i}>
                                        {i == 0 && <div className='heading'>{languageData["Package Services"] ? languageData["Package Services"] : "Package Services"}</div>}
                                        <div className='item service-details'>
                                            <div className='name'>{item.itemName}</div>
                                            <div className='price'>{item.balanceQuantity}</div>
                                        </div>
                                    </Fragment>
                                })}
                            </div>
                            <div className='package-items'>
                                {(orderData?.utilizedPackageItems?.filter((i: any) => i.type == 'product'))?.map((item: any, i: number) => {
                                    return <Fragment key={i}>
                                        {i == 0 && <div className='heading'>{languageData["Package Products"] ? languageData["Package Products"] : "Package Products"}</div>}
                                        <div className='item service-details'>
                                            <div className='name'>{item.languages && item.languages[activeLanguage?.languageId]?.itemName ? item.languages[activeLanguage?.languageId]?.itemName : item.itemName}</div>
                                            <div className='price'>{item.balanceQuantity}</div>
                                        </div>
                                    </Fragment>
                                })}
                            </div>
                        </div>

                        {orderData?.txchrgs?.length != 0 && <div className='invoice-total-details-wrap type-wise-order-invoice'>
                            <div className='total-details'>
                                <div className="total-entity-wrap">
                                    <div className='title'>{languageData["Sub Total"] ? languageData["Sub Total"] : "Sub Total"}</div>
                                    <div className='value'>{getCurrencySymbol()}{orderData?.billingPrice}</div>
                                </div>
                            </div>
                        </div>}

                        {orderData?.txchrgs?.length != 0 && <div className='invoice-total-details-wrap type-wise-order-invoice'>
                            {orderData?.txchrgs?.map((taxData: any, i: number) => {
                                return <div className='total-details'>
                                    <div className="total-entity-wrap">
                                        <div className='title'>{taxData.name} ({taxData.taxRate}{taxData.type == 'PERCENTAGE' ? '%' : getCurrencySymbol()})
                                            {Boolean(taxData.isInclusive) && <>(Inclusive)</>}</div>
                                        <div className='value'>{getCurrencySymbol()}{taxData.value}</div>
                                    </div>
                                </div>
                            })}
                        </div>}

                        <div className='invoice-total-details-wrap type-wise-order-invoice'>
                            <div className='total-details'>
                                <div className="total-entity-wrap">
                                    <div className='title'>{languageData["Grand Total"] ? languageData["Grand Total"] : "Grand Total"}</div>
                                    <div className='value'>{getCurrencySymbol()}{orderData?.purchaseAmount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='payment-wrap'>
                    <div className='payment-by d-f-c'>
                        <div className='paid-via'>
                            <span>{languageData["Paid Via"] ? languageData["Paid Via"] : "Paid Via"} : </span>
                            {orderData?.payments?.length != 0 && orderData?.payments?.map((paymode: any, pIndex: number) => {
                                return <React.Fragment key={pIndex}>
                                    {paymode?.name} - {getTofixValue(paymode?.payment, true)} {(orderData?.payments?.length != 0 && pIndex != orderData?.payments?.length - 1) && <>, </>}
                                </React.Fragment>
                            })}
                        </div>
                    </div>
                </div>
                <div className='note'>
                    {languageData["Thank you for choosing us"] ? languageData["Thank you for choosing us"] : "Thank you for choosing us"}
                </div>
            </div> : <>
                {orderId ? <div className='no-data card'>
                    {languageData["The invoice you are looking for is not available"] ? languageData["The invoice you are looking for is not available"] : "The invoice you are looking for is not available"}
                </div> : <div className='no-data card'>
                    {languageData["Invalid link"] ? languageData["Invalid link"] : "Invalid link"}
                </div>}
            </>}
        </div>
    )
}

export default PackageInvoiceModal