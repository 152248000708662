import SvgIcon from "@element/svgIcon";
import { navigateTo } from "@util/routerService";
import router from 'next/router';
import { } from "react";
import { useSelector } from 'react-redux';

function MiniHeader({ currentPage }) {
  const currentPageStatus = useSelector((state: any) => state.currentPage);
  const baseRouteUrl = useSelector((state: any) => state.store.baseRouteUrl);
  const { configData } = useSelector((state: any) => state.store.storeData);
  const store = useSelector((state: any) => state);
  const languageData: any = store.languageKeywords
  const onBackClick = () => {
    if (currentPageStatus == 'orderconfirmation' || currentPageStatus == "invoice" || currentPageStatus == "giftcardinvoice" || currentPageStatus == "membershipinvoice" || currentPageStatus == "packageinvoice") navigateTo('home')
    else router.back();
  }
  return (
    <>
      <div className="mainheaderblock mini-header-wrap">
        {Boolean(configData?.storeConfig?.basicConfig?.catalogue) && <div onClick={onBackClick} className="logo back-navigation">
          <SvgIcon icon="backArrow" shape="circle" width={32} height={32} margin="0 0 0 5px" />
        </div>}
        <div className="heading">
          {currentPage == 'cart' ? `${languageData["Cart Details"] ? languageData["Cart Details"] : "Cart Details"}` :
            currentPage == 'checkout' ? `${languageData["Order Details"] ? languageData["Order Details"] : "Order Details"}` :
              currentPage == 'invoice' ? `${languageData["Order Invoice"] ? languageData["Order Invoice"] : "Order Invoice"}` :
                currentPage == 'membershipinvoice' ? `${languageData["Membership Invoice"] ? languageData["Membership Invoice"] : "Membership Invoice"}` :
                  currentPage == 'packageinvoice' ? `${languageData["Package Invoice"] ? languageData["Package Invoice"] : "Package Invoice"}` :
                    currentPage == 'giftcardinvoice' ? `${languageData["Gift Card Invoice"] ? languageData["Gift Card Invoice"] : "Gift Card Invoice"}` :
                      currentPage == 'advanceinvoice' ? `${languageData["Advance Invoice"] ? languageData["Advance Invoice"] : "Advance Invoice"}` :
                        currentPage == 'balanceinvoice' ? `${languageData["Balance Invoice"] ? languageData["Balance Invoice"] : "Balance Invoice"}` :
                          currentPage == 'appointment' ? `${languageData["Appointment Details"] ? languageData["Appointment Details"] : "Appointment Details"}` :
                            ''}
        </div>
      </div>
    </>
  );
}

export default MiniHeader;
